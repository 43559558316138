import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/authentication/Login.vue';
import Companies from '../views/admin/Companies.vue';
import Admin from '../views/admin/Admin.vue';
import Users from '../views/admin/Users.vue';
import Documents from '../views/admin/Documents.vue';
import LabOrders from '../views/lab/Orders.vue';
import ClientAnalyzes from '../views/client/Analyzes.vue';
import ClientOrders from '../views/client/Orders.vue';
import OrdersHistory from '../components/client/Order-history.vue';
import ClientFields from '../views/client/Fields.vue';
import MapTest from '../views/admin/MapTest.vue';
import GisEditFields from '../views/gis/EditFields.vue';
import GisFields from '../views/gis/Fields.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Login,
    name: 'login',
    meta: {
      layout: 'empty-layout',
    },
    title: 'Авторизация',
  },
  {
    path: '/admin',
    component: Admin,
    name: 'admin',
    meta: {
      layout: 'main-layout',
      title: 'Администратор',
    },
  },
  {
    path: '/admin/users',
    component: Users,
    name: 'users',
    meta: {
      layout: 'main-layout',
      title: 'Пользователи',
    },
  },
  {
    path: '/admin/companies',
    component: Companies,
    name: 'companies',
    meta: {
      layout: 'main-layout',
      title: 'Компании',
    },
  },
  {
    path: '/admin/documents',
    component: Documents,
    name: 'documents',
    meta: {
      layout: 'main-layout',
      title: 'Документооборот',
    },
  },
  {
    path: '/lab/orders',
    component: LabOrders,
    name: 'lab-orders',
    meta: {
      layout: 'main-layout',
      title: 'Лаборант',
    },
  },
  {
    path: '/client/orders',
    component: ClientOrders,
    name: 'client-orders',
    meta: {
      layout: 'main-layout',
      title: 'Анализ почвы',
    },
  },
  {
    path: '/client/orders-history',
    component: OrdersHistory,
    name: 'orders-history',
    meta: {
      layout: 'main-layout',
      title: 'История анализов',
    },
  },
  {
    path: '/client/fields',
    component: ClientFields,
    name: 'client-fields',
    meta: {
      layout: 'main-layout',
      title: 'Результаты оцифровки',
    },
  },
  {
    path: '/client/analyzes',
    component: ClientAnalyzes,
    name: 'client-analyzes',
    meta: {
      layout: 'main-layout',
      title: 'Результаты анализа',
    },
  },
  {
    path: '/admin/map-test',
    component: MapTest,
    name: 'map-test',
    meta: {
      layout: 'main-layout',
      title: 'Тест карты',
    },
  },
  {
    path: '/gis/editfields',
    component: GisEditFields,
    name: 'gis-edit-fields',
    meta: {
      layout: 'main-layout',
      title: 'Оцифровка полей',
    },
  },
  {
    path: '/gis/fields',
    component: GisFields,
    name: 'gis-fields',
    meta: {
      layout: 'main-layout',
      title: 'Результаты оцифровки',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
// //   document.title = to.meta.title

//   let token = localStorage.getItem('token');
// //   // проверяем - если токена нет, и станица не логин или восстановление , то переходим на логин
//   if (to.name !== 'Login' && !token) next({ name: 'Login' })
//   else if(to.name === 'Login' && token){
//     next()
//   }
//   else if (to.name === 'Logout' && token) {
//     localStorage.removeItem('token'); next({ name: 'Login' });
//     axios.defaults.headers.common['Authorization'] = null;
//     next({ name: 'Login'})
//   }
//   else next()
// })

export default router;
