<template>
  <!-- модалка удаления пользователя -->
  <form>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Удаление пользователя</p>
      </header>
      <section class="modal-card-body">
        <p>Вы действительно хотите удалить Пользователя?</p>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Отмена"
          @click="$emit('close')" />
        <b-button
          label="Удалить"
          type="is-danger"
          @click="deleteUser();" />
      </footer>
    </div>
  </form>
</template>
<script>
import axios from 'axios';

export default {
  props: {
    id: Number,
  },
  mounted() {
  },
  methods: {
    async deleteUser() {
      await axios.delete(`/api/admin/users/${this.id}`)
        .then((response) => {
          this.$buefy.snackbar.open(response.data.status);
        }).catch(() => {
          this.$buefy.snackbar.open('Не удалось удалить пользователя');
        });
        this.$emit('isUserDeleted')
        this.$emit('close')
    },
  },
};
</script>
