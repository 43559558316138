<template>
    <div class="map">
      <div class="columns main">
        <div class="column px-0 py-0">
          <div class="columns console">
            <b-loading :is-full-page="isFullPage" v-model="isLoading" ></b-loading>
            <div clss="column">
              <template v-if="mode == 0">
                <!-- кнопки до сетки -->
                <div class="buttons-style">
                  <template class="buttons-style" v-if="SelectedLayer != null">
                    <template v-if="GridLayer == null && !EditModeIsActive">
                      <button v-show="isScndStageClick | isTrdStageClick " class="mr-1 selfStyle-btn" @click="ResetSelectedLayer">Снять выделение</button>
                    </template>

                    <template>
                      <template v-if="!ShowGridBtnSize && !EditModeIsActive">
                        <button v-show="isScndStageClick" class="mr-1 selfStyle-btn" @click="LayerEditor">Редактировать</button>
                      </template>
                    </template>
                    <!-- SelectedLayer -->
                    <button v-show="EditAfterSave" class="mr-1 selfStyle-btn" @click="EditableField(SelectedLayer)">Изменить границы</button>
                    <button v-show="EditAfterSave && EditModeIsActive" class="mr-1 selfStyle-btn" @click="SaveAfterEdit(getlatlng)">Сохранить изменения</button>
                    <button v-show="EditAfterSave && EditModeIsActive" class="mr-1 selfStyle-btn" @click="ResetBorders()">Отменить изменения</button>
                    <button v-show="EditAfterSave && !EditModeIsActive" class="mr-1 selfStyle-btn" @click="CarveLayerBtn">Вырезать</button>
                    <button v-show="EditAfterSave && !EditModeIsActive" class="mr-1 selfStyle-btn" @click="DeleteField(SelectedLayer.feature.id)">Удалить поле</button>
                    <button v-show="EditAfterSave && !EditModeIsActive" class="mr-1 selfStyle-btn" @click="RollBackField(SelectedLayer.feature.id)">Сброс</button>
                    <button v-show="isFrstStageClick && !EditModeIsActive" class="mr-1 selfStyle-btn" @click="RemoveSelectedLayer">Удалить слой</button>
                    <button v-show="isFrstStageClick && !EditModeIsActive" class="mr-1 selfStyle-btn" @click="SaveField">Сохранить без сетки</button>

                    <template>
                      <template v-if="!EditAfterSave">
                        <template v-if="GridSizeBtn == null">
                          <button v-show="isScndStageClick" class="mr-1 selfStyle-btn" @click="ShowGridBtnSize=!ShowGridBtnSize">Разделить на сектора</button>
                        </template>
                      </template>
                      <template v-if="ShowGridBtnSize">
                        <button v-show="isScndStageClick" class="mr-1 selfStyle-btn" @click="DrawGrid(223.606798)">5 гектар</button>
                        <button v-show="isScndStageClick" class="mr-1 selfStyle-btn" @click="DrawGrid(500)">25 гектар</button>
                        <template v-if="GridSizeBtn">
                          <template v-if="GridLayer != null">
                            <button v-show="isScndStageClick" class="mr-1 selfStyle-btn" @click="RemoveGrid">Назад</button>
                          </template>
                          <button v-show="GridLayer != null" :class="[isLayerOnBack ? 'active-btn' : '']" class="mr-1 selfStyle-btn" @click="GridSetting">Открепить сетку</button>
                          <button v-show="GridLayer != null" class="mr-1 selfStyle-btn" @click="ClipLayer">Применить</button>
                        </template>
                      </template>
                    </template>

                    <template v-if="!isClickOnGrid">
                      <button v-show="isFrthStageClick" class="mr-1 selfStyle-btn" :class="[isCellMergeMode ? 'active-btn' : '']"  @click="ForUnionCell">Слияние секторов</button>
                    </template>

                    <button v-show="isFrthStageClick" class="mr-1 selfStyle-btn" @click="StepBack(SelectedLayer.feature.id)">Удалить сетку</button>
                    <button v-show="isFrthStageClick" class="mr-1 selfStyle-btn" @click="SaveGrid(SelectedLayer.feature.id)">Сохранить</button>
                    <template v-if="isClickOnGrid">
                      <button v-show="isTrdStageClick" class="mr-1 selfStyle-btn" @click="DeleteGrid(SelectedLayer.feature.properties.parent_id)">Удалить сектора</button>
                    </template>
                  </template>
                </div>
                <template v-if="SelectedLayer == null">
                  <div class="columns mx-5 my-1">
                    <template v-if="company_id">
                      <button class="button" @click="OpenModal = true">Создать</button>
                    </template>
                  </div>

                  <div class="modal create-modal" :class="[OpenModal ? 'is-active' : '']">
                    <div class="modal-background " @click="OpenModal=false"></div>
                    <div class="modal-content">
                      <form @submit.prevent="DrawLayerBar">
                        <div class="form-box has-background-white-bis">
                          <p class="mb-3 has-text-primary has-text-weight-semibold">Необходимо заполнить все поля*</p>
                          <div class="field">
                            <label class="label has-text-left-desktop">Название поля*</label>
                            <div class="control">
                              <input class="input mb-1" type="text" ref="input1"  v-model="NameLayerInput" id="input1" >
                            </div>
                          </div>

                          <div class="field">
                            <label class="label has-text-left-desktop">Кадастровый номер</label>
                            <form @submit.prevent="SearchField(CadastralNumberInput)">
                            <div class="control is-flex">
                              <input class="input mb-1" type="text" minlength="11" maxlength="14" placeholder="Пример: 12-178-027-320" v-model.trim="CadastralNumberInput" required>
                              <button class="button" :class="[buttonIsGreen ? 'is-success' : ' ']">поиск</button>
                            </div>
                            </form>
                          </div>

                          <div class="field">
                            <label class="label has-text-left-desktop">Дополнительно</label>
                            <div class="control">
                              <input class="input mb-1" v-model="AdditionalInput" type="text">
                            </div>
                          </div>

                          <div class="field">
                            <label class="label has-text-left-desktop">Описание</label>
                            <div class="control">
                              <input class="input mb-1"  v-model="DescriptionInput" type="text">
                            </div>
                          </div>

                          <div class="field">
                            <label class="label has-text-left-desktop">Регион</label>
                            <div class="control">
                              <input class="input mb-1"  v-model="Region" type="text">
                            </div>
                          </div>

                          <div class="is-flex is-justify-content-space-around">
                            <template v-if="NameLayerInput && CadastralNumberInput && AdditionalInput && DescriptionInput && Region">
                              <button  type="submit" class="button is-success" @click="OpenModal = false"> Добавить </button>
                            </template>
                            <template v-else>
                              <button class="button" disabled>Добавить</button>
                            </template>
                            <button type="button" class="button is-info" @click="OpenModal = false">Отмена</button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <button class="modal-close is-large" aria-label="close" @click="OpenModal = false"></button>
                  </div>
                </template>
              </template>
            </div>
          </div>

          <div class="column" id="map">
            <template v-if="SpecificElem">
              <div class="row elements">
                <h2 class="is-size-7 mb-3">{{ElementName.name}} [{{ElementName.unit}}]</h2>
                <div class="columns list_elem" v-for="item in SpecificElem" :key="item.id">
                  <div class="column mx-1 my-1" :style="{backgroundColor:item.color, color:item.color}"></div>
                  <div class="column elem_list reset_pad is-3"> {{ item.description_ru || item.start + ' - ' + item.end}}</div>
                  <div class="column reset_pad is-6"> {{'-- ' + item.name }}</div>
                </div>
              </div>
            </template>
          </div>

          <div class="slidecontainer">
            <b-field>
              <b-slider v-model="layerOpacity"></b-slider>
            </b-field>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
import L from "leaflet";
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter'
import axios from 'axios';
import * as turf from '@turf/turf'
import 'leaflet-geometryutil';
require('leaflet-path-transform');
import haversine from 'haversine-distance'
// import layerId from '@/variables.js'
import { feature } from '@turf/turf';
var JSZip = require("jszip");

export default {
  data(){
    return{
      zip:new JSZip(),
      isLoading: false,
      isAnalizeAreShowen:false,
      isFullPage: true,
      Map:[],
      AllGridSectors:[],
      FieldToSeeAllAnalizes:[],
      AfterUnion:[],
      GetSectors:[],
      AllFieldsData:[],
      FieldWithID:null,
      SingleGrid:null,
      ResultDoneLayer:null,
      FinalLayer:null,
      IdOfSelectedLayer:null,
      SelectedLayer:null,
      resetEditBorders:null,
      GridLayer:null,
      GridSizeBtn:null,
      FieldsWithoutGrid:null,
      ListOfElements:null,
      SpecificElem:null,
      ElementName:null,
      ResultLayer:null,
      ResultInfo:null,
      ID_value:null,
      SavedField:null,
      layerOpacity:0,
      layerOpacity1:1,
      // zoomSnap:null,
      nexradSecond:null,
      newGridLayer:null,
      Union:null,
      cellArea:null,
      info:null,
      getlatlng:null,
      snoozeAction:null,
      layerReadyForUnion:[],
      isHoleCount:0,
      //флажки
      isFrstStageClick:false,
      LayerWithHole:false,
      isResultsShowen:false,
      isDoNotDelete:false,
      OpenModal:false,
      isCellMergeMode:false,
      isScndStageClick:false,
      isTrdStageClick:false,
      isFrthStageClick:false,
      isEditModeActive:false,
      ShowGridBtnSize:false,
      isClickOnGrid:false,
      isPolygonAreDivided:false,
      buttonIsGreen:false,
      EditAfterSave:false,
      EditModeIsActive:false,
      isLayerOnBack:false,
      NameLayerInput:'',
      CadastralNumberInput:'',
      AdditionalInput:'',
      DescriptionInput:'',
      Region:'',
      ActualizationInput:'',
    }
  },
  props:[
    'mode',
    'fitBoundID',
    'field_id',
    'element_id',
    'company_id',
    'order_id',
    'zoomSnap',
    'allFields',
    'HideFields',
    'FitToThisPlot',
    'ElementToAllFields',
    'isAnalizeModeChanged',
    'fireScreenAction'
  ],
  watch:{
    OpenModal(){
      if(!this.OpenModal){
        this.buttonIsGreen = false
      }
    },
    ShowGridBtnSize(){
      this.isEditModeActive = false;
    },
    SelectedLayer(newValue, oldValue){
      if(this.SelectedLayer && this.mode == 0){
        if(oldValue){
          oldValue.setStyle({color:'#3388ff',fillColor:'black'})
          newValue.setStyle({fillColor:'red'})
        }
      }
      if(oldValue)oldValue.setStyle({color:'#3388ff',fillColor:'black'})
    },
    fitBoundID(newValue){
      if(!newValue){
        return
      }
      this.Map.addLayer(this.FieldsWithoutGrid)

      const AllFieldInMap = this.AllGridSectors.concat(this.FieldsWithoutGrid);
      this.FitContent(newValue)
    },
    order_id(newValue){
      this.SpecificElem = null;
    },
    element_id: {
      async handler(newValue, oldValue) {
        this.Map.removeLayer(this.FieldsWithoutGrid)
        for (let k = 0; k < this.AllGridSectors.length; k++) {
          this.Map.removeLayer(this.AllGridSectors[k])
        }

        if(!this.field_id && !this.order_id){
          return
        }

        if(newValue == null){
          return
        }

        if(newValue){
          this.isAnalizeAreShowen = false;
          await this.GetAnalyze(newValue);
        }
      },
      immediate: false
    },
    field_id(newValue, oldValue){
      this.RemoveResults()

      for (let k = 0; k < this.AllFieldsData.length; k++) {
        if(this.AllFieldsData[k].id == newValue){
          let geojson = L.geoJson(this.AllFieldsData[k], {fillColor:'red',color: "red" }).addTo(this.Map)
          setTimeout(() =>  this.Map.removeLayer(geojson), 1000);
          this.Map.fitBounds(geojson.getBounds());
        }
      }
    },
    company_id(){
      if(this.FieldsWithoutGrid != null){
        this.Map.removeLayer(this.FieldsWithoutGrid)
        for (let i = 0; i < this.AllGridSectors.length; i++) {
          this.Map.removeLayer(this.AllGridSectors[i])
        }
        this.FieldsWithoutGrid = null
        this.AllFieldsData = []
        this.GetSectors = []
      }
      this.GetField()
    },
    layerOpacity(){
      this.nexradSecond.setOpacity(this.layerOpacity / 100);
    },
    zoomSnap(newValue){
      this.Map.options.zoomSnap = newValue;
    },
    HideFields(newValue){
      if(newValue){
        this.Map.removeLayer(this.FieldsWithoutGrid);
        for (let i = 0; i < this.AllGridSectors.length; i++) {
          this.Map.removeLayer(this.AllGridSectors[i])
        }
      }
    },
    allFields(newValue){
      if(newValue.length == 0){
        this.SpecificElem = null;
        // this.isAnalizeAreShowen = false;
        this.Map.removeLayer(this.FieldToSeeAllAnalizes);
        // for (let i = 0; i < this.AllGridSectors.length; i++) {
        //   this.Map.addLayer(this.AllGridSectors[i])
        // }

        this.RemoveResults();
      }

      this.isAnalizeAreShowen = true;
      this.ShowAllFieldsAnalizes();
    },
    async ElementToAllFields(){
      this.Map.removeLayer(this.FieldToSeeAllAnalizes)
      this.RemoveResults();
      this.Map.removeLayer(this.FieldToSeeAllAnalizes);
      this.Map.removeLayer(this.FieldsWithoutGrid);
        for (let i = 0; i < this.AllGridSectors.length; i++) {
          this.Map.removeLayer(this.AllGridSectors[i])
        }
      this.FieldToSeeAllAnalizes = [];
      await this.GetElement()
      await this.GetValueOfElement(this.ElementToAllFields);
    },
    FitToThisPlot(newValue){
      const fitValue = newValue.map(el =>{
        return el.id
      });

      this.SpecificElem = null;
      this.RemoveResults();
      this.FitContent(fitValue)
      this.Map.removeLayer(this.FieldToSeeAllAnalizes)
      this.FieldToSeeAllAnalizes = [];
    },
    // fireScreenAction(newValue){
    //   if(newValue){
    //     console.log('start');
    //     this.doScreenShot()
    //   }
    // },
    // ResultLayer(newValue){
    //   console.log('скрин сделан')
    // }
  },
  created(){
    this.GetField();
  },
  mounted() {
  // ============создание и наведение на определенное место карты===============
    this.Map = L.map('map',{
      center:[53.13964176188631, 63.88484903800869],
      zoom: 11,
      keyboard:true,
      tap:true,
      touchZoom:true,
      zoomControl: true,
      zoomSnap:this.zoomSnap
    });

  // ============основная карта===============
  // L.tileLayer('https://a.tiles.mapbox.com/v3/mapbox.world-bright/{z}/{x}/{y}.png', {
  //   attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
  //   maxZoom: 18
  // }).addTo(this.Map);

    var bing = new L.BingLayer('AvMWaJam44g44PkTUuneRrq7XpVNFYXCjo7vItG67_TdlKx9hPtzG91rNaY2puun')
    .addTo(this.Map);
    // this.map.addLayer(bing);

    // L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
    //   maxZoom: 20,
    //   subdomains:['mt0','mt1','mt2','mt3']
    // }).addTo(this.Map);
  // ============интерактивная настройка и выгрузка карты из сервера===============
    let nexrad = L.tileLayer.wms("http://map.darkandala.kz/service", {
      layers: 'maxar',
      format: 'image/png',
      transparent: true,
      opacity:1,
      attribution: `© 2022 ТОО "Агрохимическая компания ДАРКАН ДАЛА"`
    }).addTo(this.Map);

   this.nexradSecond = L.tileLayer.wms("http://map.darkandala.kz/service", {
      layers: 'kostanai4',
      format: 'image/png',
      transparent: true,
      opacity:this.layerOpacity,
      attribution: `© 2022 ТОО "Агрохимическая компания ДАРКАН ДАЛА"`
    }).addTo(this.Map);

  // ============язык===============
    this.Map.pm.setLang('ru');
  // ========= слушатель zoom =================
    this.Map.on('zoomend', this.ZoomControl)
  // ============ Вешаем собтие клик на созданное поле =============
    this.Map.on('pm:create', (shape) => {
      if(this.mode == 0){
        this.SelectedLayer = null;
        this.snoozeAction = 1;
        this.SelectedLayer = shape.layer;
        this.SelectedLayer.setStyle({fillColor:'red', weight: 1,snapIgnore: true})
         //флажки
        this.EditAfterSave = false;
        this.isEditModeActive = true;
        this.isFrstStageClick = true;
        this.isScndStageClick = false;
        this.isTrdStageClick = false;
      }
    });
  // =================Перезаписываем данные в переменную, после того как мы закончили вырезку========================
    this.Map.on('pm:cut', (shape) => {
      this.SelectedLayer = shape.layer;
      this.SelectedLayer.pm.disable();
      //флажки
      this.isCutModeActive = false;
      this.ShowGridBtnSize = false;
      this.isEditModeActive = false;
      shape.layer.on('click', (e) => {
        if(e.sourceTarget.defaultOptions.fillOpacity == 0.5){
          this.isFrstStageClick = true;
        }
      })
    });
  // =================Слушатели событий для контроля инструментов рисования/переключатель==========
    this.Map.on('pm:drawend', (e) => {
      this.Map.pm.removeControls();
    });
  // ==============================================================================================
    this.info = L.control({position:'bottomright'});

    this.info.onAdd = function (map) {
      this._div = L.DomUtil.create('div', 'info');
      this.update();
      return this._div;
    };

    this.info.update = function (props) {
      this._div.innerHTML = '<h4>Информания</h4>' +
      (props ? '<b>Наименование:' + props.name + '</b><br/>' +
      '<small><b>Общая площадь поля: ' + props.area_hec + ' га'+'<br/>' +
      '<small><b>Посевная площадь поля: ' + props.sown_area_hec + ' га'+ '<br/>' +
      '<small><b>Кадастровый номер:' +  props.cadastral_number + '</br>' +
      (props.properties.cell ? 'Площадь сектора #'+ props.properties.cell +': '+ props.properties.area_hec + ' гектар': '')
        : 'Наведите на поле');
    };

    this.info.addTo(this.Map);
    // const buttonElem = document.getElementById('button1');
    // buttonElem.addEventListener('click', this.onButtonClick);
  },
  methods:{
    // async doScreenShot(){

    //   let pluginOptions = {
    //     cropImageByInnerWH: true, // crop blank opacity from image borders
    //     hidden: true, // hide screen icon
    //     preventDownload: false, // prevent download on button click
    //     domtoimageOptions: {}, // see options for dom-to-image
    //     position: 'topleft', // position of take screen icon
    //     screenName: 'screen', // string or function
    //     hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
    //     mimeType: 'image/png', // used if format == image,
    //     caption: null, // string or function, added caption to bottom of screen
    //     captionFontSize: 15,
    //     captionFont: 'Arial',
    //     captionColor: 'black',
    //     captionBgColor: 'white',
    //     captionOffset: 5
    //   }

    //   this.simpleMapScreenshoter = L.simpleMapScreenshoter(pluginOptions).addTo(this.Map)

    //   let format = 'image'
    //   let overridedPluginOptions = {
    //     mimeType: 'image/jpeg'
    //   }

    //   try {
    //      const image = await this.simpleMapScreenshoter.takeScreen(format, overridedPluginOptions)
    //      return Promise.resolve(image)
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   //  this.simpleMapScreenshoter.takeScreen(format, overridedPluginOptions).then(async(image) => {
    //   //     // this.zip.file("results.png", image.replace(/^data:image\/(png|jpg|jpeg);base64,/,""), {base64: true})
    //   //     // return Promise.resolve(image)
    //   //     if (image) return Promise.resolve(image)
    //   //     // return new Promise((resolve, reject)=>{
    //   //     //   resolve(image)
    //   //     // })
    //   //   }).catch(e => {
    //   //     console.error(e)
    //   //   })
    // },
    openLoading() {
      this.isLoading = !this.isLoading;
    },
    async SearchField(e){
      let layerId = [];
      await axios.get(`api/layers`)
      .then(res => {
        layerId = res.data.layers
      })
      .catch(error => {
        this.$buefy.snackbar.open('Произошла ошибка получения слоев, обратитесь к разработчикам');
      })


      let temp = "U_"
      var str = e.toString()
      str = str.replace('-', '')
      let final = temp+str[0]+str[1]+'_'+str[2]+str[3]+str[4]
      let layer

      for (let i = 0; i < layerId.length; i++) {
        if(layerId[i].name === final){
          layer = layerId[i].id
        }
      }

      let arrayOfField = [], FieldCad = [], FieldJSON = [];

      // await axios.get(`https://aisgzk.kz/aisgzk/Proxy/aisgzkZem2/MapServer/find?f=json&searchText=${e}&contains=false&returnGeometry=true&layers=${layer}&searchFields=KAD_NOMER&sr=4326`).then((response) =>{
      await axios.get(`api/cadastr?number=${e}&layers=${layer}`)
      .then((response) =>{
        arrayOfField.push(response.data.results[0].geometry.rings)


        for (let i = 0; i < arrayOfField[0].length; i++) {
          let coords = L.polygon(arrayOfField[0][i])
          FieldCad.push(coords.toGeoJSON())
        }

        for (let j = 0; j < FieldCad.length; j++) {
          let point = [];
          for (let k = 0; k < FieldCad[j].geometry.coordinates[0].length; k++) {
            point.push(FieldCad[j].geometry.coordinates[0][k])
          }
          FieldJSON.push(point)
          this.$buefy.snackbar.open('Поле успешно отрисовано на карте!');
      }}).catch(()=>{
        this.$buefy.snackbar.open('Не удалось получить поля по кадастровому номеру, убедитесь в правильности кадастрового номера!');
      })

      if(FieldJSON.length > 0){
        this.buttonIsGreen = true;
      }

      let field = L.polygon(FieldJSON,{weight:1, fill:true,fillOpacity:.5}).addTo(this.Map);

      try {
        this.Map.fitBounds(field.getBounds());
      } catch (err) {
        this.OpenModal = false;
        this.$buefy.snackbar.open('Не удалось получить поля по кадастровому номеру, убедитесь в правильности кадастрового номера!');
      }

      field.on('click', this.ListenerClickActions);
    },
    async RollBackField(field){
      await axios.post(`api/gist/company_fields/${this.company_id}/${field}/rollback`)
      .then(response =>{
        this.snoozeAction = null;
      }).catch((e)=>{
         this.snoozeAction = null;
        this.$buefy.snackbar.open('Ошибка при откате поля!');
      })

      this.SelectedLayer.remove();
      this.GetFieldAfterDeleteGrid(field);

    },
    //Показывать инструменты  рисования
    DrawLayerBar(){
      if(!this.buttonIsGreen){
        this.Map.pm.addControls({
          position: 'topleft',
          dragMode:false,
          cutPolygon:false,
          rotateMode:true,
          removalMode:false,
          editMode:false,
          removalMode:false,
          editControls:false,
          drawMarker: false,
          drawPolygon:true,
          drawText:false,
          drawPolyline:false,
          drawRectangle:false,
          drawCircleMarker:false,
          drawCircle:false,
        });
      }
      else{
        return
      }

    },
    //отрисовка сетки
    DrawGrid(cellSize) {
      this.GridSizeBtn = cellSize;

      if (this.GridLayer != null){
        this.GridLayer.transform.disable();
        this.GridLayer.remove();
      };
      this.SelectedLayer.setStyle({color:'#3388ff',fillColor:'black',fillOpacity:.5})
// ============= Поиск перимитра описывающего полигон
      let minX = null, minY = null, maxX = null,maxY = null;
      let coordinates = this.SelectedLayer.toGeoJSON().geometry.coordinates
      // Каждая фигура в полигонее
      for (let j = 0; j < coordinates.length; j++) {
        // Каждая координата в фигуре
        for (let k = 0; k < coordinates[j].length; k++) {
          let point = coordinates[j][k];
          if((point[0] < minX) || (minX === null)) {
            minX = point[0]
          };
          if((point[0] > maxX) || (maxX === null)) {
            maxX = point[0]
          };
          if((point[1] < minY) || (minY === null)) {
            minY = point[1]
          };
          if((point[1] > maxY) || (maxY === null)) {
            maxY = point[1]
          };
        };
      };

      const a = [minX, minY]
      const b = [maxX, minY]
      const c = [minX, maxY]

      let horizontal = haversine(a, b);
      let vertical = haversine(a, c);

      // ===========================================================================
      let gridNumX = Math.ceil(horizontal / this.GridSizeBtn + 5);
      let gridNumY = Math.ceil(vertical  / this.GridSizeBtn + 5);

      gridNumX = gridNumX > gridNumY ? gridNumX : gridNumY
      gridNumY = gridNumY > gridNumX ? gridNumY : gridNumX

      // ===============================Grid======================================
      // ===========================================================================

      let maxGridX = L.GeometryUtil.destination({"lat":minY , "lng":minX}, 90, this.GridSizeBtn * gridNumX);
      let maxGridY = L.GeometryUtil.destination({"lat":minY , "lng":minX}, 0, this.GridSizeBtn * gridNumY);

      minY = minY + (maxY - maxGridY.lat) / 2;
      minX = minX + (maxX - maxGridX.lng) / 2;

      let LBpoint = null, RBpoint = null, RTpoint = null, LTpoint = null;

      // ===========================================================================
      // Отрисовка сетки
      let gridCells = []
      let cells = []
      let cell = []
      LBpoint = {"lat":minY, "lng":minX}
      let flag = false;
      // let
      for(let i = 0; i < gridNumY; i++){
        for(let j = 0; j < gridNumX; j++){

          if(i == 0 && j == 0){
          // Первый сектор первой строки
            RBpoint = L.GeometryUtil.destination({"lat":LBpoint.lat , "lng":LBpoint.lng}, 90, this.GridSizeBtn);
            RTpoint = L.GeometryUtil.destination({"lat":RBpoint.lat , "lng":RBpoint.lng}, 0, this.GridSizeBtn);
            LTpoint = L.GeometryUtil.destination({"lat":RTpoint.lat , "lng":RTpoint.lng}, 270, this.GridSizeBtn);
            cell = [
              [ LBpoint.lat, LBpoint.lng ],
              [ RBpoint.lat, RBpoint.lng ],
              [ RTpoint.lat, RTpoint.lng ],
              [ LTpoint.lat, LTpoint.lng ],
              [ LBpoint.lat, LBpoint.lng ],
            ]
            LBpoint = RBpoint;
            LTpoint = RTpoint;
          } else if(flag) {
          // Первый сектор НЕ первой строки
            RTpoint = L.GeometryUtil.destination({"lat":RBpoint.lat , "lng":RBpoint.lng}, 0, this.GridSizeBtn);
            LTpoint = L.GeometryUtil.destination({"lat":RTpoint.lat , "lng":RTpoint.lng}, 270, this.GridSizeBtn);
            cell = [
              [ LBpoint.lat, LBpoint.lng ],
              [ RBpoint.lat, RBpoint.lng ],
              [ RTpoint.lat, RTpoint.lng ],
              [ LTpoint.lat, LTpoint.lng ],
              [ LBpoint.lat, LBpoint.lng ],
            ]
            flag = false;
            LBpoint = RBpoint;
            LTpoint = RTpoint;
          } else {
          // Все остальные
            if (i == 0) {
              // Сектора первой строки со второго ряда
              RBpoint = L.GeometryUtil.destination({"lat":LBpoint.lat , "lng":LBpoint.lng}, 90, this.GridSizeBtn);
            } else {
              // Сектора со второго ряда второй строки
              // Правую нижнюю точку берем из буфера нижней строки (правая верхняя точка)
              RBpoint = {
                lat:cells[j][0],
                lng:cells[j][1],
              };
            }
            RTpoint = L.GeometryUtil.destination({"lat":RBpoint.lat , "lng":RBpoint.lng}, 0, this.GridSizeBtn);

            cell = [
              [ LBpoint.lat, LBpoint.lng ],
              [ RBpoint.lat, RBpoint.lng ],
              [ RTpoint.lat, RTpoint.lng ],
              [ LTpoint.lat, LTpoint.lng ],
              [ LBpoint.lat, LBpoint.lng ],
            ]
            LBpoint = RBpoint;
            LTpoint = RTpoint;
          }
          // Заносим в буфер строки правые верхние точки
          cells[j] = cell[2]
          // Пуш в результирующий массив
          gridCells.push(cell)
        }
        LBpoint = L.GeometryUtil.destination({"lat":minY , "lng":minX}, 0, this.GridSizeBtn*(i+1));
        RBpoint = L.GeometryUtil.destination({"lat":LBpoint.lat , "lng":LBpoint.lng}, 90, this.GridSizeBtn);
        flag = true;
      }
      cells = null
      this.snoozeAction = 1;
      this.GridLayer = L.polygon(gridCells,{weight:2, color:'black', transform:true, draggable:true}).addTo(this.Map);
      //=====================================
      this.GridLayer.transform.enable({scaling: false});
      this.GridLayer.dragging.enable();
      this.Map.fitBounds(this.GridLayer.getBounds());
      this.GridLayer.transform.reset();
      this.cellArea = (cellSize * cellSize)/ 2;
      
      //не понимаю зачем они, но с ними все работает как надо
      L.DomEvent.fakeStop = function () {
        return true;
      }
      L.DomEvent.skipped = function () {
        return true;
      }
      // this.GridLayer.bringToBack();
    },
    
    GridSetting(){
      this.isLayerOnBack =! this.isLayerOnBack
      if(this.isLayerOnBack){
        this.GridLayer.dragging.disable();
        this.GridLayer.bringToBack();
      }else{
        this.GridLayer.dragging.enable();
        this.GridLayer.bringToFront();
      }
    },
    //Снять выделение с поля
    ResetSelectedLayer(){
      this.SelectedLayer.setStyle({color:'#3388ff',fillColor:'black',fillOpacity:.5})
      this.SelectedLayer.pm.disable();
      this.isFrstStageClick = false;
      this.isScndStageClick = false;
      this.isTrdStageClick = false;
      this.ShowGridBtnSize = false;
      this.isFrthStageClick = false;
      this.GridLayer = null;
      this.snoozeAction = null;
      this.SelectedLayer = null;
      this.EditAfterSave = false;
      this.EditModeIsActive = false;
      
    },
    //кнопка удаление сетки до вырезки
    RemoveGrid(){
      if(this.GridLayer){
        this.GridLayer.transform.disable()
        this.GridLayer.remove()
        this.GridSizeBtn = null;
        this.ShowGridBtnSize = false;
        this.GridLayer = null
        this.SelectedLayer.setStyle({fillColor:'red', weight: 1,snapIgnore: true})
      }
    },
    //удаление слоя без сетки
    RemoveSelectedLayer(){
      this.SelectedLayer.remove();
      this.SelectedLayer = null;
      this.snoozeAction = null;
      this.isFrstStageClick = false;

      if(this.GridLayer){
        this.GridLayer.transform.disable()
        this.GridLayer.remove()
      }

      this.NameLayerInput = '';
      this.CadastralNumberInput = '';
      this.DescriptionInput = '';
      this.AdditionalInput = '';
      this.Region = '';
      this.ActualizationInput = '';
      this.Map.pm.controlsVisible(false)


    },
    LayerEditor(){
      this.EditAfterSave = !this.EditAfterSave;
      this.SavedField = this.SelectedLayer;
      if(!this.EditAfterSave){
        this.SelectedLayer.pm.disable()
        this.EditModeIsActive = false;
      }
    },
    EditableField(){
      this.EditModeIsActive =! this.EditModeIsActive
      //если на кнопку изменить границы нажали второй раз от выведет окно с предупреждением о том что нужно выбрать либо сохранить или отменить
      if(!this.EditModeIsActive){
        this.$buefy.snackbar.open('Необходимо сохранить или отменить действия!');
        this.EditModeIsActive = true
        return false
      }
      else{
        this.resetEditBorders = this.SelectedLayer
      

        if(this.EditModeIsActive){
          this.SelectedLayer.pm.enable({
            allowSelfIntersection: true,
            snapDistance:0,
            snapIgnore: true,
            pinning: true,
            snappable: false
          });

          this.SelectedLayer.setStyle({color:'#3388ff',fillColor:'black',fillOpacity:.5})

          this.SelectedLayer.on('pm:edit', (e) => {
              this.getlatlng = e.layer.getLatLngs()
              let lvalue = L.polygon(this.getlatlng);
              this.getlatlng = lvalue.toGeoJSON().geometry.coordinates
            });
        }
      }

    },
    //откат измнений границ
    ResetBorders(){
      this.SelectedLayer.remove();
      this.ResetSelectedLayer();
      this.GetFieldAfterDeleteGrid(this.SavedField.feature.id)
    },
    //кнопка глобальной резки полигонов
    CarveLayerBtn(){
      setTimeout(() => this.Map.pm.enableGlobalCutMode({
        allowSelfIntersection: true,
        snapDistance:0,
        snapIgnore: true,
        snappable: false
      }), 10);

      this.Map.pm.addControls({
          position: 'topleft',
          dragMode:false,
          cutPolygon:true,
          rotateMode:false,
          removalMode:false,
          editMode:false,
          removalMode:false,
          editControls:true,
          drawMarker: false,
          drawRectangle:false,
          drawCircleMarker:false,
          drawCircle:false,
          drawPolygon:false,
          drawPolyline:false
        });

      this.SelectedLayer.setStyle({fillOpacity:0.4, fillColor:'#000'})
      this.Map.pm.disableGlobalCutMode();
      this.SelectedLayer.on('pm:cut', (e) => {
        let getlatlng = e.layer.getLatLngs();
        let lvalue  = L.polygon(getlatlng);
        getlatlng = lvalue.toGeoJSON().geometry.coordinates
        this.SaveAfterEdit(getlatlng)
      });

    },
    //резка полигона и сетки
    ClipLayer(){
      if(this.isGridDrawn) {return;}
      // this.snoozeAction = null;
      this.Map.removeControl(this.info);
      let grid = this.GridLayer.toGeoJSON(5);
      let layer = this.SelectedLayer.toGeoJSON(5);

      let newLayerCoords = [];

      for(let k = 0;k < grid.geometry.coordinates.length; k++){
        let poly1 = turf.multiPolygon([grid.geometry.coordinates[k]]);
        let poly2 = turf.polygon(layer.geometry.coordinates);
        let intersection = turf.intersect(poly1, poly2);

        if(intersection != null){
          if(intersection.geometry.coordinates.length > 1 && intersection.geometry.type != 'Polygon'){

            for(let i = 0;i < intersection.geometry.coordinates.length; i++){
              let Feature = {
                "type":"Feature",
                "properties":{},
                "geometry":{
                  "type":"Polygon",
                  "coordinates":[]
                }
              }
              Feature.geometry.coordinates.push(intersection.geometry.coordinates[i][0])
              newLayerCoords.push(Feature);
            }
          } else{
            newLayerCoords.push(intersection);
          }
        }
      }


      this.IdOfSelectedLayer = this.SelectedLayer.feature.id

      this.GridSizeBtn = (this.GridSizeBtn * this.GridSizeBtn) / 2;
      let cellSize = this.GridSizeBtn;
      this.FinalLayer = L.geoJSON(newLayerCoords, {weight:2, fillOpacity:.5,fillColor:'#000',
        onEachFeature(feature, layer){
          let sectorSize = turf.area(feature);
          if(cellSize > sectorSize){
            layer.setStyle({fillColor:'yellow', fillOpacity:1})
          }
        }
      }).addTo(this.Map);

      this.AfterUnion = newLayerCoords;
      this.FinalLayer.on('click', this.ListenerClickActions);
      this.GridLayer.remove();
      this.SelectedLayer.remove();
      this.GridLayer.transform.disable();
      this.GridLayer = null;
      this.ID_value = this.SelectedLayer.feature.id
      //флажки
      this.isCellMergeMode = false;
      this.isTrdStageClick = false;
      this.isClickOnGrid = false;
      this.isScndStageClick = false;
      this.isFrstStageClick = false;
      this.ShowGridBtnSize = false;
      this.isFrthStageClick = true;
      this.isPolygonAreDivided = true
    },
    StepBack(e){
      if(!e){
        e = this.ID_value
      }

      this.snoozeAction = null;
      this.GridSizeBtn = null;

      this.GetFieldAfterDeleteGrid(e);

      this.FinalLayer.remove();

      if(this.Union){
        this.BackToStart()
      }
      this.ResetSelectedLayer();
      this.info.addTo(this.Map);
    },
    //объединение двух клетокв одно
    ForUnionCell(){
      this.isCellMergeMode = !this.isCellMergeMode;
      if(this.isCellMergeMode){
        this.FinalLayer.setStyle({dashArray: '10, 10', dashOffset: '10'})
        this.ApplyUnionFn();
      }else{
        for (let i = 0; i < L.PM.Utils.findLayers(this.FinalLayer).length; i++) {
          L.PM.Utils.findLayers(this.FinalLayer)[i].off('click')
        }
        this.layerReadyForUnion = [];
        this.FinalLayer.setStyle({weight:2, fillOpacity:.5,fillColor:'#000', dashArray: '', dashOffset: ''})
      }
    },
    ApplyUnionFn(){
      // Вешаем на все готовые фрагменты событие для отработки объеденения
      for (let i = 0; i < L.PM.Utils.findLayers(this.FinalLayer).length; i++) {
        // на каждый вешаем событие click
          L.PM.Utils.findLayers(this.FinalLayer)[i].on('click', (e) => {
          //если кнопка не активна возвращаемся
          if (this.isCellMergeMode == false) return false;
          //если кнопка активна закинутываем выбранный сетор в массив
          this.layerReadyForUnion.push(e.sourceTarget);

          // если вторая фигура существует в массиве ArrayForUnion и длина массива 2
          if(this.layerReadyForUnion[1] != undefined && this.layerReadyForUnion.length == 2) {

            if(JSON.stringify(this.layerReadyForUnion[0].feature.geometry.coordinates[0]) == JSON.stringify(this.layerReadyForUnion[1].feature.geometry.coordinates[0])){
              this.layerReadyForUnion = [];
              return
            }else{

            //создаем переменные для хранения будущих значений
            let unionFirst,unionSecond;
            //используем стороннюю библиотеку для подготовки к слиянию
            unionFirst = turf.polygon(this.layerReadyForUnion[0].toGeoJSON(5).geometry.coordinates);
            unionSecond = turf.polygon(this.layerReadyForUnion[1].toGeoJSON(5).geometry.coordinates);


            if(unionFirst.geometry.coordinates.length > 1 || unionSecond.geometry.coordinates.length > 1){
              this.LayerWithHole = true;
              this.isHoleCount += unionFirst.geometry.coordinates.length;
              this.isHoleCount += unionSecond.geometry.coordinates.length;
              this.isHoleCount = this.isHoleCount - 2;
            }
            //сливаем 2 сектора и получаем один
            this.unionClip = turf.union(unionFirst,unionSecond);

            if(this.unionClip.geometry.coordinates.length > 1){
              if(this.LayerWithHole){
                if(this.unionClip.geometry.coordinates[0].length - 1 == this.isHoleCount || this.unionClip.geometry.coordinates[1].length - 1 == this.isHoleCount ){
                  this.$buefy.snackbar.open('Попытка слияния секторов не расположенных рядом1!');
                  this.isHoleCount = 0;
                  this.LayerWithHole = false
                  this.layerReadyForUnion = [];
                  return
                }
              }else{
                if(this.unionClip.geometry.coordinates[0].length == 1 || this.unionClip.geometry.coordinates[1].length == 1){
                  this.LayerWithHole = false
                  this.isHoleCount = 0;
                  this.$buefy.snackbar.open('Попытка слияния секторов не расположенных рядом2!');
                  this.layerReadyForUnion = [];
                  return
                }
              }
            }

            this.LayerWithHole = false
            this.isHoleCount = 0;
            this.Union = L.geoJson(this.unionClip, {weight:2, fill:true,fillOpacity:.5,fillColor:'green'})
            .bindPopup(`
              <button id="union" type="button" class="button is-primary">Слить</button>
              <button id="cancel" type="button" class="button is-danger">Отменить</button>`
            )
            .on("click", () => {
              const union = document.getElementById('union');
              union.addEventListener('click', this.ConfirmUnion);

              const cancel = document.getElementById('cancel');
              cancel.addEventListener('click', this.BackToStart);
              })
              .openPopup()
              .addTo(this.Map);
            }
          }
        })
      }
    },
    //применить слияние
    ConfirmUnion(){
      //берем массив до слияния и конвертируем его в GeoJSON
      this.AfterUnion = this.FinalLayer.toGeoJSON(5).features;
      // перебераем массив с выбранными секторами ранее для слияния, передаем эти данные в другой массив в формате GeopJSON
      let unionLayer = [];
      for (let i = 0; i < this.layerReadyForUnion.length; i++) {
        let value = this.layerReadyForUnion[i].toGeoJSON(5);
        unionLayer.push(value);
        this.layerReadyForUnion[i].remove()
        this.Map.removeLayer(this.layerReadyForUnion[i])
      }

      // для упрощение работы делаем деструктуризацию ранее созданного массива массива
      const [first, second] = unionLayer;

      //сравниваем массивы и удаляем повторяющиеся элементы
      for (let i = 0; i < this.AfterUnion.length; i++) {
        if (JSON.stringify(this.AfterUnion[i].geometry.coordinates[0]) == JSON.stringify(first.geometry.coordinates[0])){
          this.AfterUnion.splice(i, 1);
        }
      }

      for (let k = 0; k < this.AfterUnion.length; k++) {
        if(JSON.stringify(this.AfterUnion[k].geometry.coordinates[0]) == JSON.stringify(second.geometry.coordinates[0])){
          this.AfterUnion.splice(k, 1);
        }
      }

      //запихиваем туда элемент который мы получили после слияния
      this.AfterUnion.push(this.unionClip);
      this.Map.removeLayer(this.FinalLayer);
      // this.Map.removeLayer(newGridLayer);
      let cellSize = this.cellArea;
      //отображаем на карте новый массив секторов после слияния
      this.newGridLayer = L.geoJSON(this.AfterUnion,{weight:2, fillOpacity:.5,fillColor:'#000',
        onEachFeature(feature, layer){
          let sectorSize = turf.area(feature);
          if(cellSize > sectorSize){
            layer.setStyle({fillColor:'yellow', fillOpacity:1})
          }
        }
      }).addTo(this.Map);

      //освобождаем массив который был до слияния
      this.FinalLayer = [];

      //после освобождения передаем ссылку получившегося после слияния массива секторов
      this.FinalLayer = this.newGridLayer;

      //выключаем кнопку слияния
      this.isCellMergeMode = false;
      this.Union.remove();
      this.Union = null;
      this.layerReadyForUnion = [];
      this.unionClip = null;
      this.AfterUnion = [];
      this.ForUnionCell();
    },
    //не применять слияние
    BackToStart(){
      this.Union.remove()
      // this.ForUnionCell()
      // this.isCellMergeMode = false;
      this.Union = null;
      this.SelectedLayer.setStyle({color:'#3388ff',fillColor:'black',fillOpacity:.5})
      // this.SelectedLayer = null;
      this.layerReadyForUnion = [];
      this.unionClip = null;
      this.isClickOnGrid = false;
    },
    //Слушатель клика на поля из сервера
    ListenerClickActions(e){
      if(this.mode != 0){
        return;
      }
      if(this.snoozeAction != null){
        return;
      }

      this.SelectedLayer = e.sourceTarget;
      if(this.SelectedLayer.options.weight == 1){
        this.SelectedLayer.setStyle({fillColor:'red'});
        this.isFrstStageClick = true;
        this.isScndStageClick = false;
        this.isTrdStageClick = false;
      }else
      if(this.SelectedLayer.feature.status == 0){
        if(this.SelectedLayer.feature.id){
          this.snoozeAction = 1;
          // this.Map.fitBounds(this.SelectedLayer.getBounds());
          //флажки
          this.isFrstStageClick = false;
          this.isScndStageClick = true;
          this.isTrdStageClick = false;

          this.SelectedLayer.setStyle({fillColor:'red'});
          this.RemoveResults();
        }
      }else if(this.SelectedLayer.feature.status == 1){
          this.Map.fitBounds(this.SelectedLayer.getBounds());
          this.isFrstStageClick = false;
          this.isScndStageClick = true;
          this.isTrdStageClick = false;
          this.SelectedLayer.setStyle({fillColor:'red'});
          this.RemoveResults();
        }else if(this.SelectedLayer.feature.properties.parent_id){
          if(this.EditAfterSave){
            return
          }
          this.snoozeAction = 1;
            //флажки
          this.isScndStageClick = false;
          this.isFrstStageClick = false;
          this.isTrdStageClick = true;
          this.isClickOnGrid = true;
          this.isFrthStageClick = false;
          this.RemoveResults()
          // this.SelectedLayer.setStyle({fillColor:'red'})
        }
        else{
          // this.Map.fitBounds(this.SelectedLayer.getBounds(), {padding: 50});
          this.isScndStageClick = false;
          this.isFrstStageClick = false;
          this.isTrdStageClick = false;
          this.isFrthStageClick = true;
          this.isClickOnGrid = false;
          this.RemoveResults;
        }
    },
    //сохранение без сетки
    SaveField(){
      let LayerWithoutGrid = this.SelectedLayer.toGeoJSON(5);
      this.SelectedLayer.remove()
      this.ResetSelectedLayer();

      if(!this.NameLayerInput && !this.CadastralNumberInput && !this.DescriptionInput && !this.AdditionalInput && !this.ActualizationInput){
        this.$buefy.snackbar.open('Ошибка при заполнении формы поля. Пожалуйста, вернитесь и заполните все поля корректно!');
      }

      let layer = {
        "type":"Feature",
        "properties": {},
        name: this.NameLayerInput,
        cadastral_number:this.CadastralNumberInput,
        description:this.DescriptionInput,
        additional:this.AdditionalInput,
        region:this.Region,
        geometry: {
          "type": "Polygon",
          "coordinates": []
        }
      }

      for (let i = 0; i < LayerWithoutGrid.geometry.coordinates.length; i++) {
        layer.geometry.coordinates.push(LayerWithoutGrid.geometry.coordinates[i]);
      }

      this.NameLayerInput = '';
      this.CadastralNumberInput = '';
      this.DescriptionInput = '';
      this.Region = '';
      this.AdditionalInput = '';
      this.ActualizationInput = '';
      this.PushField(layer);
    },
    //сохранение c сеткой
    SaveGrid(){
      this.GridSizeBtn = null;
      this.snoozeAction = null;
      let griddy = [];

      let finalLayer = this.FinalLayer.toGeoJSON(5)
      for (let i = 0; i < finalLayer.features.length; i++) {
        griddy.push(finalLayer.features[i])
      }

      griddy.sort(function (a, b) {

        let abox = turf.bbox(a)
        let bbox = turf.bbox(b)

        let aw = abox[0]
        let an = abox[3]

        let bw = bbox[0]
        let bn = bbox[3]

        if ( ( Math.abs(abox[3] - bbox[3]) <= 0.0005 || Math.abs(abox[1] - bbox[1]) <= 0.0005) &&  aw > bw) return 1;
        if ( ( Math.abs(abox[3] - bbox[3]) <= 0.0005 || Math.abs(abox[1] - bbox[1]) <= 0.0005) &&  aw < bw) return -1;

        if (an >= bn ) return 1;
        if (an < bn ) return -1;

        return 0;
      });

      this.PushGrid(griddy, this.IdOfSelectedLayer)
      this.ResetSelectedLayer()

    },
    async SaveAfterEdit(layer){
      if(!layer){
        this.$buefy.snackbar.open('Для сохранения необходимы изменения!');
        return 
      }
      

      this.isEditModeActive = false;
      this.AfterSaveEditor = false;

      let temp = {
        "type":"Feature",
        "properties": {},
        name: this.SavedField.feature.name,
        cadastral_number:this.SavedField.feature.cadastral_number,
        description:this.SavedField.feature.description,
        additional:this.SavedField.feature.additional,
        actualization:this.SavedField.feature.actualization,
        id:this.SavedField.feature.id,
        status:this.SavedField.feature.status,
        region:this.SavedField.feature.region,
        geometry: {
          "type": "Polygon",
          "coordinates": []
        }
      }

      if(temp.region == null){
        temp.region = '-'
      }

      if(Array.isArray(layer)){
        for (let i = 0; i < layer.length; i++) {
          temp.geometry.coordinates.push(layer[i]);
        }
      }else{
        for (let i = 0; i < layer.feature.geometry.coordinates.length; i++) {
          temp.geometry.coordinates.push(layer.feature.geometry.coordinates[i]);
        }
      }

      let UpdatedData = {"company_field":temp}

      let newLayer;
      this.openLoading()
      await axios.put(`api/gist/company_fields/${this.company_id}/${this.SavedField.feature.id}`, UpdatedData)
      .then((response) =>{
        newLayer = response.data.field;
        this.$buefy.snackbar.open('Изменения уcпешно сохранены!');
      }).catch(()=>{
        this.$buefy.snackbar.open('Не удалось сохранить изменения, попробуйте ещё раз!');
      });

      // this.$emit('isFieldUpdated')

      this.snoozeAction = null;
      this.openLoading()

      this.SelectedLayer.remove();
      this.ResetSelectedLayer();
      this.GetFieldAfterDeleteGrid(this.SavedField.feature.id)

    },
    ShowAllFieldsAnalizes(){
      let info = this.info;
      if(this.FieldToSeeAllAnalizes){
        this.Map.removeLayer(this.FieldToSeeAllAnalizes)
        this.FieldToSeeAllAnalizes = [];
      }

      if (this.Map.getZoom() <= 13){
        if(this.FieldToSeeAllAnalizes){
          this.Map.removeLayer(this.FieldToSeeAllAnalizes)
          this.FieldToSeeAllAnalizes = [];
        }
        this.Map.addLayer(this.FieldsWithoutGrid)
        this.FieldToSeeAllAnalizes = L.geoJson(this.allFields,{
          style: this.LayerResults,
          onEachFeature:function (feature, layer) {
            layer.on('mouseover', function () {
              let sectorSize = turf.area(feature);
              sectorSize = sectorSize/10000;
              feature.properties.area_hec = sectorSize.toFixed(2);
              feature.properties.sown_area_hec = sectorSize.toFixed(2);
              info.update(feature);
            });
            layer.on('mouseout', function () {
              info.update();
            });
          },
        }).addTo(this.Map)
      }
      if (this.Map.getZoom() >= 14){
        if(this.FieldToSeeAllAnalizes){
          this.Map.removeLayer(this.FieldToSeeAllAnalizes)
          this.FieldToSeeAllAnalizes = [];
        }
        this.Map.addLayer(this.FieldsWithoutGrid)
        this.FieldToSeeAllAnalizes = L.geoJson(this.allFields,{

        onEachFeature:function (feature, layer) {
          if(feature.properties.value){
            layer.bindTooltip('<div>' + feature.properties.value + ' </div>',{
              direction: 'center',
              permanent: true,
              opacity:1,
              className:'labelstyle'
            }
          ).openTooltip()
          }else{
            layer.bindTooltip('<div>'+JSON.stringify(feature.properties.cell)+'</div>',{
              direction: 'center',
              permanent: true,
              opacity:1,
              className: 'labelstyle'
            }).openTooltip()
          }
          layer.on('mouseover', function () {
            let sectorSize = turf.area(feature);
            sectorSize = sectorSize/10000;
            feature.properties.area_hec = sectorSize.toFixed(2);
            feature.properties.sown_area_hec = sectorSize.toFixed(2);
            info.update(feature);
          });
          layer.on('mouseout', function () {
            info.update();
          });
        },
        style: this.LayerResults,
        }).addTo(this.Map)
      }
    },
    //управление зумом
    ZoomControl(){
      if (this.Map.getZoom() >= 14){
        if (this.isAnalizeAreShowen){
          this.ShowAllFieldsAnalizes()
        }

        if(!this.isResultsShowen && !this.isAnalizeAreShowen){
          for (let i = 0; i < this.AllGridSectors.length; i++) {
            this.Map.removeLayer(this.AllGridSectors[i])
          }
          this.ShowDigits();
        }

        // this.SpecificElem = null;
      }

      else if(this.Map.getZoom() <= 13){
        if(this.isAnalizeAreShowen){
          this.ShowAllFieldsAnalizes()
        }
        if(!this.isResultsShowen && !this.isAnalizeAreShowen){
          for (let i = 0; i < this.AllGridSectors.length; i++) {
            this.Map.removeLayer(this.AllGridSectors[i])
          }
          this.HideDigits();
        }
        // this.SpecificElem = null;
      }
    },
    //получение полей из сервера
    async GetField(){
      let onlyFields = [], onlyGrids = [],field = [];
      if(this.company_id){
        await axios.get(`api/gist/company_fields/${this.company_id}/`)
        .then((response) =>{
          for (let i = 0; i < response.data.length; i++) {
            this.AllFieldsData.push(response.data[i]);
          }
        }).catch(()=>{
          this.$buefy.snackbar.open('Не удалось отображить поля!');
        })

        for (let k = 0; k < this.AllFieldsData.length; k++) {
          if(this.AllFieldsData[k].status == 0){
            onlyFields.push(this.AllFieldsData[k])
          }
          // else if(this.AllFieldsData[k].status == 1){
          //   onlyGrids.push(this.AllFieldsData[k])
          // }
        }

      this.GetGrid();
      let info = this.info;
      this.FieldsWithoutGrid = L.geoJson(onlyFields,{
        weight:2,
        fill:true,
        fillOpacity:.5,
        fillColor:'#000',
        onEachFeature:function(feature, layer) {
          layer.on('mouseover', function () {
            info.update(feature);
          });
          layer.on('mouseout', function () {
            info.update();
          });
        }
      }).addTo(this.Map);

      this.FieldsWithoutGrid.on('click', this.ListenerClickActions);
      }
    },
    //получения сетки из сервера
    async GetGrid(){
      await axios.get(`api/gist/company_fields/${this.company_id}/sectors`)
      .then((response) =>{
        this.GetSectors = response.data;
      }).catch((e)=> {
        this.$buefy.snackbar.open('Не удалось отображить сектора полей!');
      })

      let style = {
        weight:2,
        fill:true,
        fillOpacity:.5,
        fillColor:'#000'
      }

      let info = this.info;
      if(this.Map.getZoom() <= 15){
        for (let k = 0; k < this.GetSectors.length; k++) {
          this.FieldWithID = L.geoJSON(this.GetSectors[k], {
            style:style,
            onEachFeature:function (feature, layer) {
              // layer.bindTooltip('<div>'+JSON.stringify(feature.properties.cell)+'</div>',{
              //   direction: 'center',
              //   permanent: true,
              //   opacity:1,
              //   className: 'labelstyle'
              // }).openTooltip()
              layer.on('mouseover', function () {
                this.setStyle({'fillColor': 'red'});
                let sectorSize = turf.area(feature);
                sectorSize = sectorSize/10000;
                feature.properties.area_hec = sectorSize.toFixed(2);
                feature.properties.sown_area_hec = sectorSize.toFixed(2);
                info.update(feature);
              });
              layer.on('mouseout', function () {
                this.setStyle({
                  'fillColor': '#000'
                });
                info.update();
              });
            }
          })
          .addTo(this.Map);
          this.AllGridSectors.push(this.FieldWithID);
          this.FieldWithID.on('click', this.ListenerClickActions);
        }
      }

      if(this.Map.getZoom() >= 15){
        for (let k = 0; k < this.GetSectors.length; k++) {
          this.FieldWithID = L.geoJSON(this.GetSectors[k], {
            style:style,
            onEachFeature:function (feature, layer) {
              layer.bindTooltip('<div>'+JSON.stringify(feature.properties.cell)+'</div>',{
                direction: 'center',
                permanent: true,
                opacity:1,
                className: 'labelstyle'
              }).openTooltip()
              layer.on('mouseover', function () {
                let sectorSize = turf.area(feature);
                sectorSize = sectorSize/10000;
                feature.properties.area_hec = sectorSize.toFixed(2);
                feature.properties.sown_area_hec = sectorSize.toFixed(2);
                info.update(feature);
              });
              layer.on('mouseout', function () {
                info.update();
              });
            }
          }).addTo(this.Map);

          this.AllGridSectors.push(this.FieldWithID);
          this.FieldWithID.on('click', this.ListenerClickActions);
        }
      }
    },
    ShowDigits(){
      let style = {
        weight:2,
        fill:true,
        fillOpacity:.5,
        fillColor:'#000'
      }
      let info = this.info;
      for (let k = 0; k < this.GetSectors.length; k++) {
          this.FieldWithID = L.geoJSON(this.GetSectors[k], {
            style:style,
            onEachFeature:function (feature, layer) {
              layer.bindTooltip('<div>'+JSON.stringify(feature.properties.cell)+'</div>',{
                direction: 'center',
                permanent: true,
                opacity:1,
                className: 'labelstyle'
              }).openTooltip()
              layer.on('mouseover', function () {
                this.setStyle({'fillColor': 'red'});
                let sectorSize = turf.area(feature);
                sectorSize = sectorSize/10000;
                feature.properties.area_hec = sectorSize.toFixed(2);
                feature.properties.sown_area_hec = sectorSize.toFixed(2);
                info.update(feature);
              });
              layer.on('mouseout', function () {
                this.setStyle({
                  'fillColor': '#000'
                });
                info.update();
              });
            }
          }).addTo(this.Map);

          this.AllGridSectors.push(this.FieldWithID);
          this.FieldWithID.on('click', this.ListenerClickActions);
        }
    },
    HideDigits(){
      let style = {
        weight:2,
        fill:true,
        fillOpacity:.5,
        fillColor:'#000'
      }
      let info = this.info;
      for (let k = 0; k < this.GetSectors.length; k++) {
          this.FieldWithID = L.geoJSON(this.GetSectors[k], {
            style:style,
            onEachFeature:function (feature, layer) {
              // layer.bindTooltip('<div>'+JSON.stringify(feature.properties.cell)+'</div>',{
              //   direction: 'center',
              //   permanent: true,
              //   opacity:1,
              //   className: 'labelstyle'
              // }).openTooltip()
              layer.on('mouseover', function () {
                this.setStyle({'fillColor': 'red'});
                let sectorSize = turf.area(feature);
                sectorSize = sectorSize/10000;
                feature.properties.area_hec = sectorSize.toFixed(2);
                feature.properties.sown_area_hec = sectorSize.toFixed(2);
                info.update(feature);
              });
              layer.on('mouseout', function () {
                this.setStyle({
                  'fillColor': '#000'
                });
                info.update();
              });
            }
          }).addTo(this.Map);

          this.AllGridSectors.push(this.FieldWithID);
          this.FieldWithID.on('click', this.ListenerClickActions);
      }
    },
    //сохранение поля на сервер
    async PushField(layer){
      this.snoozeAction = null;
      let data = {"company_field":layer}
      let newLayer;
      this.openLoading()
      await axios.post(`api/gist/company_fields/${this.company_id}/`, data).then((response) =>{
        //ошибки и статус
        newLayer = response.data.field;
        if(response.status == '201'){
          this.$emit('refresh-map',response.status)
        }
        this.openLoading()
        this.$buefy.snackbar.open('Поле ' + response.data.field.name +' успешно сохранено!');
      }).catch(() => {
        this.openLoading()
        this.$buefy.snackbar.open('Не удалось создать поле!');
      });

      this.GetFieldAfterDeleteGrid(newLayer.id)
    },
    //сохранение сетки на сервер
    async PushGrid(layer,id){
      let data = {"sector":layer}
      let AfterSave = [];
      this.openLoading()
      await axios.post(`api/gist/company_fields/${this.company_id}/${id}/multi`, data)
      .then((response) => {
        AfterSave.push(response.data.status.sector)
        if(response.status == '201'){
          this.$emit('refresh-map');
        }
        this.$buefy.snackbar.open('Сектора поля успешно сохранены!');
        this.openLoading()
      }).catch(() => {
        this.$buefy.snackbar.open('Не удалось сохранить сектора поля!');
      });
      this.FinalLayer.remove()

      let style = {
        weight:2,
        fill:true,
        fillOpacity:.5,
        fillColor:'#000',
        permanent:true
      }
      this.info.addTo(this.Map);
      let info = this.info;

      for (let i = 0; i < AfterSave.length; i++) {
        this.SingleGrid = L.geoJSON(AfterSave[i],{
          style:style,
          onEachFeature:function (feature, layer) {
            layer.bindTooltip('<div>'+JSON.stringify(feature.properties.cell)+'</div>',{
                direction: 'center',
                opacity:1,
                permanent: true,
                className: 'labelstyle'
            }).openTooltip()
            layer.on('mouseover', function () {
              this.setStyle({'fillColor': 'red'});
              let sectorSize = turf.area(feature);
              sectorSize = sectorSize/10000;
              feature.name = "Сектор"
              feature.cadastral_number = "Кадастровый номер"
              feature.area_hec = sectorSize.toFixed(2);
              feature.sown_area_hec = sectorSize.toFixed(2);
              info.update(feature);
            });
            layer.on('mouseout', function () {
              this.setStyle({
                'fillColor': '#000'
              });
              info.update();
            });
          }
        }).addTo(this.Map);

        this.SingleGrid.on('click', this.ListenerClickActions);
      }
    },
    //удаление поля из базы данных
    async DeleteField(id){
      this.openLoading()
      await axios.delete(`api/gist/company_fields/${this.company_id}/${id}`)
      .then((response) =>{
        this.openLoading()
        if(response.status == '200'){
          this.$emit('refresh-map');
        }
        this.$buefy.snackbar.open('Поле успешно удалено!');
      }).catch(() => {
        this.openLoading()
        this.$buefy.snackbar.open('Не удалось удалить поле!');
      });
      this.SelectedLayer.remove();
      this.SelectedLayer = null;
      this.EditAfterSave = false;
      this.snoozeAction = null;
    },
    //удаление сетки из базы данных
    async DeleteGrid(id){
      this.idte = id
      this.openLoading();
      await axios.delete(`api/gist/company_fields/${this.company_id}/${id}/sectors`)
      .then((response) =>{
        this.openLoading();
        this.$buefy.snackbar.open('Сектра успешно удалены!');
      }).catch((e) => {
        if(e.response.status == '400'){
          this.openLoading();
          this.$buefy.snackbar.open('По данному полю имеется заявка на анализ!');
          this.isDoNotDelete = true;
        }
      });

      if(!this.isDoNotDelete){
        for (let i = 0; i < this.AllGridSectors.length; i++) {
          if(this.AllGridSectors[i].toGeoJSON().features[0].properties.parent_id == id){
            this.AllGridSectors[i].remove();

          }
        }

        if(this.SingleGrid && this.SingleGrid.toGeoJSON().features[0].properties.parent_id == id){
          this.SingleGrid.remove();
        }

        this.AllGridSectors = this.AllGridSectors.filter(el => {
          if(el.toGeoJSON().features[0].properties.parent_id != id){
            return el;
          }
        });

        for (let i = 0; i < this.AllGridSectors.length; i++) {
          this.Map.removeLayer(this.AllGridSectors[i])
        }
        this.GetGrid();

        this.$emit('ReloadList')
        //тут я должен удалять отрисовку секторов на карте
        this.ResetSelectedLayer();
        this.GetFieldAfterDeleteGrid(id);
      }else{
        this.isDoNotDelete = false;
        return
      }

    },
    //запрос поля после удаления сетки
    async GetFieldAfterDeleteGrid(id){
      this.openLoading()
      let LayerAfterUnGrid;
      await axios.get(`api/gist/company_fields/${this.company_id}/field/${id}`)
      .then((response) =>{
        this.openLoading()
        LayerAfterUnGrid = response.data;
        this.$buefy.snackbar.open('Поле обновлено!');
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка обновления поля!');
      });
      let info = this.info;

      this.FieldsWithoutGrid  = L.geoJSON(LayerAfterUnGrid, {
        weight:2,
        fill:true,
        fillOpacity:.5,
        fillColor:'#000',
        onEachFeature:function(feature, layer) {
          layer.on('mouseover', function () {
            // this.setStyle({'fillColor': 'red'});
            info.update(feature);
          });
          layer.on('mouseout', function () {
            // this.setStyle({
            //   'fillColor': '#000'
            // });
            info.update();
          });
        }
      }).addTo(this.Map);

      // this.Map.fitBounds(this.FieldsWithoutGrid.getBounds());
      this.FieldsWithoutGrid.on('click', this.ListenerClickActions);
    },
    async GetValueOfElement(element){
      if(!element){
       return
      }else{
        await axios.get(`api/gist/legends/${element}`)
        .then((response) => {
          this.SpecificElem = response.data;
        }).catch((e) => {
          this.$buefy.snackbar.open('Не удалось получить легенду!');
        });
      }
    },
    async GetAnalyze(element){
      await this.GetElement()
      this.isResultsShowen = true;
      const info = L.control();

      info.onAdd = function(map){
        this._div = L.DomUtil.create('div', 'info');
        this.update();
        return this._div;
      }

      this.ResultInfo = info;

      function highlightFeature(e) {
        let layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        info.update(layer.feature.properties);
      }

      let geojson;

      function resetHighlight(e) {
        this.ResultDoneLayer.resetStyle(e.target);
        info.update();
      }
    await this.GetAnalyzResults()
    //отрисовываем поле с секторами с соответствующими цветами

      geojson = L.geoJson(this.Results,{
      onEachFeature:function (feature, layer) {
        layer.bindTooltip('<div>' + feature.properties.value + ' </div>',{
          direction: 'center',
          permanent: true,
          opacity:1,
          className:'labelstyle'
        }).openTooltip()
      },
      style: this.LayerResults,
    })


    if(this.ResultLayer){
      this.RemoveResults()
    }

    // await this.Map.whenReady( async (e)=>{
    //   try {
    //     const image = await this.doScreenShot()
        // console.log(image, this.order_id, this.field_id, this.element_id);
      // } catch (err) {
        // console.log(err);
      // }
    // }, this.ResultLayer)

    this.ResultLayer = geojson;
    this.ResultLayer.addTo(this.Map);

    this.isResultsShowen = true;
    this.GetValueOfElement(element);
    },
    LayerResults(feature) {
      if(feature.properties.value){
        return {
          weight: 2,
          opacity: 1,
          color:feature.properties.color,
          fillOpacity: 0.7,
          fillColor:feature.properties.color
        }
      }else{
        return {
          weight:2,
          fill:true,
          fillOpacity:.5,
          fillColor:'#000'
        };
      }
    },
    RemoveResults(){
      if(this.ResultLayer){
        this.Map.removeLayer(this.FieldToSeeAllAnalizes)
        this.FieldToSeeAllAnalizes = [];
        this.ResultLayer.remove();
        this.ResultInfo.remove();
        // this.SpecificElem = null;
        this.isResultsShowen = false;
      }
    },
    async GetElement(){
      this.openLoading()
      await axios.get(`api/gist/elements`)
      .then((response) =>{
        this.openLoading()
        this.ListOfElements = response.data;
        if(this.ElementToAllFields){
          for (let i = 0; i < this.ListOfElements.length; i++) {
            if(this.ListOfElements[i].id == this.ElementToAllFields){
              this.ElementName = this.ListOfElements[i]
            }
          }
        } else {
          for (let i = 0; i < this.ListOfElements.length; i++) {
            if(this.ListOfElements[i].id == this.element_id){
              this.ElementName = this.ListOfElements[i]
            }
          }
        }

      }).catch((e) => {
        this.$buefy.snackbar.open('Не удалось загрузить элементы легенды!');
      });
    },
    async GetAnalyzResults(){
      if(this.element_id == null || this.order_id == null || this.field_id == null){
       return
      }else{
        await axios.get(`api/client/orders/result/${this.order_id}/${this.field_id}/${this.element_id}`)
        .then((response) =>{
          this.Results = response.data.results;
        }).catch(() => {
          this.$buefy.snackbar.open('Не удалось получить результаты анализов!');
        });
      }

      for (let h = 0; h < this.Results.length; h++) {
        if(this.Results[h].properties.value == null){
          this.Results[h].properties.value = ' '
        }
      }
    },
    async FitContent(fields){
      if(fields.length == 0 || !Array.isArray(fields)){
        return
      }
      let arrayField = [];
      let value;
      let geojson = [];

      for (let k = 0; k < this.AllFieldsData.length; k++) {
        for (let i = 0; i < fields.length; i++) {
          if(this.AllFieldsData[k].id == fields[i]){
            value = L.geoJson(this.AllFieldsData[k], {fillColor:'red',color: "red" }).addTo(this.Map)
            geojson.push(value)
            arrayField.push(this.AllFieldsData[k]);
          }
        }
      }

      setTimeout(() => {
        for (let i = 0; i < geojson.length; i++) {
          this.Map.removeLayer(geojson[i])
        }
      }, 1000);

      if(!this.isAnalizeAreShowen){
        this.ZoomControl();
      }

      let multipolygon = L.geoJson(arrayField);
      this.Map.fitBounds(multipolygon.getBounds());
    },
  },
};
</script>

<style>
  #map{
    height: calc(100vh - 56px);
  }
  #search{
    border-radius: 10px;
  }
  #gridButton{
    border: 2px solid #f4f4f4;
    border-radius: 50%;
    height: 50px;
    background-color:#fff;;
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size:30px;
    color: black;
  }
  .form-box{
    border: 1px solid  #A9A9A9;
    border-radius: 15px;
    padding: 20px;
    width: 400px;
    margin: 0 auto;
  }
  .selfStyle-btn{
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
  }
  .active-btn{
    background-color: #7fc12d;
    font-weight: bold;
    color:#fff;
  }
  .main{
    padding: 0;
    margin: 0;
    position: relative;
  }
  .console{
    position: absolute;
    top: 10px;
    left: 50px;
    background: none;
    z-index: 10000;
  }
  .labelstyle{
    background: none !important;
    border: none !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    box-shadow: none !important;
  }
  .labelstyleResizeMap{
    background: none !important;
    border: none !important;
    color: black !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    box-shadow: none !important;
  }
  .info{
    background-color: white;
    padding: 10px;
  }
  .my-custom-control{
    background-color: white;
    padding: 20px;
    border-radius: 20px;
  }
  .bar_slider{
    display: flex ;
    justify-content: space-between;
  }
  .slide_item{
    margin-right: 20px;
    width: 70%;
  }
  .unit_style{
    margin-left: 20px;
  }
  .elements{
    position: absolute;
    right:40px;
    bottom: 50px;
    background-color: white;
    z-index: 401;
    font-size: 12px;
    border-radius: 20px;
    flex-direction: column;
    padding: 20px;
    padding-right:50px;
  }

  @media print{
    .elements{
      width: 30%;
    }
  }

  .list_elem{
    align-items: center;
  }
  .reset_pad{
    padding: 0 !important;
    margin: 0 !important;
  }
  .elem_list{
    min-width:50%;
  }

  .map{
    height: 100vh;
  }

  .buttons-style{
    margin: 20px;
  }

  .slidecontainer{
    position: absolute;
    left: 40px;
    width: 200px;
    border-radius:20px;
    bottom: 50px;
    background-color: #fff;
    padding: 10px 25px;
    z-index: 9999;
  }

  .create-modal{
    z-index: 10000;
  }

  /* =============================== */
  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .legend {
    line-height: 18px;
    color: #555;
  }
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }

  .leaflet-buttons-control-button{
    width: 50px !important;
    height: 50px !important;
  }

</style>