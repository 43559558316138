<template>
  <!-- таблица истории заказов у клиента -->
  <section class="px-4 py-4" v-if="isRendered">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    <!-- <router-link tag="button" class="is-small is-flex button is-primary is-justify-content-flex-end mx-3 my-3" to="/client/orders">Назад</router-link> -->

    <div class="is-flex is-justify-content-space-between">
      <router-link tag="button" class="is-small is-flex button is-primary is-justify-content-flex-end mx-3 my-3" to="/client/orders">Назад</router-link>
      <b-input placeholder="№ заказа/название поля"
        v-model="searchingOrder"
        type="search"
        icon="magnify"
        icon-right-clickable
        @icon-right-click="searchingOrder = ''">
      </b-input>
    </div>

    <div v-if="orders.length > 0" >
      <b-table :data="orders">
        <b-table-column label="№" field="count" centered sortable v-slot="props">
          {{ props.row.count }}
        </b-table-column>

        <b-table-column label="Название компании" field="is_done" centered sortable v-slot="props">
          <p v-if="props.row.company"> {{ props.row.company.name}}</p>
          <p v-else> - </p>
        </b-table-column>
        <b-table-column label="Название поля" centered sortable v-slot="props">
          <p v-for="field in props.row.fields" :key="field.id">{{field.name || '-'}}</p>
        </b-table-column>
        <b-table-column label="Площадь" centered sortable v-slot="props">
          <p v-for="field in props.row.fields" :key="field.id">{{field.area_hec || '-'}} га</p>
        </b-table-column>
        <b-table-column label="Кол-во секторов" centered sortable v-slot="props">
          <p v-for="field in props.row.fields" :key="field.id">{{field.sectors_count }}</p>
        </b-table-column>
        <b-table-column label="Пользователь" centered sortable v-slot="props">
          <p>{{props.row.user.first_name || '-'}} {{props.row.user.last_name || '-'}} {{props.row.user.middle_name || '-'}}</p>
        </b-table-column>
        <template v-for="column in columns">
          <b-table-column sortable :key="column.id" v-bind="column">
              <template
                  v-if="column.searchable"
                  #searchable="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Search..."
                      icon="magnify"
                      size="is-small" />
              </template>
              <template v-slot="props">
                  {{ props.row[column.field] }}
              </template>
          </b-table-column>
        </template>
        <b-table-column label="Готово" field="is_done" centered sortable v-slot="props">
          <p :class="props.row.is_done ? 'ready' : ''"> {{ props.row.is_done ? "да" : "нет" }}</p>
        </b-table-column>
        <b-table-column label="Время обработки" field="count" centered sortable v-slot="props">
          {{ props.row.speed }}
        </b-table-column>
        <b-table-column custom-key="actions" label="Удаление" cell-class="is-actions-cell" v-slot="props" width="100">
          <div class="buttons is-right" v-if="props.row.is_done === 0">
            <button
              class="button is-small is-danger"
              type="button"
              @click.prevent="deleteAnalyze(props.row.id)"
            >
              Удалить
            <b-icon class="ml-1" icon="trash-can" size="is-small"/>
            </button>
          </div>
        </b-table-column>
      </b-table>
      <template v-if="!SearchMode">
        <Pagination :total="total" :crntPage="selected" @CurrentPage="pagination"/>
      </template>
    </div>
    <div v-else>
      <p class="title">Нет истории заказов</p>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Pagination from '@/components/pagination/GeneralPagination.vue'
export default {
  components:{
    Pagination
  },
  data() {
    return {
      orders:[],
      searchingOrder:'',
      SearchMode:false,
      openOnFocus: true,
      clearable: true,
      rounded: false,
      total:null,
      columns:[
        {
          field: 'id',
          label: 'Номер заказа',
          centered:true,
        },
        {
          field: 'created_at',
          label: 'Дата создания',
          centered:true,
        },
      ],
      fields:[],
      selected: 0,
      isLoading:false,
      isRendered:false
    };
  },
  mounted() {
    this.getFields();
    this.getCompanyOrders(0, 10);
  },
  computed:{
    filteredOrdersByName() {
      return this.orders.filter((order) => order.id
        .toString()
      );
    },
  },
  watch:{
    searchingOrder(){
      if(this.searchingOrder.length == 0 && this.orders){
        this.getCompanyOrders(0, 10);
      }else if(this.searchingOrder.length > 2){
        this.SearchOrder(this.searchingOrder)
      }
    }
  },
  methods: {
    pagination(value){
      this.selected =  Math.ceil(value.pass / 10);
      this.getCompanyOrders(value.pass, value.count);
    },
    async getCompanyOrders(pass, item) {
      this.isLoading = true;
      await axios.get(`api/client/orders/?offset=${pass}&count=${item}`)
        .then((response) => {

          if(pass != 0 && response.data.items.length == 0){
            pass = pass - 10;
            this.getCompanyOrders(pass, 10);
            this.selected = Math.ceil((pass / 10));
            return
          }

          this.total = response.data.count
          this.orders = response.data.items;
          this.total = Math.ceil(this.total / 10);
          let count = pass;
          for (let i = 0; i < this.orders.length; i++) {
            this.orders[i].count = (count++) + 1;
          }

        }).catch((e) => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка заказов',
            type: 'is-danger',
          });
        });
        this.isRendered = true;
        this.isLoading = false;
    },
    async SearchOrder(value){
      if(value.length < 2 || value.length > 10){
       this.$buefy.snackbar.open('Вводимое значение должно быть не менее 2 и не более 10 символов!');
       return
      }

      await axios.get(`api/client/orders/?offset=0&count=10&name=${value}`)
      .then(response =>{
        this.orders = response.data.items;
        this.total = this.orders.length;
        this.total = Math.ceil(this.total / 10);
        let count = 0;
        for (let i = 0; i < this.orders.length; i++) {
          this.orders[i].count = (count++) + 1;
        }
      })
      .catch((error)=>{
      })
      this.isRendered = true;
      this.isLoading = false;
      this.SearchMode = true;

    },
    deleteAnalyze(e){
      this.isLoading = true;
      axios.delete(`api/client/orders/${e}`).then(response =>{
        this.isLoading = false;
        this.refresh(this.selected);
      }).catch((e)=>{
        this.isLoading = false;
        this.$buefy.toast.open({
        message: 'Не удалось удалить заказ',
        type: 'is-danger',
      });
      })
    },
    getFields() {
      axios.get('/api/client/company_fields/')
        .then((response) => {
          this.fields = response.data;
        }).catch(() => {
          this.$buefy.snackbar.open('Ошибка получения списка полей компании');
        });
      },
    refresh(CurrentPage) {
      CurrentPage = CurrentPage * 10
      this.getCompanyOrders(CurrentPage, 10);
      this.$buefy.toast.open({
        message: 'Данные обновлены',
        type: 'is-info',
      });
    },
  },
};
</script>
