<template>
  <!-- вывод информации в левом краю -->
   <div class="px-3 py-3 is-flex is-flex-direction-column is-align-items-flex-start has-background-light">
    <p><small>Кадастровый номер:{{field.cadastral_number}}</small></p>
    <p><small>Площадь:{{field.area_hec}} га</small></p>
    <p><small>Площадь посева:{{field.sown_area_hec}} га</small></p>
    <p><small>Регион:{{field.region}}</small></p>
    <p><small>Дата создания:{{field.created_at}}</small></p>
    <p v-if="field.updated_at"><small>Дата изменения:{{field.updated_at}}</small></p>
    <template v-if="field.status == 1">
      <p><small>✅ Разделен</small></p>
    </template>
    <template v-else>
      <p><small>❌ Не разделен</small></p>
    </template>
    <div class="is-flex is-align-self-stretch mt-5">
      <button class="button is-flex-grow-1 is-primary is-outlined" @click="isLinkShow =! isLinkShow">скачать</button>
    </div>
    <template v-if="field.status == 1">
      <a v-if="isLinkShow" class="py-2 px-2" target="_blank" @click="downloadFieldsGrid(field, 1)">Cкачать GeoJSON c сеткой</a>
      <a v-if="isLinkShow" class="py-2 px-2" target="_blank" @click="downloadFieldsGrid(field, 2)">Cкачать KML c сеткой</a>
      <a v-if="isLinkShow" class="py-2 px-2" target="_blank" @click="downloadFieldsGrid(field, 3)">Cкачать SHP c сеткой</a>
    </template>
    <a v-if="isLinkShow" class="py-2 px-2" target="_blank" @click="downloadFields(field, 1)">Cкачать GeoJSON без сетки</a>
    <a v-if="isLinkShow" class="py-2 px-2" target="_blank" @click="downloadFields(field, 2)">Cкачать KML без сетки</a>
    <a v-if="isLinkShow" class="py-2 px-2" target="_blank" @click="downloadFields(field, 3)">Cкачать SHP без сетки</a>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return{
      isLinkShow:false
    }
  },
  props:['field'],
  methods:{
    downloadFields(e, num){
      let url = null;
      if(num == 1){
        url = `${axios.defaults.baseURL}/api/download/field/${e.id}`;
      }else if(num == 2){
        url = `${axios.defaults.baseURL}/api/download/kml/field/${e.id}`;
      }else if(num == 3){
        url = `${axios.defaults.baseURL}/api/download/shp/field/${e.id}`;
      }
      window.location.href = url;
    },
    downloadFieldsGrid(e, num){
      let url = null;
      if(num == 1){
        url = `${axios.defaults.baseURL}/api/download/field/${e.id}/grid`;
      }else if(num == 2){
        url = `${axios.defaults.baseURL}/api/download/kml/field/${e.id}/grid`;
      }else if(num == 3){
        url = `${axios.defaults.baseURL}/api/download/shp/field/${e.id}/grid`;
      }
      window.location.href = url;
    },
  }
}
</script>
