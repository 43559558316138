<template>
  <section v-if="role === '4'">
    <div class="conteiner-1" >
      <b-loading :is-full-page="isFullPage" v-model="isLoading" ></b-loading>
      <div class="columns py-5" >
        <div class="column is-2">
          <div class="card pb-2">
            <div class="card-content">
              <p class="subtitle">Выберите компанию</p>
              <section>
                <!-- //поиск по названию -->
                <template v-if="!CompanyBin">

                  <b-field label="Поиск по Наименованию">
                    <b-autocomplete
                      v-model="nameComp"
                      ref="autocomplete"
                      field="name"
                      :data="filteredCompaniesByName"
                      :keep-first="keepFirst"
                      :open-on-focus="openOnFocus"
                      placeholder="Введите название..."
                      @select="option => CompanyName = option"
                      :selectable-header="selectable"
                      :clearable="clearable"
                    >
                    <template #empty>Нет результатов по {{nameComp}}</template>
                    </b-autocomplete>
                  </b-field>

                  <template v-if="CompanyName">
                    <b-field label="БИН" type="is-success">
                      <b-input v-model="CompanyName.bin" disabled></b-input>
                    </b-field>
                  </template>

                </template>
                <!-- //кнопка поиск по БИН -->
                <template v-if="!CompanyName">
                  <template v-if="CompanyBin">
                    <b-field label="Название" type="is-success">
                      <b-input v-model="CompanyBin.name" disabled></b-input>
                    </b-field>
                  </template>

                  <b-field label="Поиск по БИН">
                    <b-autocomplete
                      v-model="bin"
                      ref="autocomplete"
                      field="bin"
                      :data="filteredCompaniesByBin"
                      :keep-first="keepFirst"
                      :open-on-focus="openOnFocus"
                      placeholder="Введите название..."
                      @select="option => CompanyBin = option"
                      :selectable-header="selectable"
                      :clearable="clearable"
                    >
                    <template #empty>Нет результатов по {{bin}}</template>
                    </b-autocomplete>
                  </b-field>

                </template>
              </section>
              <!-- //кнопка поиск по названию -->
              <template v-if="CompanyName">
                <button class="button is-small is-primary mt-3" type="button"
                  @click.prevent="getCompanyOrders(CompanyName.id); setCurrentCompany(CompanyName.id)">
                  Применить
                </button>
              </template>
              <!-- //кнопка поиска по БИН -->
              <template v-else-if="CompanyBin">
                <button class="button is-small is-primary mt-3" type="button"
                  @click.prevent="getCompanyOrders(CompanyBin.id); setCurrentCompany(CompanyBin.id)">
                  Применить
                </button>
              </template>
            </div>
            <div class="mx-4" v-if="currentCompany">
              <b-checkbox-button v-model="onlyReadyOrders"
                native-value="1"
                @input="page=1; getCompanyOrders(currentCompany)"
                class="my-1"
                type="is-primary">
                <span>Только готовые</span>
              </b-checkbox-button>

              <b-datepicker
                v-model="OrderByDates"
                @input="orderByDate"
                class="my-2"
                placeholder="Нажмите для выбора даты"
                range>
              </b-datepicker>
              <button v-if="OrderByDates.length > 0" class="button is-outlined is-primary" @click="resetDateFormat">Сброс даты</button>
            </div>
          </div>
        </div>
        <!-- //вывод таблицы если была выбранная конкретная компания -->
        <div v-if="orders.length > 0" class="column">
          <p class="table-title">Текущие заказы компании {{CompanyName.ownership_type}} {{CompanyName.name}}</p>
          <div>
            <b-table :data="orders">
              <b-table-column label="№" field="number" centered v-slot="props">
                {{ props.row.number }}
              </b-table-column>
              <b-table-column label="Название компании" field="is_done" centered v-slot="props">
                <p v-if="props.row.company"> {{ props.row.company.name}}</p>
                <p v-else> - </p>
              </b-table-column>
              <b-table-column label="Название поля" field="is_done" centered v-slot="props">
                <p v-for="field in props.row.fields" :key="field.id"> {{ field.name }}</p>
              </b-table-column>
                <b-table-column label="Площадь" field="is_done" centered v-slot="props">
                <p v-for="field in props.row.fields" :key="field.id"> {{field.area_hec || '-'}} га</p>
              </b-table-column>
              <b-table-column label="Кол-во секторов" centered v-slot="props">
                <p v-for="field in props.row.fields" :key="field.id">{{field.sectors_count }}</p>
              </b-table-column>
              <template v-for="column in columns">
                <b-table-column sortable :key="column.id" v-bind="column">
                  <template
                    v-if="column.searchable"
                    #searchable="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Search..."
                      icon="magnify"
                      size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row[column.field] }}
                  </template>
                </b-table-column>
              </template>

              <b-table-column label="Готово" field="is_done" centered sortable v-slot="props">
                <p :class="props.row.is_done ? 'ready' : ''"> {{ props.row.is_done ? "да" : "нет" }}</p>
              </b-table-column>
              <b-table-column label="Время обработки" field="count" centered sortable v-slot="props">
                {{ props.row.speed }}
              </b-table-column>
              <b-table-column label="Пользователь" field="is_done" centered sortable v-slot="props">
                {{props.row.user.first_name }} {{props.row.user.last_name}} {{props.row.user.middle_name}}
              </b-table-column>

              <b-table-column custom-key="actions" label="Скачать" cell-class="is-actions-cell"
                v-slot="props">
                <div v-if="props.row.is_done === 0" class="buttons is-right">
                  <button class="button is-small is-primary" type="button"
                    @click.prevent="DownloadFile(props.row.id)">
                      Скачать файл <b-icon class="ml-1" icon="flask" size="is-small"/>
                  </button>
                </div>
                <div v-else>
                  <button class="button is-small is-primary" type="button"
                    @click.prevent="DownloadFile(props.row.id)" disabled>
                      Скачать файл <b-icon class="ml-1" icon="flask" size="is-small"/>
                  </button>
                </div>
              </b-table-column>

              <b-table-column custom-key="actions" label="Загрузка" cell-class="is-actions-cell"
                v-slot="props">
                <div v-if="props.row.is_done === 0" class="buttons is-right">
                  <button class="button is-small is-info" type="button"
                    @click.prevent="uploadResultsModal(props.row.id)">
                      Загрузить результаты <b-icon class="ml-1" icon="flask" size="is-small"/>
                  </button>
                </div>
                <div v-else>
                  <button class="button is-small is-info" type="button"
                    @click.prevent="uploadResultsModal(props.row.id)" disabled>
                      Загрузить результаты <b-icon class="ml-1" icon="flask" size="is-small"/>
                  </button>
                </div>
              </b-table-column>
              <b-table-column  custom-key="actions" label="Удаление" cell-class="is-actions-cell"
                v-slot="props" width="150">
                <div v-if="props.row.is_done === 1" class="buttons is-right">
                  <button class="button is-small is-danger" type="button"
                    @click.prevent="DeleteResults(props.row.id)">
                      Удалить<b-icon class="ml-1" icon="delete" size="is-small"/>
                  </button>
                </div>
              </b-table-column>
            </b-table>

            <b-select placeholder="Select a name" class="my-2" v-model="items" @input="page = 1; getCompanyOrders(currentCompany)">
                <option>10</option>
                <option v-if="total >= 50" >50</option>
                <option v-if="total >= 100">100</option>
            </b-select>
            <div class="mx-4">
              <b-pagination
              order="is-centered"
              :total="total"
              v-model="page"
              @change="PageChange"
              :per-page="items"
            >
            </b-pagination>
            </div>
          </div>
        </div>
        <!-- //За последние 3 месяца результаты активные заказы -->
        <div v-else-if="orders.length == 0 && (!nameComp)">
          <ActiveOrders/>
        </div>
        <div v-if="orders.length == 0 && (nameComp)" class="spacing">
          <p class="is-size-2 "> Ничего не найдено </p>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <p class="title">Данный функционал доступен только Лаборанту</p>
  </section>
</template>

<script>
import axios from 'axios';
import UploadResultsModal from '@/components/lab/UploadResultsModal.vue';
import Pagination from '@/components/pagination/GeneralPagination.vue'
import ActiveOrders from '@/components/lab/ActiveOrders.vue'

export default {
  components:{
    Pagination,
    ActiveOrders
  },
  data() {
    return {
      page:1,
      items:10,
      totalItem:100,
      OrderByDates:[],
      keepFirst: false,
      ShowAlarm:false,
      selectable: false,
      isFullPage: true,
      isLoading:false,
      dateFrom:'',
      dateTo:'',
      selected: 0,
      current:0,
      item:'',
      CompanyBin:'',
      CompanyName: '',
      Companyfield:'',
      nameComp: '',
      bin: '',
      namefld:'',
      role: null,
      total:null,
      companies: [],
      orders: [],
      name: '',
      onlyReadyOrders:[],
      alphabetSort:'',
      selectedName: null,
      selectedBin: null,
      openOnFocus: true,
      clearable: true,
      currentCompany: null,
      columns: [
        {
          field: 'id',
          label: 'Номер заказа',
          numeric: true,
          centered:true,
        },
        {
          field: 'created_at',
          label: 'Дата создания',
          centered: true,
        },
      ]
    };
  },
  beforeMount(){
    //проверка роли до инициализации DOM
    this.checkRole();
  },
  mounted() {
    if (this.role === '4') {
      //если это лаборант запрашиваем список компаний
      this.getCompanies();
    }
  },
  watch:{
    // выбор параметров для взаимодействия с картой
    selectedBin() {
      this.orders = [];
    },
    CompanyName(){
      if(!this.CompanyName){
        this.nameComp = ''
        this.CompanyBin = null;
        this.CompanyName = null;
        this.bin = '';
        this.namefld = '';
        this.orders = [];
        this.ShowAlarm = false;
      }
    },
    CompanyBin(){
      if(!this.CompanyBin){
        this.nameComp = ''
        this.CompanyBin = null;
        this.CompanyName = null;
        this.bin = '';
        this.namefld = '';
        this.orders = [];
        this.ShowAlarm = false;
      }
    },
  },
  methods: {
    orderByDate(){
      //запрос информации о заказах по дате
      this.parseDate();
      this.getCompanyOrders(this.currentCompany)
    },
    PageChange(value){
      //метод пагинации
      this.getCompanyOrders(this.currentCompany)
    },
    parseDate(){
      //подгонка нужного формата для корректного поиска заказов
      let [startDate, endDate] = this.OrderByDates;
      if(startDate && endDate){
        this.dateFrom = this.formatDate(startDate)
        this.dateTo = this.formatDate(endDate)
      }
    },
    formatDate(date) {
      //форматирования даты под нужный формат
      let day = date.getDate();
      let monthIndex = date.getMonth();
      let year = date.getFullYear();
      let formattedDate = (
          (year)
          +'-'+
          (monthIndex < 10 ? '0' + (monthIndex+1) : monthIndex +1)
          +'-'+
          (day < 10 ? '0' + day:day)
        )
      return formattedDate
    },
    checkRole() {
      //проверка роли в локал сторэйдж
      this.role = localStorage.role;
    },
    resetDateFormat(){
      //сброс дейт пикера
      this.OrderByDates = [];
      this.dateFrom = '', this.dateTo = ''
      this.getCompanyOrders(this.currentCompany)
    },
    ResetSearch(){
      //сброс поиска
      if(this.selectedBin){
        this.selectedBin = null
      }
      else if(this.selectedName){
        this.selectedName = null
      }
      this.fields = [];
    },
    setCurrentCompany(id) {
      //задаем id компании
      this.currentCompany = id;
    },
    async getCompanies() {
      //запрос компаний
      await axios.get('/api/laborant/companies')
        .then((response) => {
          this.companies = response.data;
          this.companies.filter((option) => {
            if(option.bin == null ){
              option.bin = 0;
            }
          })
        }).catch(() => {
          this.$buefy.snackbar.open('Ошибка получения списка компаний');
        });
    },
    async getCompanyOrders(id, from='', to='', isDone='') {
      //запрос заказов компании
      if(id == null){
        return;
      }

      this.isLoading = true;
      await axios.get(`api/laborant/analyzes/${id}/orders?offset=${(this.page*this.items)-this.items}&count=${this.items}`, {
        params:{
          ...(this.dateFrom && ({from:this.dateFrom})),
          ...(this.dateTo && ({to:this.dateTo})),
          ...(this.onlyReadyOrders.length > 0 && ({ isDone: 1 }))
        }
      })
        .then((response) => {
          this.total = response.data.count;
          this.orders = response.data.items.map((item, idx) => {
            return {
              number: ++idx + (this.page * this.items) - this.items,
              ...item
            }
          });
        }).catch((error) => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка заказов',
            type: 'is-danger',
          });
      });
      this.isLoading = false;
    },
    async DeleteResults(id){
      //удаление результатов по заказу
      this.isLoading = true;
      await axios.post(`api/laborant/analyzes/unset/${id}`).then(response => {
        this.$buefy.snackbar.open('Успешно удалено!');
        this.refresh(this.currentCompany);
      }).catch((error)=>{
        this.$buefy.snackbar.open('Не удалось удалить анализ!');
      })
      this.isLoading = false;
    },
    refresh(currentCompany) {
      //обновление данных на странице
      this.getCompanyOrders(currentCompany);
      this.$buefy.toast.open({
        message: 'Данные обновлены',
        type: 'is-info',
      });
    },
    uploadResultsModal(id) {
      //загрузка результатов, модальное окно
      this.$buefy.modal.open({
        parent: this,
        component: UploadResultsModal,
        props: {
          orderId: id,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isResultIsUploaded': () => {
            this.refresh(this.currentCompany, this.current);
          }
        }
      });
    },
    DownloadFile(order_id){
      //скачивания анализов
      const url = `${axios.defaults.baseURL}/api/laborant/analyzes/download/${order_id}`;
      window.location.href = url;
    }
  },
  computed: {
    // фильтры для выводов в списках
    filteredCompaniesByBin() {
      return this.companies.filter((option) => option.bin
        .toString()
        .toLowerCase()
        .indexOf(this.bin.toLowerCase()) >= 0);
    },
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
  },
};
</script>

<style scoped>
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.mg-left-5{
  margin-left: 5px;
}
.table-title{
  color: white;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 0;
  margin: 15px 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  background-color: #61a937;
}

.card{
  width: 400px;
}

.search{
  padding-bottom: 10px;
}
.conteiner-1{
  width: 100%;
  margin: 0;
  padding: 0;
}
.refresh-btn{
  display: flex;
  align-content: flex-end;
}
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.highlight {
  background: #61a937;
}
.spacing{
    margin: 0 auto;
    display: flex;
    align-items: center;
}

</style>
