<template>
  <!-- данный модуль/страница/компонент не используется, пока что -->
  <section v-if="role === '1'">
    <p>Документооборот</p>
  </section>
  <section v-else>
     <p class="title">Данный функционал доступен только Администратору</p>
  </section>
</template>
<script>

export default {
  beforeMount() {
    this.checkRole();
  },
  methods: {
    checkRole() {
      this.role = localStorage.role;
    },
  },
};
</script>
