<template>
  <!-- модалка редактирования -->
   <div class="modal is-active">
    <div class="modal-background" @click="CloseModal"></div>
    <div class="modal-content modal-content-box has-background-white is-flex is-flex-direction-column px-4 py-4">
      <form @submit.prevent="SendUpdatedInfo">
        <p class="title is-size-5">Редактировать поле</p>
        <label class="subtitle is-6">Наименование поля</label>
        <input type="text" class="input mb-3" v-model="FieldToEdit.name">
        <label class="subtitle is-6">Кадастровый номер</label>
        <input type="text" class="input mb-3" v-model="FieldToEdit.cadastral_number">
        <label class="subtitle is-6">Дополнительно</label>
        <input type="text" class="input mb-3" v-model="FieldToEdit.additional">
        <label class="subtitle is-6">Описание</label>
        <input type="text" class="input mb-3" v-model="FieldToEdit.description">
        <label class="subtitle is-6">Регион</label>
        <input type="text" class="input mb-3" v-model="FieldToEdit.region" required>
        <div class="is-flex py-4 mx-3">
          <button class="button mr-2 is-danger is-outlined" @click="CloseModal">Отменить</button>
          <button type="submit" class="button is-primary is-outlined">Изменить</button>
        </div>
      </form>
    </div>
    <button @click="CloseModal" class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
export default {
  props:['FieldToEdit'],
  methods:{
    CloseModal(){
      this.$emit('CloseModal')
    },
    SendUpdatedInfo(){
      let data = {
        "actualization": this.FieldToEdit.actualization,
        "name":this.FieldToEdit.name,
        "additional": this.FieldToEdit.additional,
        "description": this.FieldToEdit.description,
        "cadastral_number":this.FieldToEdit.cadastral_number,
        "properties":this.FieldToEdit.properties,
        "status":this.FieldToEdit.status,
        "type":this.FieldToEdit.type,
        "geometry":this.FieldToEdit.geometry,
        "region":this.FieldToEdit.region,
      }
      this.$emit('SendUpdatedInfo', data)
    }
  }
}
</script>

<style scoped>
.modal-content-box{
  width: 280px !important;
}
</style>

// <EditFieldModal v-if="EditModal" @CloseModal="CloseModal" @SendUpdatedInfo="SendUpdatedInfo" :FieldToEdit="FieldToEdit"/>
// import EditFieldModal from '@/components/modal/EditFieldModal.vue';
// components: {
//   MapDarkanDala,
//   EditFieldModal
// },


// CloseModal(){
//   this.EditModal = false;
// },

// async SendUpdatedInfo(value){
//   this.EditModal = false;
//   let key = {"company_field":value}
//   await axios.put(`api/gist/company_fields/${this.company_id}/${this.FieldToEdit.id}`, key)
//   .then(response => {
//   }).catch(() => {
//     this.$buefy.snackbar.open('Ошибка сохранения поля');
//   });
// },
