<template>
  <section v-if="role === '1'">
    <!-- лоадер для асинхронных запросов -->
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    <div class="is-flex is-justify-content-space-between my-3">
      <div class="ml-3 is-flex is-align-self-flex-end">
        <b-input placeholder="Название или БИН"
          v-model="searchingComp"
          type="search"
          icon="magnify"
          icon-right-clickable
          @icon-right-click="searchingComp = ''">
        </b-input>
      </div>
      <b-button size="is-small"
        icon-left="account-multiple-plus"
        @click="createCompanyModal()"
        class="mr-3 is-primary">
        Добавить компанию
      </b-button>
    </div>
    <template v-if="isPageLoaded">
      <div class="title" v-if="companies.length == 0">
        Ничего не найдено.
      </div>
      
      <b-table v-else :data="companies" @sort="onSort" >
        <b-table-column label="№" field="count" centered sortable  v-slot="props">
          {{ props.row.count }}
        </b-table-column>
        <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
                <template
                    v-if="column.searchable && !column.numeric"
                    #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Поиск..."
                        icon="magnify"
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                </template>
            </b-table-column>
        </template>
        <b-table-column label="Дата создния" field="created_at" centered sortable  v-slot="props">
          {{ props.row.created_at.substring(0,10) }} {{ props.row.created_at.substring(11,19) }}
        </b-table-column>
        <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props" width="100">
          <div class="buttons is-right">
            <button class="button is-small is-info" type="button"
            @click.prevent="editCompanyModal(props.row)">
              <b-icon icon="account-edit" size="is-small"/>
            </button>
            <button class="button is-small is-danger" type="button"
            @click.prevent="deleteCompanyModal(props.row.id)">
              <b-icon icon="trash-can" size="is-small"/>
            </button>
          </div>
        </b-table-column>
      </b-table>
      <template v-if="!SearchMode">
        <Pagination :total="total" :crntPage="selected" @CurrentPage="CompanyPagination"/>
      </template>
    </template>

  </section>
  <section v-else>
     <p class="title">Данный функционал доступен только Администратору</p>
  </section>
</template>
<script>
import axios from 'axios';
import CompanyCreateModal from '@/components/admin/CompanyCreateModal.vue';
import CompanyEditModal from '@/components/admin/CompanyEditModal.vue';
import CompanyDeleteModal from '@/components/admin/CompanyDeleteModal.vue';
import Pagination from '@/components/pagination/GeneralPagination.vue'
import LoginVue from '../authentication/Login.vue';

export default {
  components:{
    Pagination
  },
  data() {
    return {
      role: null,
      isLoading:false,
      SearchMode:false,
      isPageLoaded:false,
      selected: 0,
      companies: [],
      total: 0,
      searchingComp:'',
      searchResult:[],
      sort:true,
      columns: [
        {
          field: 'name',
          label: 'Наименование',
          centered:true
        },
        {
          field: 'ownership_type',
          label: 'Форма собственности',
          centered:true
        },
        {
          field: 'bin',
          label: 'БИН',
          centered:true
        },
        {
          field: 'legal_address',
          label: 'Юридический Адрес',
          centered:true
        },
        {
          field: 'actual_address',
          label: 'Фактический Адрес',
          centered:true
        },
      ]
    };
  },
  beforeMount() {
    //запуск проверки роли
    this.checkRole();
  },
  mounted() {
    //проверка роди и вывод соответствующей информации
    if (this.role === '1') {
      this.getCompanies(0,10);
    }
  },
  watch:{
    //поиск компании
    searchingComp(){
      if(this.searchingComp.length == 0 && this.companies){
        this.getCompanies(0, 10);
      }else if(this.searchingComp.length == 4){
        this.SearchCompany(this.searchingComp)
      }
    }
  },
  methods: {
    onSort(){
      this.companies = []
      this.isLoading = true;
      this.sort = !this.sort
      this.selected = 0
      this.getCompanies(0,10)
      this.isLoading = false;
    },
    //проверка роли пользователя
    checkRole() {
      this.role = localStorage.role;
    },
    async SearchCompany(value){
      if(value.length < 4){
       this.$buefy.snackbar.open('Вводимое значение должно быть не менее 4 символов!');
       return
      }

      await axios.get(`/api/admin/companies/?name=${value}`)
      .then(response =>{
        this.companies = response.data;
        this.total = this.companies.length;
        this.total = Math.ceil(this.total / 10);
        let count = 0;
        for (let i = 0; i < this.companies.length; i++) {
          this.companies[i].count = (count++) + 1;
        }
      })
      .catch((error)=>{
      })
      this.isPageLoaded = true;
      this.isLoading = false;
      this.SearchMode = true;
    },
    CompanyPagination(value){
      // пагинация для компаний
      this.selected = Math.ceil(value.pass / 10);
      this.getCompanies(value.pass, value.count);
    },
    async getCompanies(pass, items) {
      //запрос списка компаний
      this.isLoading = true;
      await axios.get(`/api/admin/companies/count`)
      .then(response => {
        this.total = response.data.count;
        this.total = Math.ceil(this.total / 10);
      })

      await axios.get(`/api/admin/companies/${pass}/${items}?order_by=${this.sort ? 'asc' : 'desc'}`)
      .then((response) => {

        if(pass != 0 && response.data.length == 0){
          pass = pass - 10;
          this.getCompanies(pass, 10);
          this.selected = Math.ceil((pass / 10));
          return
        }

        this.companies = response.data

        let count = pass;
        for (let i = 0; i < this.companies.length; i++) {
          this.companies[i].count = (count++) + 1;
        }
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка получения списка компаний!');
      });
      this.isPageLoaded = true;
      this.isLoading = false;
      this.SearchMode = false;

    },
    refresh(selectedPage) {
      // обновление списка копаний после каких либо операций в таблице
      if(this.searchingComp){
        this.SearchCompany(this.searchingComp)
      }else{
        selectedPage = selectedPage * 10;
        this.getCompanies(selectedPage, 10);
      }
      this.$buefy.toast.open({
        message: 'Данные обновлены',
        type: 'is-info',
      });
    },
    createCompanyModal() {
      //инициализация открытия модального окна для создания компании
      this.$buefy.modal.open({
        parent: this,
        component: CompanyCreateModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isCompanyCreated': () => {
            this.refresh(this.selected);
            this.isLoading =! this.isLoading
          }
        },
      });
    },
    editCompanyModal(company) {
      //инициализация открытия модального окна для редактировая компании
      this.$buefy.modal.open({
        parent: this,
        component: CompanyEditModal,
        props: company,
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isCompanyEdited': () => {
            this.refresh(this.selected);
            this.isLoading =! this.isLoading
          }
        },
      });
    },
    deleteCompanyModal(companyId) {
      //инициализация открытия модального окна для удаления компании
      this.$buefy.modal.open({
        parent: this,
        component: CompanyDeleteModal,
        props: {
          id: companyId,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isCompanyDeleted': () => {
            this.refresh(this.selected);
            this.isLoading =! this.isLoading
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.mg-left-5{
  margin-left: 5px;
}
</style>
