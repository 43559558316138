<template>
  <!-- кнопка и модалка удаления компании -->
    <form action="">
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title has-text-centered">Удаление Компании</p>
        </header>
        <section class="modal-card-body">
          <p>Вы действительно хотите удалить Компанию?</p>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Отмена"
            @click="$emit('close')" />
          <b-button
            label="Удалить"
            type="is-danger"
            @click="deleteCompany();" />
        </footer>
    </div>
</form>
</template>
<script>
import axios from 'axios';
export default {
  props: {
    id: Number,
  },
  methods: {
    async deleteCompany() {
      await axios.delete(`/api/admin/companies/${this.id}`)
      .then((response) => {
        this.$buefy.snackbar.open(response.data.status);
      }).catch(() => {
        this.$buefy.snackbar.open('Не удалось удалить компанию');
      });
      this.$emit('isCompanyDeleted')
      this.$emit('close')
    },
  },
};
</script>
