<template>
  <!-- модалка редактирования компании -->
    <form action="">
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title has-text-centered">Редактирование Компании</p>
        </header>
        <section class="modal-card-body">
          <b-field label="ID">
              <b-input v-model="company.id" disabled></b-input>
          </b-field>
          <template v-if="company.ownership_type != 'ПК'">
            <b-field label="Форма собcтвенности">
              <b-select v-model="company.ownership_type" :placeholder="company.ownership_type" >
                <option value="ИП">ИП</option>
                <option value="ТОО">ТОО</option>
                <option value="КХ">КХ</option>
                <option value="ПК">ПК</option>
              </b-select>
            </b-field>
          </template>
          <template v-else>
            <b-field label="Форма собcтвенности">
              <b-select v-model="company.ownership_type" :placeholder="company.ownership_type" disabled>
                <option value="ИП">ИП</option>
                <option value="ТОО">ТОО</option>
                <option value="КХ">КХ</option>
                <option value="ПК">ПК</option>
              </b-select>
            </b-field>
          </template>
            <b-field label="Название">
              <b-input v-model="company.name"></b-input>
            </b-field>
            <template v-if="company.ownership_type != 'ПК'">
              <b-field label="БИН / ИИН">
                <b-input v-model="company.bin"></b-input>
              </b-field>

              <b-field label="Юридический адрес">
                <b-input maxlength="200" type="textarea"
                v-model="company.legal_address"></b-input>
              </b-field>

              <b-field label="Фактический адрес">
                  <b-input maxlength="200" type="textarea"
                  v-model="company.actual_address"></b-input>
              </b-field>
            </template>
            <template v-else>
              <div class="pk-input">
                <b-field label="Выбрать ТОО">
                  <b-autocomplete
                    v-model="nameComp"
                    ref="autocomplete"
                    field="name"
                    :data="filteredCompaniesByName"
                    :open-on-focus="openOnFocus"
                    placeholder="Выбрать ТОО..."
                    @select="option => CompanyName = option"
                    :selectable-header="selectable"
                    :clearable="clearable"
                  >
                  <template #empty>Нет результатов по {{nameComp}}</template>
                  </b-autocomplete>
                </b-field>
              </div>
          </template>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Отмена"
            @click="$emit('close')" />
          <b-button
            label="Сохранить"
            type="is-primary"
            @click="editCompany();"/>
        </footer>
    </div>
</form>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      companies:[],
      nameComp:'',
      openOnFocus:true,
      CompanyName:null,
      selectable: true,
      clearable: true,
      company: {
        id: this.id,
        ownership_type: this.ownership_type,
        name: this.name,
        bin: this.bin,
        legal_address: this.legal_address,
        actual_address: this.actual_address,
        parent_id:this.parent_id
      },
    };
  },
  props: {
    id: Number,
    ownership_type: String,
    name: String,
    bin: String,
    legal_address: String,
    actual_address: String,
    parent_id:Number
  },
  beforeMount(){
    this.getCompanies();
  },
  watch:{
    'company.ownership_type'(){
      if(this.company.ownership_type == 'ПК'){
        this.company.bin = null;
        this.company.legal_address = null;
        this.company.actual_address = null;
        this.getCompanies();
      }else{
        this.CompanyName = null;
      }
    }
  },
  computed:{
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
  },
  methods: {
    async editCompany() {
      if(this.company.ownership_type == 'ПК'){
        this.company.parent_id = this.CompanyName.id;
      }

      const data = {
        company: this.company,
      };
      await axios.put(`/api/admin/companies/${this.id}`, data)
        .then((response) => {
          this.$buefy.snackbar.open(response.data.status);
        }).catch(() => {
          this.$buefy.snackbar.open('Не удалось отредактировать компанию');
        });
        this.$emit('isCompanyEdited')
        this.$emit('close')
    },
    async getCompanies() {
      await axios.get('/api/admin/companies/')
      .then((response) => {
        this.companies = response.data;
        if(this.company.ownership_type == 'ПК'){
          this.companies = this.companies.filter(el => {
            return el.ownership_type == "ТОО"
          })
          this.CompanyName = this.companies.find(item => item.id === this.parent_id);
          this.nameComp = this.CompanyName.name;
        }
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка получения списка компаний!');
      });
    },
  },
};
</script>

<style scoped>
.pk-input{
  height: 250px;
}
</style>
