<template>
  <section>
   <!-- компонент авторизации -->
    <div v-if="WrongDetails" class="wronglogin notification is-danger">
      <button class="delete" @click="WrongDetails =! WrongDetails"></button>
      Неверный логин или пароль
    </div>
    <div class="columns is-gapless auth">
      <div class="column welcome-box">
        <div class="auth-form">
          <h2 class="auth-form-welcome-title">Добро пожаловать!</h2>
          <div class="auth-form-welcome-logo" />
        </div>
      </div>
      <div class="column">
        <h5>Авторизация</h5>
        <form @submit.prevent="loginAttempt">
          <b-field>
            <b-input
              v-model="user.login"
              placeholder="Введите логин"
              name="login"
              required
              icon="account"
            />
          </b-field>

          <b-field>
            <b-input
              type="password"
              v-model="user.password"
              placeholder="Введите пароль"
              name="password"
              value="p@$$w0rD"
              required
              icon="lock"
              password-reveal
            />
          </b-field>

          <b-field>
            <div class="control">
              <button type="submit" class="button is-primary">ВОЙТИ</button>
            </div>
          </b-field>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: {
        login: null,
        password: null,
      },
      role: null,
      WrongDetails: false,
    };
  },
  mounted() {
    localStorage.removeItem('token');
    this.$root.user = null;
    this.$root.config = null;
    axios.defaults.headers.common.token = null;
    clearInterval(this.$root.timer);
  },
  methods: {
    checkRole() {
      this.role = localStorage.role;
    },
    loginAttempt() {
      const data = {
        user: this.user,
      };
      axios.post('/api/user/signin', data)
        .then((response) => {
          localStorage.setItem('token', response.data.tokens[0].token);
          localStorage.setItem('role', response.data.roles[0].id);
          localStorage.setItem('user_first_name', response.data.first_name);
          localStorage.setItem('user_last_name', response.data.last_name);
          localStorage.setItem('company_id', response.data.companies[0].id);
          localStorage.setItem('company_ownership_type', response.data.companies[0].ownership_type);
          localStorage.setItem('company_name', response.data.companies[0].name);
          localStorage.setItem('company_bin', response.data.companies[0].bin);
          localStorage.setItem('company_legal_address', response.data.companies[0].legal_address);
          axios.defaults.headers.common.token = `${response.data.tokens[0].token}`;
          this.checkRole();
          if (this.role === '1') this.$router.push('/admin/companies');
          if (this.role === '2') this.$router.push('/gis/editfields');
          if (this.role === '3') this.$router.push('/client/fields');
          if (this.role === '4') this.$router.push('/lab/orders');
        })
        .catch((err) => {
          this.WrongDetails = true;
        });
    },
  },
};
</script>

<style scoped>
.auth {
  padding: 5%;
}
.auth-form {
  margin: auto;
  padding-right: 20px;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border-radius: 20px;
  justify-content: space-between;
}
.auth-form-welcome-logo {
  background: url("../../assets/DD_rgb.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 200px;
  height: 102px;
  content: "";
  display: block;
  margin: auto;
}
.auth-form-welcome-title {
  color: #4e4e4e;
}
.wronglogin{
  display:block;
}
/* .welcome-box{
  background-color: red;
} */
</style>
