<template>
  <section v-if="role === '4'">
    <b-loading :is-full-page="true" v-model="isLoading" ></b-loading>
    <div class="conteiner-1">
      <div class="columns">
        <div class="column is-2 side-bar-view py-2">
          <div class="card">
            <div class="card-content">
              <p class="subtitle">
                Выберите компанию
              </p>
              <section>
                <template v-if="!CompanyBin">
                  <b-field label="Поиск по Наименованию">
                    <b-autocomplete
                      v-model="nameComp"
                      ref="autocomplete"
                      field="name"
                      :data="filteredCompaniesByName"
                      :keep-first="keepFirst"
                      :open-on-focus="openOnFocus"
                      placeholder="Введите название..."
                      @select="option => CompanyName = option"
                      :selectable-header="selectable"
                      :clearable="clearable"
                    >
                    <template #empty>Нет результатов по {{nameComp}}</template>
                    </b-autocomplete>
                  </b-field>
                </template>

                <template v-if="CompanyName">
                  <b-field label="БИН" type="is-success">
                    <b-input v-model="CompanyName.bin" disabled></b-input>
                  </b-field>
                </template>

                <template v-if="!CompanyName">
                    <b-field label="Поиск по БИН">
                      <b-autocomplete
                        v-model="bin"
                        ref="autocomplete"
                        field="bin"
                        :data="filteredCompaniesByBin"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        placeholder="Введите БИН..."
                        @select="option => CompanyBin = option"
                        :selectable-header="selectable"
                        :clearable="clearable"
                      >
                    <template #empty>Нет результатов по {{bin}}</template>
                    </b-autocomplete>
                  </b-field>
                </template>

                <template v-if="CompanyBin">
                  <b-field label="Название" type="is-success">
                    <b-input v-model="CompanyBin.name" disabled></b-input>
                  </b-field>
                </template>

              </section>

              <template v-if="CompanyName">
                  <button class="button is-small is-primary mt-3" type="button"
                    @click.prevent="getCompanyFields(CompanyName.id); setCompanyId(CompanyName.id)">
                    Применить
                  </button>
                </template>
                <template v-else-if="CompanyBin">
                  <button class="button is-small is-primary mt-3" type="button"
                    @click.prevent="getCompanyFields(CompanyBin.id); setCompanyId(CompanyBin.id)">
                    Применить
                  </button>
              </template>
            </div>
          </div>
          <div class="">
            <div class="card-content px-2 py-1">
              <section class="my-3">
                <template v-if="fields.length != 0">
                  <b-field label="Поиск названию поля">
                    <b-autocomplete
                        v-model="namefld"
                        ref="autocomplete"
                        field="name"
                        :data="filteredFieldsByName"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        placeholder="Введите название..."
                        @select="option => Companyfield = option"
                        :selectable-header="selectable"
                        :clearable="clearable"
                      >
                      <template #empty>Не результатов по {{namefld}}</template>
                      </b-autocomplete>
                    </b-field>
                    <div class="my-3">
                      <button v-if="HideAllFields" class="button is-primary is-outlined" @click="ShowAllField">Показать всё {{fields.length}}</button>
                      <button v-if="!HideAllFields" class="button is-primary is-outlined" @click="HideAllFields = true">Скрыть поля {{fields.length}}</button><br>
                      <div class="is-flex is-justify-content-space-between">
                        <button v-if="fieldsCheckbox.length > 1" class="my-2 button is-primary is-outlined is-small" @click="isModalOpen = true;">Скачать координаты {{fieldsCheckbox.length}} полей</button>
                        <button v-if="fieldsCheckbox.length > 1" @click="fieldsCheckbox = []" class="my-2 button is-danger is-outlined is-small">Сброс</button>
                      </div>
                    </div>
                </template>
              </section>
              <section class="list-box">
                <div v-for="field in filteredFieldsByName" :key="field.id" class="is-flex is-flex-direction-column">
                  <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
                    <b-field class="fields-filter" >
                      <!-- <b-radio
                        v-model="selectedField"
                        :native-value="field.id"
                        @click.native="toField(field.id)"
                      >
                       <p @click.prevent="toField(selectedField)">{{field.name}}</p>
                      </b-radio> -->

                          <b-checkbox
                        v-model="fieldsCheckbox"
                        :native-value="field.id">
                       {{field.name}}
                      </b-checkbox>
                    </b-field>
                    <!-- <div @click="EditFieldData(field)">
                      <button class="button is-small is-primary is-outlined">Изменить</button>
                    </div> -->
                  </div>
                  <FieldInfoFrame :field="field"/>
                  <hr>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="column px-5 py-2">
          <p v-if="!render" class="table-title">Выберите компанию</p>
          <template v-if="isModalOpen">
            <DownloadModal :fieldsCheckbox="fieldsCheckbox" @CloseModal="CloseModal"/>
          </template>
          <EditFieldModal v-if="EditModal" @CloseModal="CloseModal" @SendUpdatedInfo="SendUpdatedInfo" :FieldToEdit="FieldToEdit"/>
          <section class="map" v-if="render">
            <MapDarkanDala :zoomSnap="zoomSnap" :mode="mode" :company_id="company_id" :field_id="field_id"
              :HideFields="HideAllFields"  :fitBoundID="fieldsCheckbox"/>
              <!-- :FitToThisPlot="FitToThisPlot" -->
          </section>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <p class="title">Данный функционал доступен только Гис-специалисту</p>
  </section>
</template>
<script>
import axios from 'axios';
import MapDarkanDala from '../../components/map/MapDarkanDala.vue';
import FieldInfoFrame from '@/components/modal/FieldInfoFrame.vue';
import EditFieldModal from '@/components/modal/EditFieldModal.vue';
import DownloadModal from '@/components/modal/DownloadAllFields.vue';

export default {
  name: 'Map',
  components: {
    MapDarkanDala,
    FieldInfoFrame,
    EditFieldModal,
    DownloadModal
  },
  data() {
    return {
      FitToThisPlot:[],
      role: null,
      isModalOpen:false,
      fieldsCheckbox:[],
      HideAllFields:false,
      isLoading:false,
      keepFirst: false,
      selectable: false,
      zoomSnap:1,
      users: [],
      fields: [],
      CompanyName: '',
      CompanyBin:'',
      Companyfield:'',
      nameComp: '',
      bin: '',
      namefld:'',
      fieldName:'',
      mode: 1,
      company_id: 0,
      field_id: null,
      selectedBin: null,
      companies: [],
      render: true,
      selectedName: null,
      openOnFocus: true,
      clearable: true,
      rounded: false,
      multiple: false,
      selectedField: null,
      fitBoundID: null,
      detailsShown: false,
      open: true,
      fullheight: false,
      overlay: false,
      FieldToEdit:null,
      EditModal:false,
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
        },
        {
          field: 'first_name',
          label: 'Имя',
          centered: true,
        },
        {
          field: 'middle_name',
          label: 'Отчество',
          centered: true,
        },
        {
          field: 'last_name',
          label: 'Фамилия',
          centered: true,
        },
        {
          field: 'login',
          label: 'Логин',
          centered: true,
        },
        {
          field: 'role',
          label: 'Роль',
          centered: true,
        },
        {
          field: 'company_name',
          label: 'Компания',
          centered: true,
        },
        {
          field: 'email',
          label: 'Email',
          centered: true,
        },
        {
          field: 'created_at',
          label: 'Дата создания',
          centered: true,
        },
        {
          field: 'updated_at',
          label: 'Дата обновления',
          centered: true,
        },
        {
          field: 'is_active',
          label: 'Активирован',
          centered: true,
        },
      ],
    };
  },
  beforeMount() {
    this.checkRole();
    if (this.role === '2' || this.role === '4') {
      this.getCompanies();
    }
  },
  computed: {
    filteredCompaniesByBin() {
      return this.companies.filter((option) => option.bin
        .toString()
        .toLowerCase()
        .indexOf(this.bin.toLowerCase()) >= 0);
    },
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
    filteredFieldsByName() {
      return this.fields.filter((field) => field.name
        .toString()
        .toLowerCase()
        .indexOf(this.namefld.toLowerCase()) >= 0);
    },
    map_data(id) {
      return (
        {
          company_id: 9,
          mode: 0,
          field_id: id,
        }
      );
    },
  },
  watch: {
    CompanyName(){
      if(!this.CompanyName){
        this.fields = [];
        this.nameComp = ''
        this.bin = '';
        this.namefld = '';
        this.company_id = null;
      }
    },
    CompanyBin(){
      if(!this.CompanyBin){
        this.fields = [];
        this.nameComp = ''
        this.bin = '';
        this.namefld = '';
        this.company_id = null;
      }
    },
    HideAllFields(newValue){
      if(newValue){
        this.fieldsCheckbox = []
      }
    },
  },
  methods: {
    ShowAllField(){
      this.fieldsCheckbox = this.fields.map(el => {
        return el.id;
      });
      this.HideAllFields = false
    },
    CloseModal(){
      this.EditModal = false;
      this.isModalOpen = false;
    },
    EditFieldData(field){
      this.FieldToEdit = field
      this.EditModal = true;
    },
    async SendUpdatedInfo(value){
      this.EditModal = false;
      this.isLoading = true;
      let key = {"company_field":value}
      await axios.put(`api/gist/company_fields/${this.company_id}/${this.FieldToEdit.id}`, key)
      .then(response => {
        this.$buefy.snackbar.open('Изменения успешно сохранены!');
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка при изменения информации о поле!');
      });
      this.isLoading = false;
    },
    checkRole() {
      this.role = localStorage.role;
    },
    async getCompanies() {
      this.isLoading = true;
      await axios.get('/api/laborant/companies')
        .then((response) => {
          this.companies = response.data;
          this.companies.filter((option) => {
            if(option.bin == null ){
              option.bin = 0;
            }
          })
        }).catch(() => {
          this.$buefy.snackbar.open('Ошибка получения списка компаний');
        });
      this.isLoading = false;
    },
    renderMap() {
      this.render = true;
    },
    async getCompanyFields(id) {
      this.fields = [];
      this.isLoading = true;
      await axios.get(`/api/gist/company_fields/${id}/`)
        .then((response) => {
          this.fields = response.data;
          this.renderMap(id);
          this.FitToThisPlot = response.data;
        }).catch((e) => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка полей компании',
            type: 'is-danger',
          });
        });
      this.isLoading = false;
    },
    setCompanyId(id) {
      this.company_id = id;
    },
    showFieldDetails() {
      this.detailsShown = !this.detailsShown;
    },
  },
};
</script>
<style scoped>
.column{
  padding: 0;
}
.side-bar-view{
  padding-left: 20px;
}
.conteiner-1{
  width: 100%;
}
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.mg-left-5{
  margin-left: 5px;
}

.fields-filter{
  text-align: left;
}
.map{
  margin-top: 12px;
  height: 100%;
}
.list-box{
  /* height:60vh; */
  height: calc(100vh - 550px);
  overflow: scroll;
}
.modal-content-box{
  width: 280px !important;
}
.modal{
  z-index: 100100000342;
}
</style>
