<template>
  <section v-if="role === '2'">
    <b-loading :is-full-page="true" v-model="isLoading" ></b-loading>
    <div class="conteiner-1">
      <div class="columns">
        <div class="column is-2 main-card">
          <div class="card">
            <div class="card-content">
              <p class="subtitle">Выберите компанию</p>
              <section>

                <template v-if="!CompanyBin">
                  <b-field label="Поиск по наименованию">
                    <b-autocomplete
                      v-model="nameComp"
                      ref="autocomplete"
                      field="name"
                      :data="filteredCompaniesByName"
                      :keep-first="keepFirst"
                      :open-on-focus="openOnFocus"
                      placeholder="Введите название..."
                      @select="option => CompanyName = option"
                      :selectable-header="selectable"
                      :clearable="clearable"
                    >
                    <template #empty>Нет результатов по {{nameComp}}</template>
                    </b-autocomplete>
                  </b-field>
                </template>

                <template v-if="CompanyName">
                  <b-field label="БИН" type="is-success">
                    <b-input v-model="CompanyName.bin" disabled></b-input>
                  </b-field>
                </template>

                <template v-if="CompanyBin">
                  <b-field label="Название" type="is-success">
                    <b-input v-model="CompanyBin.name" disabled></b-input>
                  </b-field>
                </template>

                <template v-if="!CompanyName">
                    <b-field label="Поиск по БИН">
                      <b-autocomplete
                        v-model="bin"
                        ref="autocomplete"
                        field="bin"
                        :data="filteredCompaniesByBin"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        placeholder="Введите БИН..."
                        @select="option => CompanyBin = option"
                        :selectable-header="selectable"
                        :clearable="clearable"
                      >
                    <template #empty>Нет результатов по {{bin}}</template>
                    </b-autocomplete>
                  </b-field>
                </template>

              </section>



              <template v-if="CompanyName">
                <button class="button is-small is-primary mt-3" type="button"
                  @click.prevent="getCompanyFields(CompanyName.id); setCompanyId(CompanyName.id)">
                  Применить
                </button>
              </template>
              <template v-else-if="CompanyBin">
                <button class="button is-small is-primary mt-3" type="button"
                  @click.prevent="getCompanyFields(CompanyBin.id); setCompanyId(CompanyBin.id)">
                  Применить
                </button>
              </template>
            </div>
          </div>

          <div class="card">
            <div class="card-content px-2 py-2">
              <section class="my-3">
                <template v-if="fields.length != 0">
                  <b-field label="Поиск названию поля">
                    <b-autocomplete
                        v-model="namefld"
                        ref="autocomplete"
                        field="name"
                        :data="filteredFieldsByName"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        placeholder="Введите название..."
                        @select="option => Companyfield = option"
                        :selectable-header="selectable"
                        :clearable="clearable"
                      >
                      <template #empty>Не результатов по {{namefld}}</template>
                      </b-autocomplete>
                    </b-field>
                    <div class="my-3">
                      <button v-if="HideAllFields" class="button is-primary is-outlined" @click="ShowAllField">Показать всё</button>
                      <button v-if="!HideAllFields" class="button is-primary is-outlined" @click="HideAllFields = true">Скрыть поля</button><br>
                      <div class="is-flex is-justify-content-space-between">
                        <button v-if="fieldsCheckbox.length > 1" class="my-2 button is-primary is-outlined is-small" @click="isModalOpen = true;">Скачать координаты {{fieldsCheckbox.length}} полей</button>
                        <button v-if="fieldsCheckbox.length > 1" @click="fieldsCheckbox = []" class="my-2 button is-danger is-outlined is-small">Сброс</button>
                      </div>
                    </div>
                </template>
              </section>
              <section class="list-box">
                <div v-for="field in filteredFieldsByName" :key="field.id" class="is-flex is-flex-direction-column">
                  <div class="list-box-inner">
                      <b-field class="fields-filter">
                        <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">

                          <!-- <b-radio
                            @click.native="toField(field.id)"
                            v-model="Companyfield"
                            :native-value="field.id">
                           {{field.name}}
                          </b-radio> -->

                          <b-checkbox
                        v-model="fieldsCheckbox"
                        :native-value="field.id">
                       {{field.name}}
                      </b-checkbox>
                          <div @click="EditFieldData(field)">
                            <button class="button is-small is-primary is-outlined">Изменить</button>
                          </div>
                        </div>
                        <!-- <template v-if="EditModal">
                          <div class="modal is-active">
                            <div class="modal-background" @click="EditModal = false"></div>
                            <div class="modal-content modal-content-box has-background-white is-flex is-flex-direction-column px-4 py-4">
                              <form @submit.prevent="SendUpdatedInfo">
                                <p class="my-3 is-uppercase has-text-weight-semibold">Редактировать поле</p>
                                <label for="">Кадастровый номер</label>
                                <input type="text" class="input" v-model="FieldToEdit.cadastral_number">
                                <label for="">Дополнительно</label>
                                <input type="text" class="input" v-model="FieldToEdit.additional">
                                <label for="">Описание</label>
                                <input type="text" class="input" v-model="FieldToEdit.description">
                                <label for="">Регион</label>
                                <input type="text" class="input" v-model="FieldToEdit.region">
                                <div class="buttons is-flex py-4">
                                  <button type="submit" class="button is-info">Изменить</button>
                                  <div class="button is-danger" @click="EditModal = false">Отменить</div>
                                </div>
                              </form>
                            </div>
                            <button @click="EditModal = false" class="modal-close is-large" aria-label="close"></button>
                          </div>
                        </template> -->
                      </b-field>
                  </div>
                  <FieldInfoFrame :field="field"/>
                  <hr>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="column pr-5 py-2">
          <template v-if="isModalOpen">
            <DownloadModal :fieldsCheckbox="fieldsCheckbox" @CloseModal="CloseModal"/>
          </template>
          <EditFieldModal v-if="EditModal" @CloseModal="CloseModal" @SendUpdatedInfo="SendUpdatedInfo" :FieldToEdit="FieldToEdit"/>
          <section class="map" v-if="render">
            <MapDarkanDala  @ReloadList="isActionDone" @isFieldUpdated="UpdateSidebar" @refresh-map="refreshMap" :zoomSnap="zoomSnap" :mode="mode" :company_id="company_id" :field_id="field_id" :HideFields="HideAllFields" :fitBoundID="fieldsCheckbox"/>
            <!-- :FitToThisPlot="FitToThisPlot" -->
          </section>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <p class="title">Данный функционал доступен только Гис-специалисту</p>
  </section>
</template>
<script>

import axios from 'axios';
import MapDarkanDala from '@/components/map/MapDarkanDala.vue';
import FieldInfoFrame from '@/components/modal/FieldInfoFrame.vue';
import EditFieldModal from '@/components/modal/EditFieldModal.vue';
import DownloadModal from '@/components/modal/DownloadAllFields.vue';

export default {
  name: 'Map',
  components: {
    MapDarkanDala,
    FieldInfoFrame,
    EditFieldModal,
    DownloadModal
  },
  data() {
    return {
      FitToThisPlot:[],
      fieldsCheckbox:[],
      HideAllFields:false,
      openOnFocus: false,
      isLoading:false,
      isRendered:false,
      isModalOpen:false,
      CompanyName: '',
      CompanyBin:'',
      Companyfield:'',
      nameComp: '',
      bin: '',
      namefld:'',
      fieldName:'',
      zoomSnap:1,
      keepFirst: false,
      selected:null,
      selectable: false,
      clearable: false,
      role: null,
      FieldToEdit:null,
      EditModal:false,
      users: [],
      fields: [],
      mode: 0,
      company_id: 0,
      field_id: null,
      companies: [],
      render: true,
      openOnFocus: true,
      clearable: true,
      rounded: false,
      multiple: false,
      fitBoundID: null,
      open: true,
      fullheight: false,
      overlay: false,
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
        },
        {
          field: 'first_name',
          label: 'Имя',
          centered: true,
        },
        {
          field: 'middle_name',
          label: 'Отчество',
          centered: true,
        },
        {
          field: 'last_name',
          label: 'Фамилия',
          centered: true,
        },
        {
          field: 'login',
          label: 'Логин',
          centered: true,
        },
        {
          field: 'role',
          label: 'Роль',
          centered: true,
        },
        {
          field: 'company_name',
          label: 'Компания',
          centered: true,
        },
        {
          field: 'email',
          label: 'Email',
          centered: true,
        },
        {
          field: 'created_at',
          label: 'Дата создания',
          centered: true,
        },
        {
          field: 'updated_at',
          label: 'Дата обновления',
          centered: true,
        },
        {
          field: 'is_active',
          label: 'Активирован',
          centered: true,
        },
      ],
    };
  },
  beforeMount() {
    this.checkRole();
    if (this.role === '2') {
      this.getCompanies();
    }
  },
  watch:{
    CompanyName(e){
      if(!this.CompanyName){
        this.fields = [];
        this.nameComp = ''
        this.bin = '';
        this.namefld = '';
        this.company_id = null;
      }
    },
    CompanyBin(){
      if(!this.CompanyBin){
        this.fields = [];
        this.nameComp = ''
        this.bin = '';
        this.namefld = '';
        this.company_id = null;
      }
    },
    HideAllFields(newValue){
      if(newValue){
        this.fieldsCheckbox = []
      }
    },
  },
  computed: {
    filteredCompaniesByBin() {
      return this.companies.filter((option) => option.bin
        .toString()
        .toLowerCase()
        .indexOf(this.bin.toLowerCase()) >= 0);
    },
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
    filteredFieldsByName() {
      return this.fields.filter((field) => field.name
        .toString()
        .toLowerCase()
        .indexOf(this.namefld.toLowerCase()) >= 0);
    },
    map_data(id) {
      return (
        {
          company_id: 9,
          mode: 0,
          field_id: id,
        }
      );
    },
  },
  methods: {
    ShowAllField(){
      this.fieldsCheckbox = this.fields.map(el => {
        return el.id;
      });
      this.HideAllFields = false
    },
    CloseModal(){
      this.EditModal = false;
      this.isModalOpen = false;
    },
    async SendUpdatedInfo(value){
      this.EditModal = false;
      this.isLoading = true;
      let key = {"company_field":value}
      await axios.put(`api/gist/company_fields/${this.company_id}/${this.FieldToEdit.id}`, key)
      .then(response => {
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка сохранения поля');
      });
      this.isLoading = false;
    },
    refreshMap(e){
      this.getCompanyFields(this.company_id);
    },
    checkRole() {
      this.role = localStorage.role;
    },
    async getCompanies() {
      this.isLoading = true;
      await axios.get('/api/laborant/companies')
      .then((response) => {
        this.companies = response.data;
        this.companies.filter((option) => {
         if( option.bin == null ){
          option.bin = 0;
         }
        })
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка получения списка компаний');
      });
      this.isLoading = false;
    },
    renderMap() {
      this.render = true;
    },
    async getCompanyFields(id) {
      this.fields = [];
      this.isLoading = true;
      await axios.get(`/api/gist/company_fields/${id}/`)
        .then((response) => {
          this.fields = response.data;
          this.fields = this.fields.reverse();

          this.FitToThisPlot = this.fields.filter(el =>{
            if(el.status == 1){
              return el
            }
          });

          this.renderMap(id);
        }).catch(() => {
          this.$buefy.snackbar.open('Ошибка получения списка полей компании');
        });
      this.isLoading = false;
    },
    isActionDone(){
      this.getCompanyFields(this.company_id)
    },
    setCompanyId(id) {
      this.company_id = id;
    },
    EditFieldData(field){
      this.FieldToEdit = field
      this.EditModal = true;
    },
    async SendUpdatedInfo(){
      this.isLoading = true;
      this.EditModal = false;

      let data = {
        "name":this.FieldToEdit.name,
        "additional": this.FieldToEdit.additional,
        "description": this.FieldToEdit.description,
        "cadastral_number":this.FieldToEdit.cadastral_number,
        "properties":this.FieldToEdit.properties,
        "status":this.FieldToEdit.status,
        "type":this.FieldToEdit.type,
        "geometry":this.FieldToEdit.geometry,
        "region":this.FieldToEdit.region
      }

      let key = {"company_field":data}
      await axios.put(`api/gist/company_fields/${this.company_id}/${this.FieldToEdit.id}`, key)
      .then(response =>{})
      .catch((e)=>{
        this.$buefy.toast.open({
          message: 'Ошибка, не удалось изменить данные поля!',
          type: 'is-danger',
        });
      })
      this.isLoading = false;
    },
    UpdateSidebar(){
      this.fields = [];
      this.getFields();
    }
  },
};
</script>

<style scoped>
.column{
  padding: 0;
}
.main-card{
  padding:0 15px;
}
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.mg-left-5{
  margin-left: 5px;
}

.fields-filter{
  text-align: left;
}

.list-box{
  height: calc(100vh - 550px);
  overflow: scroll;
}
.list-box-inner{
  height:auto;
  overflow: scroll;
}
.map{
  margin-top: 12px;
  height: 100%;
}
.modal{
  z-index: 100100000342;
}

.modal-content-box{
  width: 280px !important;
}

</style>
