<template>
  <!-- модалка создания компании для дальнейшей работы -->
    <form>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title has-text-centered">Добавление новой Компании</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Форма собственности">
            <b-select v-model="company.ownership_type" placeholder="Выберите ..." required>
              <option value="ИП">ИП</option>
              <option value="ТОО">ТОО</option>
              <option value="КХ">КХ</option>
              <option value="ПК">ПК</option>
            </b-select>
          </b-field>

          <b-field label="Наименование">
            <b-input v-model="company.name"></b-input>
          </b-field>
          <template v-if="company.ownership_type !== 'ПК'">
            <b-field label="БИН / ИИН">
              <b-input minlength="12" maxlength="12" v-model="company.bin"></b-input>
            </b-field>
          </template>
          <template v-else>
            <div class="pk-input">
              <b-field label="Выбрать ТОО">
                <b-autocomplete
                  v-model="nameComp"
                  ref="autocomplete"
                  field="name"
                  :data="filteredCompaniesByName"
                  :keep-first="keepFirst"
                  :open-on-focus="openOnFocus"
                  placeholder="Выбрать ТОО..."
                  @select="option => CompanyName = option"
                  :selectable-header="selectable"
                  :clearable="clearable"
                >
                <template #empty>Нет результатов по {{nameComp}}</template>
                </b-autocomplete>
              </b-field>
            </div>
          </template>
          <template v-if="company.ownership_type !== 'ПК'">
            <b-field label="Юридический адрес">
              <b-input maxlength="200" type="textarea"
                v-model="company.legal_address">
              </b-input>
            </b-field>

            <b-field label="Фактический адрес">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="company.actual_address">
              </b-input>
            </b-field>
          </template>

        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Отмена"
            @click="$emit('close')" />
            <b-button
            label="Сохранить"
            type="is-primary"
            @click="createCompany();" />
        </footer>
    </div>
</form>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      companies:[],
      nameComp:'',
      openOnFocus:true,
      CompanyName:null,
      keepFirst: false,
      selectable: true,
      clearable: true,
      company: {
        ownership_type: null,
        name: null,
        bin: null,
        legal_address: null,
        actual_address: null,
      },
    };
  },
  watch:{
    'company.ownership_type'(){
      if(this.company.ownership_type == 'ПК'){
        this.company.bin = null;
        this.company.legal_address = null;
        this.company.actual_address = null;
        this.getCompanies();
      }else{
        this.CompanyName = null;
      }
    }
  },
  computed:{
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
  },
  methods: {
    async createCompany() {
      if(!this.company.ownership_type || !this.company.name){
        this.$buefy.snackbar.open('Необходимые данные не заполнены!');
        return;
      }

      if(this.company.ownership_type == 'ПК' && (!this.CompanyName || !this.company.name)){
        this.$buefy.snackbar.open('Заполните все поля ПК!');
        return;
      }

      if(this.company.ownership_type != 'ПК' && (!this.company.bin || !this.company.actual_address || !this.company.legal_address)){
        this.$buefy.snackbar.open('Заполните все поля для создания компании!');
        return;
      }

      if(this.company.ownership_type != 'ПК'){
        const data = {
          company: this.company,
        };

        await axios.post('/api/admin/companies/', data)
        .then((response) => {
          this.$buefy.snackbar.open(response.data.status);
        }).catch((e) => {
          this.$buefy.snackbar.open('Не удалось создать Компанию');
        });
        this.$emit('isCompanyCreated');
        this.$emit('close');
      }else{
        if(!this.CompanyName.id){
          this.$buefy.snackbar.open('Выберите компанию из списка');
          return
        }
        const data = {
          company: {
            name:this.company.name,
            parent_id:this.CompanyName.id,
            ownership_type:this.company.ownership_type
          },
        };

        await axios.post('/api/admin/companies/', data)
        .then((response) => {
          this.$buefy.snackbar.open(response.data.status);
        }).catch((e) => {
          this.$buefy.snackbar.open('Не удалось создать Компанию');
        });
        this.$emit('isCompanyCreated');
        this.$emit('close');
      }
    },
    async getCompanies() {
      await axios.get('/api/admin/companies/')
      .then((response) => {
        this.companies = response.data;
        this.companies = this.companies.filter(el => {
          return el.ownership_type == "ТОО"
        })
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка получения списка компаний!');
      });
    },
  },
};
</script>

<style scoped>
.pk-input{
  height: 250px;
}
</style>
