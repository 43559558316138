<template>
  <!-- модалка редактирования пользователя -->
    <form action="">
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">Редактирование пользователя</p>
        </header>

        <section class="modal-card-body">
          <b-field label="ID">
            <b-input v-model="user.id" disabled></b-input>
          </b-field>

          <b-field label="Статус">
            <b-select v-model="user.is_blocked">
              <option value="0">Активен</option>
              <option value="1">Блокировка</option>
            </b-select>
          </b-field>

          <b-field label="Имя">
              <b-input v-model="user.first_name"></b-input>
          </b-field>

          <b-field label="Отчество">
            <b-input v-model="user.middle_name"></b-input>
          </b-field>

          <b-field label="Фамилия">
            <b-input v-model="user.last_name"></b-input>
          </b-field>

          <b-field label="Логин">
            <b-input v-model="user.login"></b-input>
          </b-field>

          <b-field label="Пароль">
            <b-input v-model="user.password" type="password"></b-input>
          </b-field>
          <b-field label="Роль">
            <b-select v-model="user.role">
              <option value="3">Клиент</option>
              <option value="2">Гис-специалист</option>
              <option value="4">Лаборант</option>
              <option value="1">Администратор</option>
            </b-select>
          </b-field>

          <b-field label="Компания">
            <b-select v-model="user.company_id">
              <option v-for="company in companies" :key="company.id" :value="company.id">
                {{company.ownership_type}} "{{company.name}}"
              </option>
            </b-select>
          </b-field>

          <b-field label="Email">
            <b-input v-model="user.email"></b-input>
          </b-field>

        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Отмена"
            @click="$emit('close')" />
          <b-button
            label="Сохранить"
            type="is-primary"
            @click="editCompany();"/>
        </footer>
    </div>
</form>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      user: {
        id: this.userData.id ? this.userData.id : null,
        first_name: this.userData.first_name,
        middle_name: this.userData.middle_name,
        last_name: this.userData.last_name,
        login: this.userData.login,
        role: this.userData.roles.length > 0 ? this.userData.roles[0].id : null,
        company_id: this.userData.companies.length > 0 ? this.userData.companies[0].id : null,
        email: this.userData.email,
        type: 0,
        is_blocked: this.userData.is_blocked
      },
      companies: [],
    };
  },
  props: {
    userData: Object,
  },
  beforeMount() {
    this.getCompanies();
  },
  methods: {
    async getCompanies() {
      await axios.get('/api/admin/companies/')
        .then((response) => {
          this.companies = response.data;
        }).catch(() => {
          this.$buefy.snackbar.open('Ошибка получения списка компаний');
        });
    },
    async editCompany() {
      const data = {
        user: this.user,
      };
      await axios.put(`/api/admin/users/${this.user.id}`, data)
      .then((response) => {
        this.$buefy.snackbar.open(response.data.status);
      }).catch(() => {
        this.$buefy.snackbar.open('Не удалось отредактировать пользователя');
      });
      this.$emit('isUserEdited');
      this.$emit('close');
    },
  },
};
</script>
