<template>
  <!-- общий компонент для всех ролей и компонентов, переиспользуемая пагинация -->
  <div v-if="total > 1">
    <div class="is-flex py-6 is-justify-content-center is-flex-wrap-wrap">
      <div v-for="(item,id) in total" :key="id">
        <button class="button mr-1 mb-1" :class="{highlight:id == selected}"  @click.prevent="Pagination(id); selected = id">{{item}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 props:["total", "dateFrom", "dateTo", "crntPage"],
  data(){
    return{
      selected:0
    }
  },
  watch:{
    crntPage(){
      this.selected = this.crntPage;
    }
  },
  methods:{
    Pagination(item){
      let currentPage = item;
      if(item == 1){
          item = item * 10;
          let data = {
            pass:10,
            count:item,
            current:currentPage
          }
          if(this.dateFrom && this.dateTo){
            data.dateFrom = this.dateFrom;
            data.dateTo = this.dateTo;
          }
          this.$emit('CurrentPage', data)
      }else{
        let pass = item * 10;
        let count = 10;
        let data = {
          pass:pass,
          count:count,
          current:currentPage
        }
        if(this.dateFrom && this.dateTo){
          data.dateFrom = this.dateFrom;
          data.dateTo = this.dateTo;
        }
        this.$emit('CurrentPage', data)
      }

    },
  }
}
</script>

<style scoped>
.highlight {
  background: #61a937;
}
</style>


// if(this.dateFrom && this.dateTo){
//         if( pass == 0 ){
//           this.getOrderNotReady(pass, 10, this.dateFrom, this.dateTo)
//           this.current = pass;
//         } else {
//           this.getOrderNotReady(pass * 10, 10, this.dateFrom, this.dateTo)
//           this.current = pass * 10;
//         }
//       }
