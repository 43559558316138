<template>
  <!-- модалка создания пользователя -->
    <form action="">
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title has-text-centered">Добавление нового пользователя</p>
        </header>
        <section class="modal-card-body">

            <b-field label="Имя">
                <b-input v-model="user.first_name"></b-input>
            </b-field>

            <b-field label="Отчество">
                <b-input v-model="user.middle_name"></b-input>
            </b-field>

            <b-field label="Фамилия">
                <b-input v-model="user.last_name"></b-input>
            </b-field>

            <b-field label="Логин">
               <b-input v-model="user.login"></b-input>
            </b-field>

            <b-field label="Пароль">
               <b-input v-model="user.password" type="password"></b-input>
            </b-field>

            <b-field label="Роль">
                <b-select v-model="user.role" placeholder="Выберите ...">
                    <option value="3">Клиент</option>
                    <option value="2">Гис-специалист</option>
                    <option value="4">Лаборант</option>
                    <option value="1">Администратор</option>
                </b-select>
            </b-field>

            <b-field label="Поиск по Наименованию">
              <b-autocomplete
                v-model="nameComp"
                ref="autocomplete"
                field="name"
                :data="filteredCompaniesByName"
                :keep-first="keepFirst"
                :open-on-focus="openOnFocus"
                placeholder="Введите название..."
                @select="option => CompanyName = option"
                :selectable-header="selectable"
                :clearable="clearable"
              >
              <template #empty>Нет результатов по {{nameComp}}</template>
              </b-autocomplete>
            </b-field>
            <b-field label="Email">
               <b-input v-model="user.email" type="email"></b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Отмена"
            @click="$emit('close')" />
          <b-button
            label="Сохранить"
            type="is-primary"
            @click="createUser();" />
        </footer>
    </div>
</form>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: {
        id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        login: null,
        role: null,
        company_id: null,
        email: null,
        type: 0,
      },
      CompanyName:'',
      openOnFocus: true,
      keepFirst: false,
      selectable: true,
      clearable: true,
      nameComp:'',
      companies: [],
    };
  },
  beforeMount() {
    this.getCompanies();
  },
  computed:{
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
  },
  methods: {
    async getCompanies() {
      await axios.get('/api/admin/companies/')
      .then((response) => {
        this.companies = response.data;
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка получения списка компаний!');
      });
    },
    async createUser() {
      this.user.company_id = this.CompanyName.id;
      if(!this.user.first_name || !this.user.middle_name || !this.user.last_name || !this.user.login || !this.user.role || !this.user.email){
        this.$buefy.snackbar.open('Необходимо заполнить все поля для ввода!');
        return
      }

      const data = {
        user: this.user,
      };

      await axios.post('/api/admin/users/', data)
      .then((response) => {
        this.$buefy.snackbar.open(response.data.status);
      }).catch(() => {
        this.$buefy.snackbar.open('Не удалось создать Пользователя');
      });
      this.$emit('isUserCreated');
      this.$emit('close');
    },
  },
};
</script>
