<template>
  <!-- загрузка результатов анализов -->
  <div>
    <div class="modal-card" style="width: 100% ; margin: 100px">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">
          Загрузка результатов анализа по заказу №{{orderId}}
        </p>
      </header>
    <section class="modal-card-body">
      <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
      <p v-if="!file" class="modal-card-title has-text-centered">Выберите протокол</p>
      <form>
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body has-text-centered">
            <b-field v-if="!file">
              <b-upload v-model="file" drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Перетащите файл или нажмите для выбора</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <div v-if="file" class="tags">
              <span class="tag is-primary">
                {{file.name}}
                <button class="delete is-small" type="button"
                  @click="deleteDropFile()"/>
              </span>
            </div>
          </section>
        </div>
      </form>
      <div class="content has-text-centered">
        <b-button v-if="file"
          outlined
          type="is-primary"
          :disabled="false"
          @click.prevent="parseFile();">
          Загрузить
        </b-button>
      </div>
    </section>
  </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      file: null,
      dropFiles: [],
      results: null,
      activeStep: 0,
      isLoading:false,
    };
  },
  props: {
    orderId: Number,
  },
  watch:{
  },
  methods: {
    deleteDropFile() {
      this.file = null;
    },
    async parseFile() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', this.file);
      await axios.post(`api/laborant/analyzes/upload/${this.orderId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.results = response.data;
        this.uploadResults()
      }).catch((e) => {
        let error = e.response.data.message
        this.isLoading = false;

        this.$buefy.toast.open({
          message: error,
          type: 'is-danger',
        });
      });

    },
    async uploadResults() {
      if(!this.orderId){
        return
      }

      this.results.order_id = this.orderId;

      await axios.post('/api/laborant/analyzes/set', this.results)
        .then((response) => {
          this.$buefy.snackbar.open(response.data.message);
        }).catch(() => {
          this.$buefy.snackbar.open('Не удалось загрузить результаты');
        });
      this.isLoading = false;
      this.$emit('isResultIsUploaded');
      this.$emit('close');

    },
  },
};
</script>
<style scoped>
  .tags{
    justify-content: center;
  }
  .tag{
    font-size: 16px;
  }
  .modal-card-title{
    font-size: 20px;
    color: black;
  }
</style>
