<template>
  <!-- тут начинается все что связанно с картами -->
  <MapDarkanDala  :fitBoundID="fitBoundID" :field_id="field_id"
    :element_id="element_id" :company_id="company_id" :mode="mode" />
</template>

<script>
import MapDarkanDala from '../../components/map/MapDarkanDala.vue';

export default {
  name: 'MapTest',
  components: {
    MapDarkanDala,
  },
  data() {
    return {
      mode: 0,
      company_id: 228,
      fitBoundID: [2, 3, 5, 6],
      field_id: 1,
      element_id: 1597,
    };
  },
};
</script>
