<template>
  <!-- таблица заказов по которым не были произведены работы в процессе -->
  <div class="column is-full mr-3" v-if="isRender">
    <b-loading :is-full-page="true" v-model="isLoading" ></b-loading>

    <p class="table-title" v-if="searchingActiveOrder.length > 3">Поиск по результату "{{searchingActiveOrder}}"</p>
    <p class="table-title" v-else>Активные заказы всех компаний c {{dateFrom}} - {{dateTo}}</p>
     <div class="is-flex">
        <div>
          <b-field label="Выберите дату">
            <b-datepicker
              placeholder="Нажмите для выбора"
              v-model="dates"
              range>
            </b-datepicker>
          </b-field>
        </div>
        <div v-if="dates.length >= 2" class="mx-3 is-flex is-align-self-flex-end">
          <button class="button is-primary mr-1" @click="SearchByDate">Поиск</button>
          <button class="button is-primary is-outlined" @click.prevent="SetDate(); dates = []; searchingActiveOrder = ''; selected = 0">Cброс</button>
        </div>
      </div>

      <div class="is-flex is-justify-content-end my-1 mx-1">
        <b-input placeholder="Поиск по № заказа"
          v-model="searchingActiveOrder"
          type="search"
          icon="magnify"
          icon-right-clickable
          @icon-right-click="searchingActiveOrder = ''">
        </b-input>
      </div>

    <div class="empty-block" v-if="NotReadyOrder.length == 0">
      <p class="title">Нет заявок</p>
    </div>

    <div v-else>
      <b-table :data="NotReadyOrder">
        <b-table-column label="№" field="count" centered sortable v-slot="props">
          {{ props.row.count }}
        </b-table-column>
        <b-table-column label="Название компании" field="is_done" centered sortable v-slot="props">
          <p v-if="props.row.company"> {{ props.row.company.name}}</p>
          <p v-else> - </p>
        </b-table-column>
        <b-table-column label="Название поля" field="is_done" centered sortable v-slot="props">
          <p v-for="field in props.row.fields" :key="field.id"> {{ field.name }}</p>
        </b-table-column>
          <b-table-column label="Площадь" field="is_done" centered sortable v-slot="props">
          <p v-for="field in props.row.fields" :key="field.id"> {{ field.area_hec}} га</p>
        </b-table-column>
          <b-table-column label="Кол-во секторов" centered sortable v-slot="props">
            <p v-for="field in props.row.fields" :key="field.id">{{field.sectors_count }}</p>
          </b-table-column>
        <template v-for="column in columns">
          <b-table-column sortable :key="column.id" v-bind="column">
            <template
                v-if="column.searchable"
                #searchable="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Поиск..."
                  icon="magnify"
                  size="is-small" />
            </template>
            <template v-slot="props">
                {{ props.row[column.field] }}
            </template>
          </b-table-column>
        </template>
        <b-table-column label="Готово" field="is_done" centered sortable v-slot="props">
          <p :class="props.row.is_done ? 'ready' : ''"> {{ props.row.is_done ? "да" : "нет" }}</p>
        </b-table-column>
        <b-table-column label="Пользователь" field="is_done" centered sortable v-slot="props">
          {{props.row.user.first_name }} {{props.row.user.last_name}} {{props.row.user.middle_name}}
        </b-table-column>
        <b-table-column custom-key="actions" label="Скачать" cell-class="is-actions-cell"
        v-slot="props">
        <div v-if="props.row.is_done === 0" class="buttons is-right">
          <button class="button is-small is-primary" type="button"
            @click.prevent="DownloadFile(props.row.id)">
              Скачать файл <b-icon class="ml-1" icon="flask" size="is-small"/>
          </button>
        </div>
      </b-table-column>
        <b-table-column custom-key="actions" label="Загрузка" cell-class="is-actions-cell"
          v-slot="props" width="150">
          <div v-if="props.row.is_done === 0" class="buttons is-right">
            <button class="button is-small is-info" type="button"
              @click.prevent="uploadResultsModal(props.row.id)">
                Загрузить результаты <b-icon icon="flask" size="is-small"/>
            </button>
          </div>
        </b-table-column>
      </b-table>
      <Pagination :total="totalActvOrdr" :crntPage="selected" :dateFrom="dateFrom" :dateTo="dateTo" @CurrentPage="NotReadyOrdersPagination"/>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import Pagination from '@/components/pagination/GeneralPagination.vue'
import UploadResultsModal from '@/components/lab/UploadResultsModal.vue';
export default {
  components:{
    Pagination
  },
  data(){
    return{
      dates:[],
      searchingActiveOrder:'',
      isRender:false,
      isLoading:false,
      dateFrom:null,
      dateTo:null,
      totalActvOrdr:null,
      NotReadyOrder:[],
      selected:0,
      current:0,
      columns: [
        {
          field: 'id',
          label: 'Номер заказа',
          numeric: true,
          centered:true,
        },
        {
          field: 'created_at',
          label: 'Дата создания',
          centered: true,
        },
      ]
    }
  },
  watch:{
    searchingActiveOrder(){
      if(this.searchingActiveOrder.length == 0 && this.NotReadyOrder){
        this.getOrderNotReady(0, 10);
      }else{
        this.SearchActiveOrders(this.searchingActiveOrder)
      }
    }
  },
  beforeMount() {
    this.dateTo = new Date().toISOString().split('T')[0];
    this.dateFrom = new Date().setMonth(new Date().getMonth() -3);
    this.dateFrom = new Date(this.dateFrom);
    this.dateFrom = this.dateFrom.toISOString().split('T')[0];
    this.getOrderNotReady(0,10, this.dateFrom, this.dateTo);
  },
  methods:{
    SetDate(){
      this.dateTo = new Date().toISOString().split('T')[0];
      this.dateFrom = new Date().setMonth(new Date().getMonth() -3);
      this.dateFrom = new Date(this.dateFrom);
      this.dateFrom = this.dateFrom.toISOString().split('T')[0];
      this.getOrderNotReady(0,10, this.dateFrom, this.dateTo);
    },
    SearchByDate(){
      this.searchingActiveOrder = '';
      this.parseDate()
      this.selected = 0;
      this.getOrderNotReady(0, 10, this.dateFrom, this.dateTo)
    },
    parseDate(){
      let [startDate, endDate] = this.dates;
      if(startDate && endDate){
        this.dateFrom = this.formatDate(startDate)
        this.dateTo = this.formatDate(endDate)
      }
    },
    formatDate(date) {
      let day = date.getDate();
      let monthIndex = date.getMonth();
      let year = date.getFullYear();
      let formattedDate = (
          (year)
          +'-'+
          (monthIndex < 10 ? '0' + (monthIndex+1) : monthIndex +1)
          +'-'+
          (day < 10 ? '0' + day:day)
        )
      return formattedDate
    },
    async getOrderNotReady(pass, items){
      this.isLoading = true;
      await axios.get(`api/laborant/analyzes/orders/date?from=${this.dateFrom}&to=${this.dateTo}&offset=${pass}&count=${items}`)
      .then(response => {
          if(pass != 0 && response.data.items.length == 0){
            pass = pass - 10;
            this.getOrderNotReady(pass, 10);
            this.selected = Math.ceil((pass / 10));
            return
          }

        this.totalActvOrdr = response.data.count;
        this.NotReadyOrder = response.data.items;
        this.totalActvOrdr = Math.ceil(this.totalActvOrdr / 10);
        let count = pass;
        for (let i = 0; i < response.data.items.length; i++) {
          this.NotReadyOrder[i].count = (count++) + 1;
        }
      }).catch((e) =>{
        this.$buefy.toast.open({
          message: 'Ошибка получения списка заказов',
          type: 'is-danger',
        });
      });
      this.isLoading = false;
      this.isRender = true;
    },
    async SearchActiveOrders(value){
      if(value.length < 4 || value.length > 10){
      //  this.$buefy.snackbar.open('Вводимое значение должно быть не менее 4 и не болле 10 символов!');
       return
      }

      await axios.get(`api/laborant/analyzes/orders/date?offset=0&count=1000&name=${value}`)
      .then(response =>{
        this.NotReadyOrder = response.data.items;
        this.totalActvOrdr = this.NotReadyOrder.length;
        this.totalActvOrdr = Math.ceil(this.totalActvOrdr / 10);
        let count = 0;
        for (let i = 0; i < this.NotReadyOrder.length; i++) {
          this.NotReadyOrder[i].count = (count++) + 1;
        }
      })
      .catch((error)=>{
      })
      this.isPageLoaded = true;
      this.isLoading = false;
      this.SearchMode = true;
    },
    refreshNotReadyOrders(selected){
      this.getOrderNotReady(selected, 10);
      this.$buefy.toast.open({
        message: 'Данные обновлены',
        type: 'is-info',
      });
    },
    NotReadyOrdersPagination(data){
      this.selected = Math.ceil(data.pass / 10)
      this.current = data.pass;
      this.getOrderNotReady(data.pass, data.count);
    },
    uploadResultsModal(id) {
      this.$buefy.modal.open({
        parent: this,
        component: UploadResultsModal,
        props: {
          orderId: id,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isResultIsUploaded': () => {
            this.refreshNotReadyOrders(this.current);
          }
        }
      });
    },
    DownloadFile(order_id){
      const url = `${axios.defaults.baseURL}/api/laborant/analyzes/download/${order_id}`;
      window.location.href = url;
    }
  }
}
</script>

<style>
.table-title{
  color: white;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 0;
  margin: 15px 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  background-color: #61a937;
}
.empty-block{
  min-width: 1500px;
}
</style>
