<template>
  <section v-if="role === '3'">
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div class="columns">
      <div class="column is-2 py-2 side-bar-style">
        <div class="card">
          <div class="card-content" v-if="isRendered">
            <p v-if="fields.length === 0" class="py-2 title">Пусто</p>
            <section class="list-of-field ">
              <b-field label="Поиск по названию поля" class="search mt-3">
                <b-autocomplete
                  :rounded="rounded"
                  v-model="name"
                  :data="filteredFieldsByName"
                  field="name"
                  :open-on-focus="openOnFocus"
                  placeholder="поиск по компаниям"
                  icon="magnify"
                  :clearable="clearable"
                  @select="option => selectedName = option">
                  <template v-slot="props">
                    <p>{{props.option.name}}</p>
                  </template>
                  <template #empty>No results found</template>
                </b-autocomplete>
              </b-field>

              <div class="my-3">
                <button v-if="HideAllFields" class="button is-primary is-outlined" @click="ShowAllField">Показать все поля {{fields.length}}</button>
                <button v-if="!HideAllFields" class="button is-primary is-outlined" @click="HideAllFields = true">Скрыть все поля {{fields.length}}</button>
                <div class="is-flex is-justify-content-space-between">
                  <button v-if="fieldsCheckbox.length > 1" class="my-2 button is-primary is-outlined is-small" @click="isModalOpen = true;">Скачать координаты {{fieldsCheckbox.length}} полей</button>
                  <button v-if="fieldsCheckbox.length > 1" @click="fieldsCheckbox = []" class="my-2 button is-danger is-outlined is-small">Сброс</button>
                </div>
              </div>

              <div v-for="field in filteredFieldsByName" :key="field.id" class="is-flex is-flex-direction-column">
                <div>
                  <b-field class="fields-filter">
                    <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
                      <b-checkbox
                        v-model="fieldsCheckbox"
                        :native-value="field.id">
                       {{field.name}}
                      </b-checkbox>

                      <button @click="EditFieldData(field)" class="button is-small is-primary is-outlined">Изменить</button>
                    </div>
                  </b-field>
                </div>
                <FieldInfoFrame :field="field"/>
                <hr>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="column pr-5 py-2">
        <template v-if="isModalOpen">
          <DownloadModal :fieldsCheckbox="fieldsCheckbox" @CloseModal="CloseModal"/>
        </template>
        <EditFieldModal v-if="EditModal" @CloseModal="CloseModal" @SendUpdatedInfo="SendUpdatedInfo" :FieldToEdit="FieldToEdit"/>
        <section class="map" v-if="render">
          <MapDarkanDala   @ReloadList="isActionDone" @isFieldUpdated="UpdateSidebar" @refresh-map="refreshMap" :zoomSnap="zoomSnap" :fitBoundID="fieldsCheckbox" :HideFields="HideAllFields" :mode="mode" :company_id="company_id"/>
          <!-- :FitToThisPlot="FitToThisPlot" -->
        </section>
      </div>
    </div>
  </section>
  <section v-else>
    <p class="title">Данный функционал доступен только Клиенту</p>
  </section>
</template>
<script>
import axios from 'axios';
import * as turf from '@turf/turf'
import MapDarkanDala from '@/components/map/MapDarkanDala.vue';
import EditFieldModal from '@/components/modal/EditFieldModal.vue';
import FieldInfoFrame from '@/components/modal/FieldInfoFrame.vue';
import DownloadModal from '@/components/modal/DownloadAllFields.vue';

export default {
  name: 'Map',
  components: {
    MapDarkanDala,
    EditFieldModal,
    FieldInfoFrame,
    DownloadModal
  },
  watch:{
    HideAllFields(newValue){
      if(newValue){
        this.fieldsCheckbox = []
      }
    },
  },
  data() {
    return {
      FitToThisPlot:[],
      isModalOpen:false,
      HideAllFields:false,
      role: null,
      bundleForDownload:{
        setting:null,
        format:null
      },
      fieldsCheckbox:[],
      isLoading:false,
      isRendered:false,
      EditModal:false,
      mode: 0,
      zoomSnap:1,
      company_id: localStorage.company_id,
      users: [],
      fields: [],
      name: '',
      FieldToEdit:null,
      render: true,
      selectedName: null,
      openOnFocus: true,
      clearable: true,
      rounded: false,
      multiple: true,
      selectedField: null,
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
        },
        {
          field: 'first_name',
          label: 'Имя',
          centered: true,
        },
        {
          field: 'middle_name',
          label: 'Отчество',
          centered: true,
        },
        {
          field: 'last_name',
          label: 'Фамилия',
          centered: true,
        },
        {
          field: 'login',
          label: 'Логин',
          centered: true,
        },
        {
          field: 'role',
          label: 'Роль',
          centered: true,
        },
        {
          field: 'company_name',
          label: 'Компания',
          centered: true,
        },
        {
          field: 'email',
          label: 'Email',
          centered: true,
        },
        {
          field: 'created_at',
          label: 'Дата создания',
          centered: true,
        },
        {
          field: 'updated_at',
          label: 'Дата обновления',
          centered: true,
        },
        {
          field: 'is_active',
          label: 'Активирован',
          centered: true,
        },
      ],
    };
  },
  beforeMount() {
    this.checkRole();
    if (this.role === '3') {
      this.getFields();
    }
  },
  computed: {
    filteredFieldsByName() {
      // фильтр полей по имени
      return this.fields.filter((field) => field.name
        .toString()
        .toLowerCase()
        .indexOf(this.name.toLowerCase()) >= 0);
    },
    map_data(id) {
      return (
        {
          company_id: 9,
          mode: 1,
          field_id: id,
        }
      );
    },
  },
  methods: {
    ShowAllField(){
      //показать/скрыть все поля(чекбокс меню слева)
      this.fieldsCheckbox = this.fields.map(el => {
        return el.id;
      });
      this.HideAllFields = false
    },
    refreshMap(e){
      this.getFields();
    },
    renderMap() {
      this.render = true;
    },
    checkRole() {
      this.role = localStorage.role;
    },
    EditFieldData(field){
      this.FieldToEdit = field;
      this.EditModal = true;
    },
    CloseModal(){
      this.EditModal = false;
      this.isModalOpen = false;
    },
    async SendUpdatedInfo(value){
      //сохранить изменение которые вносятся в параметры поля
      this.EditModal = false;
      this.isLoading = true;
      let key = {"company_field":value}
      await axios.put(`api/gist/company_fields/${this.company_id}/${this.FieldToEdit.id}`, key)
      .then(response => {
        this.$buefy.snackbar.open('Информация о поле обновлена!');
      }).catch(() => {
        this.$buefy.snackbar.open('Ошибка изменения информации о поле!');
      });
      this.isLoading = false;
    },
    isActionDone(){
      this.getFields()
    },
    async getFields() {
      //запрос полей компании
      this.isLoading = true;
      await axios.get('/api/client/company_fields/')
      .then((response) => {
        this.fields = response.data;
        for (let i = 0; i < this.fields.length; i++) {
          let areameters = turf.area(this.fields[i]);
          this.fields[i].properties.area = (areameters/10000).toFixed(2);
        }
        this.fields = this.fields.reverse();
        this.FitToThisPlot = this.fields
      }).catch((e) => {
        this.$buefy.toast.open({
          message: 'Ошибка получения списка полей компании',
          type: 'is-danger',
        });
      });
      this.isRendered = true;
      this.isLoading = false;
    },
    UpdateSidebar(){
      //обнуление переменных и новый запрос
      this.fields = [];
      this.getFields();
    }
  },
};
</script>

<style scoped>
.fields-filter{
  text-align: left;
}
.card{
  width: 100%;
}
.card-content{
  width: 100%;
  padding: 5px;
}
.map{
  margin-top: 10px;
  /* width: 100vw; */
  height: 100%;
}
.list-of-field{
  overflow: scroll;
  /* height: 50px; */
  height: calc(100vh - 85px);

}
.modal{
  z-index: 100001;
}
.column{
  padding: 0;
}
.side-bar-style{
  padding:0 15px;
  /* width: 15% !important; */
}

</style>
