import Vue from 'vue';
import Buefy from 'buefy';
import axios from 'axios';
import App from './App.vue';
import router from './router';

/* Map */
import 'leaflet/dist/leaflet.css';
import 'leaflet.wms';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
/* Styles */
import 'buefy/dist/buefy.css';
import './scss/main.scss';
import './bing.js'

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common.token = localStorage.token;
// axios.defaults.headers.common['content-type'] = 'application/json';
axios.defaults.baseURL = window.location.hostname === 'geo.darkandala.kz' ? 'https://geo.darkandala.kz' : 'https://geo.darkandala.kz'
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) window.location.href = '/';
    return Promise.reject(error);
  },
);

Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
