<template>
  <section v-if="role === '3'">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    <div>
      <router-link tag="button" class="button is-primary is-small is-flex is-justify-content-flex-end mx-3 my-3" to="/client/orders-history">История заказов</router-link>

      <div>
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">Запрос на анализ почвы</p>
        </header>

        <div class="is-flex is-justify-content-flex-end my-4 mx-4">
          <button  class="button is-primary" @click="createOrder()">Подтвердить заказ</button>
          <div v-if="isElementSelected"></div>
        </div>

        <b-table :data="fields">
          <b-table-column label="№" field="count" centered sortable v-slot="props">
            {{ props.row.count }}
          </b-table-column>

          <b-table-column label="Название" field="name" centered sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column label="Общая площадь" centered sortable v-slot="props">
            {{ props.row.area_hec || '-' }} га
          </b-table-column>

          <b-table-column label="Посевная площадь" centered sortable v-slot="props">
            {{ props.row.sown_area_hec || '-' }} га
          </b-table-column>

          <b-table-column label="Действие" centered v-slot="props">
            <b-button
              outlined
              type="is-primary"
              class="mr-2"
              @click.prevent="OpenModalOrder(props.row);isModalOpen = true"
            >
              Выбрать
            </b-button>

            <b-button
              v-if="props.row.elements.length != 0 "
              outlined
              type="is-danger"
              @click.prevent="DeleteItem(props.row);"
            >
              Удалить
            </b-button>
          </b-table-column>

          <b-table-column label="Элементы" centered v-slot="props">
            <template class="is-size-6" v-if="props.row.elements.length != 0 ">
              <p v-for="item in props.row.elements" :key="item.id">
                {{item.name}}
              </p>
            </template>
            <p v-else>Выбрать элемент...</p>
          </b-table-column>
        </b-table>

        <div v-if="isModalOpen" class="modal is-active">
          <div @click="isModalOpen = false" class="modal-background"></div>
          <div class="modal-content modal-view ">
            <OrderModal :selectedRow="selectedRow" @close-modal="CloseModal" @package-element="PostToAnalyse"/>
          </div>
          <button
            class="modal-close is-large"
            aria-label="close"
            @click="isModalOpen = false"
          >
          </button>
        </div>

      </div>
    </div>
  </section>
  <section v-else>
    <p class="title">Данный функционал доступен только Клиенту</p>
  </section>
</template>
<script>

import axios from 'axios';
import OrderModal from '@/components/client/Order-create-modal.vue'
import * as turf from '@turf/turf';

export default {
  components:{
    OrderModal
  },
  data() {
    return {
      role: null,
      isModalOpen:false,
      selectedRow:null,
      fields: [],
      isElementSelected:false,
      isLoading:false,
      Elements:[],
    };
  },
  beforeMount() {
    this.checkRole();
    this.getCompanyFields();
  },
  methods: {
    checkRole() {
      this.role = localStorage.role;
    },
    CloseModal(){
      this.isModalOpen = false
    },
    OpenModalOrder(e){
      this.selectedRow = e;
    },
    DeleteItem(list){
      this.isElementSelected = false;
      setTimeout(() => this.isElementSelected = true, 10);

      this.fields.forEach(elem => {
        if( elem.id == list.id){
          elem.elements = [];
        }
      });
    },
    getCompanyFields() {
      axios.get('/api/client/company_fields/')
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            //сортируем список полей которые подходят для анализов
            if(response.data[i].status == 1){
              this.fields.push(response.data[i])
            }
          }

          //нумерация списка на фронте
          for (let k = 0; k < this.fields.length; k++) {
            let count = k+1;
            this.fields[k].count = count;
            this.fields[k].elements = [];
          }
        }).catch((e) => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка полей',
            type: 'is-danger',
          });
        });
    },
    PostToAnalyse(value){
      this.Elements = value;
      this.isModalOpen = false;
      for (let i = 0; i < this.fields.length; i++) {
        if(this.fields[i].id == value.field_id){
          this.fields[i].elements = value.elements;
        }
      }
      this.isElementSelected = true;
    },
    createOrder() {
      let elements = {}
      let dataToBack = this.fields.filter(elem => elem.elements != 0)
      if(dataToBack.length == 0){
        this.$buefy.snackbar.open('Вы не выбрали поле или элементы для анализа!');
        return
      }
      this.isLoading = true;

      const data = dataToBack.map(elem => {
        return {
          elements:elem.elements.map(el => el.id),
          field_id:elem.id
        }
      })

      axios.post(`/api/client/orders/`, data)
      .then((response) => {
        this.$buefy.snackbar.open(response.data.message);
        this.fields.forEach(elem => {elem.elements = []});
        this.isElementSelected = false;
        this.isLoading = false;
      }).catch(() =>{
        this.$buefy.snackbar.open('Не удалось создать заказ');
        this.isLoading = false;
      })
    },
  },
};
</script>

<style scoped>
.modal-view{
  padding: 20px;
  min-width: 400px;
  max-width: 600px;
  height: auto;
}
.element-view{
  width: 70px;
}
.additional-element_view{
  width: 500px;
}
</style>
