<template>
  <!-- скачивание координат всех полей с сеткой и без сетки -->
  <div class="modal is-active">
    <div class="modal-background" @click="CloseModal"></div>
    <div class="modal-content modal-content-box has-background-white">
      <p class="my-4 title ">Выберите параметры:</p>
      <div>
        <button  @click="bundleForDownload.format = 1" :class="[bundleForDownload.format == 1 ? 'is-primary' :'']" class="button mr-2">GeoJson</button>
        <button @click="bundleForDownload.format = 2" :class="[bundleForDownload.format == 2 ? 'is-primary' :'']" class="button mr-2">KML</button>
        <button @click="bundleForDownload.format = 3" :class="[bundleForDownload.format == 3 ? 'is-primary' :'']" class="button">SHP</button>
      </div>
      <div v-if="bundleForDownload.format" class="my-3">
        <button @click="bundleForDownload.setting = 1" :class="[bundleForDownload.setting == 1 ? 'is-primary' :'']" class="button mr-2">C сеткой</button>
        <button @click="bundleForDownload.setting = 2" :class="[bundleForDownload.setting == 2 ? 'is-primary' :'']" class="button">Без сетки</button>

        <div class="my-4" v-if="bundleForDownload.format && bundleForDownload.setting">
          <button @click="downloadCoordsFromArray" class="button is-primary is-outlined">Скачать</button>
        </div>

        <div class="mt-1">
          <span><small>Примечание: Если пользователь выбрал не разделенные поля, применив режим скачать с сеткой, по умолчанию будет скачано без сетки, так как её физически не существует.</small></span>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" @click="CloseModal" aria-label="close"></button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return{
      bundleForDownload:{
        setting:null,
        format:null
      },
    }
  },
  props:['fieldsCheckbox'],
  methods:{
    CloseModal(){
      this.$emit('CloseModal')
    },
     downloadCoordsFromArray(){
      let data = '';
        if(this.bundleForDownload.setting == 1){
          if(this.bundleForDownload.format == 1) {
            data += this.fieldsCheckbox.join(',');
            window.location.href = `${axios.defaults.baseURL}/api/download/field/${data}/grid`;
          }
          if(this.bundleForDownload.format == 2){
            data += this.fieldsCheckbox.join(',');
            window.location.href = `${axios.defaults.baseURL}/api/download/kml/field/${data}/grid`;
          }
          if(this.bundleForDownload.format == 3){
            data += this.fieldsCheckbox.join(',');
            window.location.href = `${axios.defaults.baseURL}/api/download/shp/field/${data}/grid`
          }
        }else if(this.bundleForDownload.setting == 2){
          if(this.bundleForDownload.format == 1) {
            data += this.fieldsCheckbox.join(',');
            window.location.href = `${axios.defaults.baseURL}/api/download/field/${data}`
          }
          if(this.bundleForDownload.format == 2){
            data += this.fieldsCheckbox.join(',');
            window.location.href = `${axios.defaults.baseURL}/api/download/kml/field/${data}`
          }
          if(this.bundleForDownload.format == 3){
            data += this.fieldsCheckbox.join(',');
            window.location.href = `${axios.defaults.baseURL}/api/download/shp/field/${data}`
          }
        }
      this.bundleForDownload.setting = null;
      this.bundleForDownload.format = null;
      this.CloseModal();
    },
  }
}
</script>

<style>
.modal-content-box{
  padding: 20px;
  width: 450px !important;
}
</style>
