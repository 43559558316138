<template>
  <section v-if="role === '1'">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    <div class="is-flex is-justify-content-space-between my-3">
      <div class="ml-3 is-flex is-align-self-flex-end">
        <b-input placeholder="Имя или компания"
          v-model="searchingUser"
          type="search"
          icon="magnify"
          icon-right-clickable
          @icon-right-click="searchingUser = ''">
        </b-input>
      </div>
      <b-button size="is-small"
        icon-left="account-multiple-plus"
        @click="createUserModal()"
        class="mr-3 is-primary">
        Добавить пользователя
      </b-button>
    </div>
    <template v-if="isPageLoaded">
      <div class="title" v-if="users.length == 0">
        Ничего не найдено.
      </div>
      <b-table
        :data="users" v-else>
        <b-table-column label="№" field="count" centered sortable v-slot="props">
          {{ props.row.count }}
        </b-table-column>
        <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
                <template
                    v-if="column.searchable && !column.numeric"
                    #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Search..."
                        icon="magnify"
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                </template>
            </b-table-column>
        </template>
        <b-table-column label="Роль" field="role" centered sortable v-slot="props">
        {{props.row.roles[0].name}}
        </b-table-column>
        <b-table-column label="Компания" field="company_name" centered sortable v-slot="props">
          <p v-if="props.row.companies.length > 0">
            {{ props.row.companies[0].ownership_type }} "{{ props.row.companies[0].name }}"
          </p>
          <p v-else>
            -
          </p>
        </b-table-column>
        <b-table-column label="Email" centered sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>
        <b-table-column label="Статус" centered sortable v-slot="props">
          <p v-if="props.row.is_blocked == 0">Активен</p>
          <p v-else>Заблокирован</p>
        </b-table-column>
        <b-table-column label="Дата создания" field="created_at" centered sortable v-slot="props">
          {{ props.row.created_at.substring(0,10) }} {{ props.row.created_at.substring(11,19) }}
        </b-table-column>
        <!-- <b-table-column label="Дата обновления" field="updated_at" centered sortable v-slot="props">
          {{ props.row.updated_at.substring(0,10) }} {{ props.row.updated_at.substring(11,19) }}
        </b-table-column> -->
        <!-- <b-table-column label="Активна" field="is_active" centered sortable v-slot="props">
          <p v-if="props.row.is_active === 1">Да</p>
          <p v-else>Нет</p>
        </b-table-column> -->
        <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props" width="100">
          <div class="buttons is-right">
            <button class="button is-small is-info" type="button"
            @click.prevent="editUserModal(props.row)">
              <b-icon icon="account-edit" size="is-small"/>
            </button>
            <button class="button is-small is-danger" type="button"
            @click.prevent="deleteUserModal(props.row.id)">
              <b-icon icon="trash-can" size="is-small"/>
            </button>
          </div>
        </b-table-column>
      </b-table>
      <template v-if="!SearchMode">
        <Pagination :total="total" :crntPage="selected" @CurrentPage="CompanyPagination"/>
      </template>
    </template>

  </section>
  <section v-else>
    <p class="title">Данный функционал доступен только Администратору</p>
  </section>
</template>

<script>
import axios from 'axios';
import UserCreateModal from '@/components/admin/UserCreateModal.vue';
import UserEditModal from '@/components/admin/UserEditModal.vue';
import UserDeleteModal from '@/components/admin/UserDeleteModal.vue';
import Pagination from '@/components/pagination/GeneralPagination.vue';
export default {
  components:{
    Pagination
  },
  data() {
    return {
      role: null,
      total:null,
      isPageLoaded:false,
      SearchMode:false,
      searchResult:[],
      selected: 0,
      searchingUser:'',
      users: [],
      isLoading:false,
      columns:[
        {
          field: 'last_name',
          label: 'Фамилия',
          width: '100',
        },
        {
          field: 'first_name',
          label: 'Имя',
          width: '100',
        },
        {
          field: 'middle_name',
          label: 'Отчетсво',
          width: '100',
        },
        {
          field: 'login',
          label: 'Логин',
          width: '100',
        },
      ]
    };
  },
  beforeMount() {
    this.checkRole();
  },
  mounted() {
    if (this.role === '1') {
      this.getUsers(0, 10);
    }
  },
  watch:{
    // поиск юзеров
    searchingUser(){
      if(this.searchingUser.length == 0 && this.users){
        this.getUsers(0, 10);
      }else if(this.searchingUser.length == 4){
        this.SearchUsers(this.searchingUser)
      }
    }
  },
  methods: {
    //првоерка роли
    checkRole() {
      this.role = localStorage.role;
    },

    CompanyPagination(value){
      //пагинация для юзеров, название забыл изменить
      this.selected = Math.ceil(value.pass / 10);
      this.getUsers(value.pass, value.count);
    },
    async SearchUsers(value){
      //поиск юзеров
      if(value.length < 4){
       this.$buefy.snackbar.open('Вводимое значение должно быть не менее 4 символов!');
       return
      }

       await axios.get(`/api/admin/users/?name=${value}`)
      .then(response =>{
        this.users = response.data;
        this.total = this.users.length;
        this.total = Math.ceil(this.total / 10);
        let count = 0;
        for (let i = 0; i < this.users.length; i++) {
          this.users[i].count = (count++) + 1;
        }
      })
      .catch((error)=>{
      })
      this.isPageLoaded = true;
      this.isLoading = false;
      this.SearchMode = true;

    },
    async getUsers(pass, items) {
      //запрос на получения списка юзеров
      this.isLoading = true;
      axios.get(`/api/admin/users/count`).then(response =>{
        this.total = response.data.count
        this.total = Math.ceil(this.total / 10);
      });

      await axios.get(`/api/admin/users/${pass}/${items}`)
        .then((response) => {

          if(pass != 0 && response.data.length == 0){
            pass = pass - 10;
            this.getUsers(pass, 10);
            this.selected = Math.ceil((pass / 10));
            return
          }

          this.users = response.data;
          let count = pass;
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].count = (count++) + 1;
          }
        }).catch(() => {
          this.$buefy.snackbar.open('Ошибка получения списка пользователей');
        });
        this.isPageLoaded = true;
        this.isLoading = false;
        this.SearchMode = false;
    },
    refresh(selectedPage) {
      //обновление таблицы при каких либо манипуляциях с информацией
      if(this.searchingUser){
        this.SearchUsers(this.searchingUser)
      }else{
        this.isLoading = true;
        selectedPage = selectedPage * 10;
        this.getUsers(selectedPage, 10);
      }

      this.$buefy.toast.open({
        message: 'Данные обновлены',
        type: 'is-info',
      });
    },
    createUserModal() {
      // модальное окна для создания юзеров
      this.$buefy.modal.open({
        parent: this,
        component: UserCreateModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isUserCreated': () => {
            this.refresh(this.selected);
          }
        },
      });
    },
    editUserModal(user) {
      // модальное окна для редактирования юзеров

      this.$buefy.modal.open({
        parent: this,
        component: UserEditModal,
        props: {
          userData: user,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isUserEdited': () => {
            this.refresh(this.selected);
          }
        },
      });
    },
    deleteUserModal(userId) {
      //модальное окно по удалению юзеров
      this.$buefy.modal.open({
        parent: this,
        component: UserDeleteModal,
        props: {
          id: userId,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          'isUserDeleted': () => {
            this.refresh(this.selected);
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.mg-left-5{
  margin-left: 5px;
}
.highlight {
  background: #61a937;
}
</style>
