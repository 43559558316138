<template>
  <section v-if="role === '3'">
    <b-loading :is-full-page="true" v-model="isLoading" ></b-loading>
    <div class="landscape">
      <div class="div-header">
        <div class="p-logo">
          <img src="@/assets/DD_rgb.png"/>
        </div>
        <div class="div-title">
          <p class="p-title">Агрохимическая картограмма</p>
        </div>
      </div>
      <div class="div-header cl-1">
        <p class="p-header">Компания: <span v-if="InfoForMapPrint.too_name">ТОО "{{InfoForMapPrint.too_name}}",</span> {{InfoForMapPrint.ownership_type}} "{{InfoForMapPrint.company_name}}"</p>
        <p class="p-header">Регион:{{InfoForMapPrint.actual_address}}</p>
        <p class="p-header">Договор: {{InfoForMapPrint.contract}}</p>
        <p class="p-header">Вид исследования:{{InfoForMapPrint.research_type}} </p>
        <p class="p-header">Протокол: {{InfoForMapPrint.protocol}}</p>
        <p v-if="resizeMap" class="p-header">Название поля: {{InfoForMapPrint.field_name}} </p>
        <p v-if="resizeMap" class="p-header">Элемент: {{InfoForMapPrint.element}} </p>
        <p class="p-header">Методика измерения: {{InfoForMapPrint.method}}</p>
      </div>
       <div class="div-header cl-2">
        <p class="p-header">Почвоотбор:</p>
        <p class="p-header">Филиал "Исследовательский центр"</p>
        <p class="p-header">ТОО "Агрохимическая Компании ДАРКАН ДАЛА" в городе Костанай</p>
        <p class="p-header">Лабораторные исследования:</p>
        <p class="p-header">Филиал "Исследовательский центр"</p>
        <p class="p-header">ТОО "Агрохимическая Компании ДАРКАН ДАЛА" в городе Костанай</p>
        <p class="p-header">Номер аккредитаций:KZ.T.11.2383 от 12.10.2020 г.</p>
      </div>

      <div class="columns" v-if="isRendered">
        <div class="column is-2 sidebar py-2">
          <div class="card" v-if="orders.length != 0">
            <div class="card-content">
              <b-field>
                <b-checkbox v-model="resizeMap">
                  Режим печати
                </b-checkbox>
              </b-field>
              <p v-if="resizeMap" class="hint">Измените масштаб и расположите поле на карте.
                Затем нажмите "Печать картограммы"</p>
              <b-button v-if="resizeMap" type="is-primary" @click="printWindow()"> Печать картограммы</b-button>
            </div>
          </div>

          <button
            v-if="isStandartActive"
            @click.prevent="isStandartActive = false;"
            class="viewAllFields button is-small is-primary is-outlined">
            Показать все поля компании с анализами
          </button>
          <button
            v-if="!isStandartActive"
            @click.prevent="isStandartActive =! isStandartActive"
            class="button is-small is-primary is-outlined">
            Выбрать по № заказа
          </button>

          <div class="card" v-if="!isStandartActive">
            <div class="card-content elements-card">
              <section>
                <p class="c-title">Выберите элемент</p>

                <b-field class="elements-filter" v-for="element in elements" :key="element.id">
                  <b-radio v-model="ElementToAllFields"
                    :native-value="element.id">
                    {{element.protocol_name}}
                  </b-radio>
                </b-field>
              </section>
            </div>
          </div>

          <template v-if="orders.length != 0">
            <div class="card" v-if="orders.length != 0 && isStandartActive">
              <div class="card-content elements-card">
                <section>
                  <p class="c-title">Выберите заказ</p>
                  <p class="is-small mb-3">Количество:{{total}} ед.</p>
                  <b-field class="elements-filter" v-for="order in orders" :key="order.id">
                    <b-radio :disabled="order.is_done ? false : true" v-model="selectedOrder"
                      :native-value="order.id">
                      №{{order.id}} от {{order.created_at}}
                    </b-radio>
                  </b-field>
                </section>
                <div class="mt-2">
                  <button class="button is-small is-primary mr-1" id="prev" @click="PageNext(prev)" disabled>Пред</button>
                  <button class="button is-small is-primary" id="next" @click="PageNext(next)">След</button>
                </div>
              </div>
            </div>

            <div class="card" v-if="selectedOrder && isStandartActive">
              <p class="c-title mx-4">Выберите поле</p>
              <div class="card-content fields-card">
                <section class="fields-box">
                  <b-field class="fields-filter" v-for="field in fields" :key="field.id">
                    <b-radio v-model="selectedField"
                      :native-value="field.id">
                      {{field.name}}
                    </b-radio>
                    <button class="button is-small" @click="downloadResults(field.id)">Скачать результаты</button>
                  </b-field>
                </section>
                <div class="mt-2">
                  <button class="button is-small is-primary mr-1" id="prev-field" @click="PageNextField(prevFields)" disabled>Пред</button>
                  <button class="button is-small is-primary" id="next-field" @click="PageNextField(nextFields)">След</button>
                </div>
              </div>
            </div>

            <div class="card" v-if="selectedField && isStandartActive">
              <p class="c-title mx-4">Выберите элемент</p>
              <div class="card-content elements-card">
                <section class="fields-card">
                  <b-field class="elements-filter" v-for="element in Elements[0].elements" :key="element.id">
                    <b-radio v-model="selectedElement"
                      :native-value="element.id">
                      {{element.protocol_name}}
                    </b-radio>
                  </b-field>
                </section>
              </div>
            </div>
          </template>

          <template v-else>
            <div>
              <p class="my-4 title is-5 is-uppercase">нет результатов</p>
            </div>
          </template>

        </div>
      <div class="column px-5 py-2">
        <section :class="resizeMap ? 'map map-3 forPrint' : 'map forUnPrint map-3'" v-if="render">
          <MapDarkanDala
            :zoomSnap="zoomSnap"
            :fitBoundID="selectedField"
            :mode="mode"
            :company_id="company_id"
            :order_id="selectedOrder"
            :field_id="selectedField"
            :element_id="selectedElement"
            :allFields="allFields"
            :FitToThisPlot="FitToThisPlot"
            :ElementToAllFields="ElementToAllFields"
          />
        </section>
      </div>
    </div>
    </div>
  </section>

  <section v-else-if="role === '4'">
    <b-loading :is-full-page="true" v-model="isLoading" ></b-loading>
    <div class="landscape">
      <div class="div-header">
        <div class="p-logo">
          <img src="../../assets/DD_rgb.png"/>
        </div>
        <div class="div-title">
          <p class="p-title">Агрохимическая картограмма</p>
        </div>
      </div>
      <div class="div-header cl-1">
        <p class="p-header">Компания: <span v-if="InfoForMapPrint.too_name">ТОО "{{InfoForMapPrint.too_name}}",</span> {{InfoForMapPrint.ownership_type}} "{{InfoForMapPrint.company_name}}"</p>
        <p class="p-header">Регион:{{InfoForMapPrint.actual_address}}</p>
        <p class="p-header">Договор: {{InfoForMapPrint.contract}}</p>
        <p class="p-header">Вид исследования:{{InfoForMapPrint.research_type}} </p>
        <p class="p-header">Протокол: {{InfoForMapPrint.protocol}}</p>
        <p v-if="resizeMap" class="p-header">Название поля: {{InfoForMapPrint.field_name}} </p>
        <p v-if="resizeMap" class="p-header">Элемент: {{InfoForMapPrint.element}} </p>
        <p class="p-header">Методика измерения: {{InfoForMapPrint.method}}</p>
      </div>
      <div class="div-header cl-2">
        <p class="p-header">Почвоотбор:</p>
        <p class="p-header">Филиал "Исследовательский центр"</p>
        <p class="p-header">ТОО "Агрохимическая Компании ДАРКАН ДАЛА" в городе Костанай</p>
        <p class="p-header">Лабораторные исследования:</p>
        <p class="p-header">Филиал "Исследовательский центр"</p>
        <p class="p-header">ТОО "Агрохимическая Компании ДАРКАН ДАЛА" в городе Костанай</p>
        <p class="p-header">Номер аккредитаций:KZ.T.11.2383 от 12.10.2020 г.</p>
      </div>
    </div>
      <div class="columns">
        <div class="column is-2 py-2 sidebar-laborant">
          <div class="card-content-first">
              <div class="card mb-4 test-sidebar">
                <div class="card card-content">
                  <b-field>
                    <b-checkbox v-model="resizeMap">
                      Режим печати
                    </b-checkbox>
                  </b-field>
                  <p v-if="resizeMap" class="hint">Измените масштаб и расположите поле на карте.
                    Затем нажмите "Печать картограммы"</p>
                  <b-button v-if="resizeMap" type="is-primary" @click="printWindow()"> Печать картограммы</b-button>
                </div>
              </div>

              <template v-if="!CompanyBin">
                <b-field label="Поиск по Наименованию">
                  <b-autocomplete
                    v-model="nameComp"
                    ref="autocomplete"
                    field="name"
                    :data="filteredCompaniesByName"
                    :keep-first="keepFirst"
                    :open-on-focus="openOnFocus"
                    placeholder="Введите название..."
                    @select="option => CompanyName = option"
                    :selectable-header="selectable"
                    :clearable="clearable"
                  >
                  <template #empty>Нет результатов по {{nameComp}}</template>
                  </b-autocomplete>
                </b-field>
              </template>

              <template v-if="CompanyName">
                <b-field label="БИН" type="is-success">
                  <b-input v-model="CompanyName.bin" disabled></b-input>
                </b-field>
              </template>
            <!-- ================================================ -->
              <template v-if="CompanyBin">
                <b-field label="Название" type="is-success">
                  <b-input v-model="CompanyBin.name" disabled></b-input>
                </b-field>
              </template>

              <template v-if="!CompanyName">
                <b-field label="Поиск по БИН">
                  <b-autocomplete
                    v-model="bin"
                    ref="autocomplete"
                    field="bin"
                    :data="filteredCompaniesByBin"
                    :keep-first="keepFirst"
                    :open-on-focus="openOnFocus"
                    placeholder="Введите БИН..."
                    @select="option => CompanyBin = option"
                    :selectable-header="selectable"
                    :clearable="clearable"
                  >
                  <template #empty>Нет результатов по {{bin}}</template>
                  </b-autocomplete>
                </b-field>
              </template>

            <template v-if="CompanyName">
              <button class="button is-small is-primary mt-3" type="button"
                @click.prevent="getCompaniesOrders(CompanyName.id, 0, 10); Offset = 0;">
                Применить
              </button>
            </template>
            <template v-else-if="CompanyBin">
              <button class="button is-small is-primary mt-3" type="button"
                @click.prevent="getCompaniesOrders(CompanyBin.id, 0, 10); Offset = 0;">
                Применить
              </button>
            </template>
          </div>
          <div class="my-2 seachbar" v-if="(CompanyName || CompanyBin) && isStandartActive">
            <b-input type="name"
              placeholder="поле или номер заказа"
              v-model="searchValueName"
              @input="SearchModule"
            >
            </b-input>
          </div>

          <div v-if="orders.length != 0 && (bin || nameComp)">
            <button  class="viewAllFields button is-small is-primary is-outlined" v-if="isStandartActive && !resizeMap" @click.prevent="isStandartActive = false;selectedField = null; selectedOrder = null; selectedElement = null">Показать все поля компании с анализами</button>
            <button  class="viewAllFields button is-small is-primary is-outlined" v-if="!isStandartActive && !resizeMap" @click.prevent="isStandartActive =! isStandartActive; allFields = []; ElementToAllFields = null">Выбрать по № заказа</button>
          </div>

          <div class="card" v-if="!isStandartActive && (bin || nameComp)">
            <div class="card-content elements-card">
              <section>
                <p class="c-title">Выберите элемент</p>
                <b-field class="elements-filter" v-for="element in elements" :key="element.id">
                  <b-radio v-model="ElementToAllFields"
                    :native-value="element.id">
                    {{element.protocol_name}}
                  </b-radio>
                </b-field>
              </section>
            </div>
          </div>

          <div class="card" v-if="orders.length != 0 && (CompanyName || CompanyBin) && isStandartActive">
            <div class="card-content elements-card">
              <section>
                <p class="c-title">Выберите заказ</p>
                <p class="is-small mb-3">Количество:{{total}} ед.</p>
                <b-field class="elements-filter" v-for="order in orders" :key="order.id">
                  <b-radio :disabled="order.is_done ? false : true" v-model="selectedOrder"
                    :native-value="order.id">
                    №{{order.id}} от  {{order.created_at}}
                  </b-radio>
                </b-field>
              </section>
              <div class="mt-2">
                <button class="button is-small is-primary mr-1" id="prev" @click="PageNext(prev)" disabled>Пред</button>
                <button class="button is-small is-primary" id="next" @click="PageNext(next)">След</button>
              </div>
            </div>
          </div>

          <div class="card" v-if="orders.length != 0 && selectedOrder && (CompanyName || CompanyBin) && isStandartActive">
            <p class="c-title mx-4">Выберите поле</p>
            <div class="card-content fields-card">
              <section class="fields-box">
                <b-field class="fields-filter is-flex is-justify-content-space-between is-flex-wrap-wrap" v-for="field in fields" ref="field" :key="field.id">
                  <b-radio ref="fieldObject" v-model="selectedField"
                    :native-value="field.id">
                    {{field.name}}
                  </b-radio>
                  <button class="button is-small" @click="downloadResults(field.id)">Скачать результаты</button>
                </b-field>
              </section>
              <div class="mt-2">
                <button class="button is-small is-primary mr-1" id="prev-field" @click="PageNextField(prevFields)" disabled>Пред</button>
                <button class="button is-small is-primary" id="next-field" @click="PageNextField(nextFields)">След</button>
              </div>
            </div>
          </div>

          <div class="card" v-if="orders.length != 0 && selectedField && (CompanyName || CompanyBin) && isStandartActive">
            <p class="c-title mx-4">Выберите элемент</p>
            <div class="card-content elements-card">
              <section class="fields-box">
                <b-field class="elements-filter" v-for="element in Elements[0].elements" :key="element.id">
                  <b-radio ref="elementObject" v-model="selectedElement"
                    :native-value="element.id">
                    {{element.name}}
                  </b-radio>
                </b-field>
              </section>
            </div>
          </div>
        </div>

        <div class="column px-5 py-2">
          <section
            :class="resizeMap ? 'map map-3 forPrint' : 'map map-3'"
            v-if="render"
          >
            <MapDarkanDala
              :zoomSnap="zoomSnap"
              :fitBoundID="selectedField"
              :mode="mode"
              :company_id="company_id"
              :order_id="selectedOrder"
              :field_id="selectedField"
              :element_id="selectedElement"
              :allFields="allFields"
              :FitToThisPlot="FitToThisPlot"
              :ElementToAllFields="ElementToAllFields"
            />
          </section>
        </div>
      </div>
  </section>

  <section v-else>
    <p class="title">Данный функционал доступен только Клиенту</p>
  </section>

</template>
<script>
import axios from 'axios';
import MapDarkanDala from '../../components/map/MapDarkanDala.vue';

export default {
  name: 'Map',
  components: {
    MapDarkanDala,
  },
  data() {
    return {
      CompanyName: '',
      CompanyBin:'',
      nameComp: '',
      bin: '',
      searchValueName:'',
      total:0,
      totalFields:0,
      allFields:[],
      FitToThisPlot:[],
      ElementToAllFields:null,
      isStandartActive:true,
      InfoForMapPrint:[],
      keepFirst: false,
      isRendered:false,
      selectable: false,
      role: null,
      isButtonClicked:false,
      fields: [],
      mode: 1,
      zoomSnap:1,
      company_id: localStorage.company_id,
      name: '',
      render: true,
      selectedName: null,
      openOnFocus: true,
      clearable: true,
      Elements:[],
      selectedField: null,
      selectedOrder: null,
      selectedBin:null,
      elements: [],
      selectedElement: null,
      resizeMap: false,
      companies: [],
      orders: [],
      isLoading:false,
      Count:10,
      Offset:0,
      OffsetFields:0,
      prev:0,
      next:1,
      prevFields:0,
      nextFields:1
    };
  },
  beforeMount() {
    this.checkRole();
    this.getElements();

    if (this.role === '3') {
      this.getAllOrderByCompanyId(0,10);
    }
    else if(this.role === '4'){
      this.getCompanies();
    }
  },
  watch: {
    nameComp(newValue){
      this.selectedOrder = null;
      this.selectedField = null;
      this.selectedElement = null;
      this.fields = [];
      this.elements = [];
      this.orders = [];
      this.allFields = [];
      this.Offset = 0;
      this.OffsetFields = 0;
    },
    bin(newValue){
      this.selectedOrder = null;
      this.selectedField = null;
      this.selectedElement = null;
      this.fields = [];
      this.elements = [];
      this.orders = [];
      this.Offset = 0;
      this.OffsetFields = 0;
    },
    selectedOrder(newValue) {
      if(!newValue){
        return
      }
      this.getFieldsByOrderId(this.selectedOrder, 0);
      this.selectedField = null;
      this.selectedElement = null;
    },
    selectedField(field) {
      this.selectedElement = null;
      if(!field){
        return
      }
      this.Elements = this.fields.filter(el => {
        return el.id == this.selectedField
      });
    },
    selectedElement(element) {
      if(!element){
        return
      }

      if(!this.selectedOrder && !this.selectedField){
        return
      }

      this.renderMap(element);
      if(this.selectedOrder && this.selectedField && element){
        this.getInfoToPrintMap(this.selectedOrder, this.selectedField, element)
      }
    },
    resizeMap(newValue) {
      this.resizeMap = newValue;

      if(newValue){
        this.zoomSnap = 0.25;
      }else{
        this.zoomSnap = 1;
      }
    },
    ElementToAllFields(newValue){
      if(newValue){
        this.getAllFieldsAnalizes(this.company_id, newValue)
      }
    },
    isStandartActive(newValue){
      if(newValue){
        this.allFields = [];
        this.ElementToAllFields = null;
      }else{
        if(this.role == '4'){
          this.getElements()
          this.getAllFieldFromThisCompany();
        }else{
          this.getAllFieldFromThisCompany();
        }
      }

    },

  },
  computed: {
    //Фильтры в списках
    filteredCompaniesByBin() {
      return this.companies.filter((option) => option.bin
        .toString()
        .toLowerCase()
        .indexOf(this.bin.toLowerCase()) >= 0);
    },
    filteredCompaniesByName() {
      return this.companies.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.nameComp.toLowerCase()) >= 0);
    },
    filteredFieldsByName() {
      return this.fields.filter((field) => field.name
        .toString()
        .toLowerCase()
        .indexOf(this.name.toLowerCase()) >= 0);
    },
    currentCompanyName() {
      return `${localStorage.company_ownership_type} "${localStorage.company_name}"`;
    },
    currentCompanyBin() {
      return localStorage.company_bin;
    },
    currentCompanyLegalAddress() {
      return localStorage.company_legal_address;
    },
    currentField() {
      let fd = null;

      this.fields.forEach((field) => {
        if (field.id === this.selectedField) {
          fd = field.name;
        }
      });

      return fd;
    },
    currentElement() {
      let el = null;

      this.elements.forEach((element) => {
        if (element.id === this.selectedElement) {
          el = `${element.name}, ${element.unit}`;
        }
      });

      return el;
    },
  },
  methods: {
    SearchModule(newValue){
      //поиск заказов компании
      if(newValue.length > 0){
        this.getCompaniesOrders(this.company_id, 0,10, newValue)
      }else{
        this.getCompaniesOrders(this.company_id, 0,10)
      }
    },
    downloadResults(id){
      //скачивание результатов анализов поля с сеткой
      window.location.href = `${axios.defaults.baseURL}/api/download/shp/field/${id}/grid?withResult`
    },
    ResetSearch(){
      //сброс поиска
      if(this.selectedBin){
        this.selectedBin = null
      }
      else if(this.selectedName){
        this.selectedName = null
      }

      this.fields = [];
      this.orders = [];
      this.elements = [];
      this.selectedOrder = null;
      this.selectedElement = null;
    },
    renderMap() {
      // инициализация карты
      this.render = true;
    },
    checkRole() {
      //првоерка роли из локал сторейдж
      this.role = localStorage.role;
    },
    async getAllFieldFromThisCompany(){
      // запрос на все поля этой компании
      if(this.role == '3'){
        await axios.get(`api/client/company_fields/`).then(response =>{
          this.FitToThisPlot = response.data;
        }).catch((e)=>{

        })
      }else{
        await axios.get(`api/gist/company_fields/${this.company_id}/`).then(response =>{
          this.FitToThisPlot = response.data;
        }).catch((e)=>{

        })
      }
    },
    async getAllFieldsAnalizes(company_id, element_id){
      //запрос на все поля компании с анализами
      if(!company_id && !element_id || (!company_id || !element_id)){
        return
      }

      let test = {
        "type": "FeatureCollection",
        "features": []
      }
      this.isLoading = true;
      await axios.get(`api/client/orders/result/${company_id}/${element_id}`)
      .then(response =>{
        test.features = response.data.results;
      })
      .catch((e)=>{
      })
      this.allFields = test;
      this.isLoading = false;
      this.selectedField = null;
      this.selectedOrder = null;
      this.selectedElement = null;
    },
    async getInfoToPrintMap(order_id, field_id, element_id){
      //выбор необходимых параметров для отображение поля с анализами
      await axios.get(`api/laborant/orders/info/${order_id}/${field_id}/${element_id}`)
      .then(response => {
        this.InfoForMapPrint = response.data;
      })
      .catch((error)=> {

      })
    },
    async getCompanies() {
      //запрос на получения списка компаний
      this.isLoading = true;
      await axios.get('/api/laborant/companies')
      .then((response) => {
        this.companies = response.data;
        this.companies.filter((option) => {
          if(option.bin == null ){
            option.bin = 0;
          }
        })
      }).catch((e) => {
          this.$buefy.toast.open({
          message: 'Ошибка получения списка компаний',
          type: 'is-danger',
        });
      });
      this.isLoading = false;
    },
    async getCompaniesOrders(company_id, offset, count, name=''){
      //запрос на список заказов компании
      this.selectedOrder = null;
      this.selectedField = null;
      this.selectedElement = null;
      this.company_id = company_id;
      this.isLoading = true;
      this.fields = [];
      await axios.get(`/api/laborant/analyzes/${this.company_id}/orders?offset=${offset}&count=${count}`, { params: {
        ...(name && ({ name: name }))
      }})
      .then((response) => {
        this.orders = response.data.items
        this.orders = this.orders.filter(el => el.fields.length > 0)
        this.total = response.data.count;
      }).catch((err) => {
        this.$buefy.toast.open({
          message: 'Ошибка получения списка полей компании',
          type: 'is-danger',
        });
      });
      this.isLoading = false;
    },
    async getFields() {
      //запрос на поля компании
      this.fields = [];
      this.isLoading = true;
      await axios.get('/api/client/company_fields/is_done')
        .then((response) => {
          this.fields = response.data;
          let set = new Set(this.fields.map(JSON.stringify));
          this.fields = Array.from(set).map(JSON.parse);
        })
        .catch(() => {
          this.$buefy.toast.open({
          message: 'Ошибка получения списка полей компании',
          type: 'is-danger',
        });
      });
      this.isLoading = false;
    },
    async getElementsByOrderId() {
      //запрос элементов по номеру заказа
      this.elements = [];
      this.isLoading = true;
      await axios.get(`/api/laborant/analyzes/${this.company_id}/orders/${this.selectedOrder}`)
        .then((response) => {
          this.elements = response.data;
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка элементов в заказе',
            type: 'is-danger',
          });
        });
      this.isLoading = false;
    },
    async getElements() {
      //запрос легенды
      this.elements = [];
      this.isLoading = true;
      await axios.get('/api/gist/elements')
      .then((response) => {
        this.elements = response.data;
      }).catch(() => {
          this.$buefy.toast.open({
          message: 'Ошибка получения списка элементов',
          type: 'is-danger',
        });
      });
      this.isLoading = false;
    },
    PageNext(value){
      this.selectedField = null;
      this.selectedElement = null;

      if(this.role === '3'){
        if(this.total > 10){
          let next = document.getElementById('next');
          next.removeAttribute('disabled');
        }
        if(value == 0){
          if(this.Offset > 0){
            this.Offset = this.Offset - 10;
            if(this.searchValueName.length > 2){
              this.getAllOrderByCompanyId(this.Offset, 10, this.searchValueName);
            }else{
              this.getAllOrderByCompanyId(this.Offset, 10);
            }
            let next = document.getElementById('next');
            next.removeAttribute('disabled');
          }else{
            let prev = document.getElementById('prev');
            prev.setAttribute('disabled', '');
            return
          }
        }else{
          let cuts = this.total - this.Offset;
          if(cuts > 10){
            if(this.Offset < this.total){
              this.Offset = this.Offset + 10;
              if(this.searchValueName.length > 2){
                this.getAllOrderByCompanyId(this.Offset, 10, this.searchValueName)
              }else{
                this.getAllOrderByCompanyId(this.Offset, 10)
              }

              let prev = document.getElementById('prev');
              prev.removeAttribute('disabled');
            }else{
              this.getAllOrderByCompanyId(this.Offset, 10)
            }
          }else{
            let next = document.getElementById('next');
            next.setAttribute('disabled', '');
            return
          }
        }
      }else{
        if(value == 0){
          if(this.Offset > 0){
            this.Offset = this.Offset - 10;
            this.getCompaniesOrders(this.company_id, this.Offset, 10, this.searchValueName);
            let next = document.getElementById('next');
            next.removeAttribute('disabled');
          }else{
            let prev = document.getElementById('prev');
            prev.setAttribute('disabled', '');
            return
          }
        }else{
          let cuts = this.total - this.Offset;
          if(cuts > 10){
            if(this.Offset < this.total){
              this.Offset = this.Offset + 10;
              this.getCompaniesOrders(this.company_id, this.Offset, 10, this.searchValueName)
              let prev = document.getElementById('prev');
              prev.removeAttribute('disabled');
            }else{
              this.getCompaniesOrders(this.company_id, this.Offset, 10 , this.searchValueName)
            }
          }else{
            let next = document.getElementById('next');
            next.setAttribute('disabled', '');
            return
          }
        }
      }
    },
    PageNextField(value){
      this.selectedElement = null;
      if(this.totalFields > 10){
        let next = document.getElementById('next-field');
        next.removeAttribute('disabled');
      }
      if(value == 0){
        if(this.OffsetFields > 0){
          this.OffsetFields = this.OffsetFields - 10;
          this.getFieldsByOrderId(this.selectedOrder, this.OffsetFields);
          let next = document.getElementById('next-field');
          next.removeAttribute('disabled');
        }else{
          let prev = document.getElementById('prev-field');
          prev.setAttribute('disabled', '');
          return
        }
      }else{
        let cuts = this.totalFields - this.OffsetFields;
        if(cuts > 10){
          if(this.OffsetFields < this.totalFields){
            this.OffsetFields = this.OffsetFields + 10;
            this.getFieldsByOrderId(this.selectedOrder, this.OffsetFields)
            let prev = document.getElementById('prev-field');
            prev.removeAttribute('disabled');
          }else{
            this.getFieldsByOrderId(this.selectedOrder,this.OffsetFields)
          }
        }else{
          let next = document.getElementById('next-field');
          next.setAttribute('disabled', '');
          return
        }
      }
    },
    async getAllOrderByCompanyId(offset, count, searchValueName = ''){
      //запрос всех заказов по номеру компании
      this.isLoading = true;
      await axios.get(`api/laborant/analyzes/${this.company_id}/orders?offset=${offset}&count=${count}&isDone=1`,{params:{
        ...(searchValueName && ({searchValueName:searchValueName}))
      }})
        .then((response) => {
          this.orders = response.data.items
          // .filter(el => el.is_done === 1);
          this.total = response.data.count;
          this.orders = this.orders
        }).catch((e) => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка выполненных заказов',
            type: 'is-danger',
        });
      });

      this.isLoading = false;
      this.isRendered = true;
    },
    async getFieldsByOrderId(order_id, offset){
      //запрос полей по номеру заказ
      if(order_id == null){
        return
      }

      this.isLoading = true;
        await axios.get(`/api/client/orders/fields/${order_id}?offset=${offset}&count=10`)
        .then((response) => {
          this.totalFields = response.data.count;
          this.fields = response.data.fields;
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка полей заказа',
            type: 'is-danger',
          });
        });
        this.isLoading = false;
    },
    printWindow() {
      window.print();
    },
  },
};
</script>

<style>
  .forPrint #map {
    width: 1050px !important;
    height: 550px !important;
  }

  body {
    -webkit-print-color-adjust: exact !important;
  }

  @media print{
    .leaflet-control-container {
      display: none;
    }
    .slidecontainer{
      display: none;
    }
    .card-content-first{
      display: none;
    }
    .test-sidebar{
      display: none;
    }
    .card-content{
      display: none;
    }
    .seachbar{
      display: none;
    }
    .card{
      display: none;
    }
    .viewAllFields{
      display: none;
    }


  }

</style>

<style scoped>
.is-right{
  text-align: right;
  padding-bottom: 10px;
}
.column{
  padding: 0;
}
.sidebar{
  padding-left: 20px;
  height: 93vh;
  overflow: scroll;
}
.sidebar-laborant{
  padding-left: 20px;
  height: 95vh;
  overflow: scroll;
}
.mg-left-5{
  margin-left: 5px;
}
.fields-filter{
  text-align: left;
}
.fields-card{
  height: 300px;
  overflow:scroll;
  /* overflow-x:hidden; */
}
.elements-filter{
  text-align: left;
}
.elements-card{
  height: auto;
  overflow:scroll;
}
.card{
  width: 100%;
}
.card-content{
  height: auto;
  width: auto;
}
.card-content-first{
  height: 350px;
  width: auto;
}
.c-title{
  font-weight: 500;
  color: gray;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: 90%;
}
.map{
  margin-top: 10px;
  height:100%;
}
.hint{
  padding-right: 15px;
  margin-bottom: 10px;
  font-size: 10px;
  color: gray;
}
.card{
  margin-bottom: 10px;
}
#rrr{
  margin: 10px;
}


@media screen{
  .p-logo, .div-header{
    display: none;
  }

}

@media print{
  @page {
    size: 4508px 3480px landscape;
    /* margin: 25mm 25mm 25mm 25mm; */
  }

  body {
    margin: 0;
    padding: 0;
  }

  .sidebar, .navbar{
    display: none;
  }
  .p-logo{
    position: fixed;
    top: 40px;
    left: 40px;
    max-width: 70px;
    height: auto;
  }
  .p-header{
    text-align: left!important;
    font-size: 12px;
    color: black;
  }
  .div-title{
    display: inline-block;
  }
  .p-title{
    text-align: left!important;
    font-size: 14px;
    color: black;
  }
  .cl-1{
    position: fixed;
    top: 30px;
    left: 130px;
  }
  .cl-2{
    position: fixed;
    top: 30px;
    left: 650px;
  }
  .map-3{
    position: fixed;
    top: 170px;
    left: 40px;
    width: 1100px;
  }

}
</style>
