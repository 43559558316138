<template>
  <!-- модальное окно по созданию заказа -->
  <div class="has-background-white py-5">
    <h2 class="title has-text-centered">Выберите элементы</h2>
    <div class="buttons if-flex is-flex-direction-column is-align-items-flex-center">
      <div class="buttons">
      <button class="button is-large" :class="[BaseBtn ? 'is-primary':'']" @click="BaseBtn =! BaseBtn">Базовый</button>
      <button class="button is-large" :class="[AdditionalBtn ? 'is-primary':'']" @click="AdditionalBtn =! AdditionalBtn">Микроэлементы</button>
      </div>
      <div class="is-flex is-flex-direction-column mb-5" v-if="AdditionalBtn">
        <div>
          <b-checkbox-button v-model="selectedElem"
            native-value="All"
            type="is-primary">
            <span>Выбрать все</span>
          </b-checkbox-button>
        </div>
        <div class="is-flex is-flex-direction-row mb-5">
          <b-checkbox-button
          v-model="selectedElem"
          v-for="elem in AdditionalElements"
          :key="elem.id"
          :native-value="elem"
          type="is-primary"
          class="ml-1"
        >
          <span>{{elem.protocol_name}}</span>
        </b-checkbox-button>
        </div>
      </div>

      <div class="mt-5">
        <button class="button is-danger is-outlined" @click="CancelModal">Отменить</button>
        <button class="button is-primary is-outlined" @click="SendPackToParent">Подтвердить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return{
      BaseBtn:false,
      AdditionalBtn:false,
      elements:[],
      BaseElements:[],
      AdditionalElements:[],
      selectedElem:[],
      ElementPackage:[],
      SecondPack:[]
    }
  },
  beforeMount(){
    this.getElements()
  },
  watch:{
    BaseBtn(newValue, oldValue){
      if(newValue){
        this.ElementPackage = this.BaseElements;
      } else {
        this.ElementPackage = [];
      }
    },
    selectedElem(newValue, oldValue){
      const filteredArray = newValue.filter(n => {
        if(n === 'All'){
          this.selectedElem = this.AdditionalElements.map(el =>{
            return el
          })
        }
      });
      this.SecondPack = newValue;
    },
    AdditionalBtn(newValue, oldValue){
      if(newValue){
        this.selectedElem = []
      }
    }
  },
  props:['selectedRow'],
  methods:{
    getElements() {
      axios.get('/api/gist/elements')
        .then((response) => {
          this.elements = response.data;
          this.BaseElements = this.elements.filter((element) => element.group_id === 1);
          this.AdditionalElements = this.elements.filter((element) => element.group_id === 2);
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка получения списка полей',
            type: 'is-danger',
          });
        });
    },
    SendPackToParent(){
      let data = {
        field_id:null,
        elements:[]
      }
      data.field_id = this.selectedRow.id
      if(this.ElementPackage.length > 0 || this.SecondPack.length > 0){
        data.elements = this.ElementPackage.concat(this.SecondPack);
        this.$emit('package-element', data)
      }else{
        this.$buefy.toast.open({
          message: 'Элементы не выбраны',
          type: 'is-danger',
        });
      }
    },
    CancelModal(){
      this.$emit('close-modal', false)
    }
  }
}
</script>

<style scoped>

</style>
